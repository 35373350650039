<template>
  <div v-if="visible" class="loading-overlay">
    <div class="loading-message">
      正在上传中，请等待...
    </div>
    <div class="loading-message-attention">
      关闭页面将会导致上传失败，请谨慎操作。
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-message {
  color: white;
  font-size: 20px;
  margin-bottom: 10px;
}

.loading-message-attention {
  color: red;
  font-size: 20px;
}
</style>
