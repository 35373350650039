<template>
  <div>
    <Page v-show="isOverviewShow" name="Endpoint" :data="EndpointList" :key="componentKey" @InitialData="InitialData" @handleFilterByStr="handleFilterByStr" @handleUpdateLeasingMode="handleUpdateLeasingMode"
      @handleLockMachines="handleLockMachines" @handleUpdateLeasingBuff="handleUpdateLeasingBuff"
      @handleSendMessageJob="handleSendMessageJob" @showDetails="showDetails" :table_fields="table_fields"
      :initialFilterName="filterName" :update_leasingbufferperiod_fields="update_leasingbufferperiod_fields"
      :update_leasingmode_fields="update_leasingmode_fields" :send_message_fields="send_message_fields"
      :lock_fields="lockFields" @handleReturn="handleReturn" :leasing_out_or_not_list="leasing_out_or_not_list" :leasing_out_or_not_list_checked="leasing_out_or_not_list_checked" :provision_list="provision_list" :provision_list_checked="provision_list_checked" :leasing_mode_list="leasing_mode_list" :leasing_mode_list_checked="leasing_mode_list_checked" :lock_list="lock_list" :lock_list_checked="lock_list_checked" :connect_status_list="connect_status_list" :connect_status_list_checked="connect_status_list_checked" :rent_list="rent_list" :rent_list_checked="rent_list_checked" :total_endpoints_num="total_endpoints_num"></Page>
    <Details v-show="isDetailsShow" name="EndpointDetails" :data="selectedEndpointData" :action_fields="action_fields"
      :actionList="actionList" :user_list="user_list" :user_list_checked="user_list_checked" :action_list="action_list"
      :action_list_checked="action_list_checked" :status_list="status_list"
      :status_list_checked="status_list_checked" :details_fields="details_fields" :total_actions_num="total_actions_num" @handleDetailFilterByStr="handleDetailFilterByStr" @handleUpdateMachine="handleUpdateMachine"></Details>
  </div>
</template>

<script>
import Page from '../components/Page.vue'
import Details from '../components/Details.vue'
import { getAllEndpoints, updateMachine, showDetails } from '../apis/Machine'
import { updateLeasing, updateLeasingBuff } from '../apis/Lease'
import { getAction, sendMessageJob, evaluateAssetRecovery } from '../apis/Action'
import { getItem, setItem } from "../utils/storage.js";

const PROVISIONSTATUS = {
  "provisioned": "已完成安全配置",
  "unprovisioned": "尚未安全配置",
  'provisioning': "配置中"
}
const ACTIONTYPE = {
  "lease_update": "更改租赁信息",
  "lease_term_buff_update": "更改缓冲期或最终缓冲期",
  "message": "发送消息",
  "asset_return": "资产评估",
  "provision": "安全配置"
}
const ACTIONRESULT = {
  "success": "成功",
  "fail": "失败",
  "incomplete": "未完成"
}
const CONNECTIONSTATUS = {
  "connected": "已联网",
  "disconnected": "未联网"
}
const LEASINGTERM = {
  "no lease term added": "未上传租期",
  "overdue": "已逾期",
  "less than 30 days": "30天以内",
  "less than 3 months": "30天至3个月",
  "others": "超过3个月"
}

const JOBTYPE = {
  "uLM": "更改租赁模式",
  "uLB": "更改缓冲期或最终缓冲期",
  "uSS": "停止服务",
  "sAM": "发送消息",
  "eAR": "资产评估"
}

export default {
  name: 'Endpoint',
  components: {
    Page,
    Details
  },
  data() {
    return {
      componentKey: 0,
      filterName: '',
      tabName: this.$store.state.tabName,
      leasing_out_or_not_list: [],
      leasing_out_or_not_list_checked: [],
      provision_list: [],
      provision_list_checked: [],
      leasing_mode_list: [],
      leasing_mode_list_checked: [],
      lock_list: [],
      lock_list_checked: [],
      connect_status_list: [],
      connect_status_list_checked: [],
      rent_list: [],
      rent_list_checked: [],
      isOverviewShow: this.$store.state.isOverviewShow,
      isDetailsShow: !this.$store.state.isOverviewShow,
      EndpointList: [],
      selectedEndpointData: {},
      actionList: [],
      user_list: [],
      user_list_checked: [],
      action_list: [],
      action_list_checked: [],
      status_list: [],
      status_list_checked: [],
      hardware_data: {},
      total_endpoints_num: 0,
      total_actions_num: 0,
      table_fields: [
        { name: 'Name', type: 'string', table_name: '机器' },
        { name: 'EndGroup', type: 'string', table_name: '设备组' },
        { name: 'Machine_Status', type: 'string', table_name: '链接状态' },
        { name: 'Last_Connect_Time', type: 'string', table_name: '上次链接时间' },
        { name: 'Is_Overdue', type: 'string', table_name: '剩余租期' },
        { name: 'custom_1', type: 'string', table_name: "" },
        { name: 'custom_2', type: 'string', table_name: "" }
      ],

      details_fields: [
        { name: 'SN', type: 'string', table_name: 'SN' },
        { name: 'Machine_ID', type: 'string', table_name: '机器号' },
        { name: 'EndGroup', type: 'string', table_name: '设备组' },
        { name: 'Machine_Status', type: 'string', table_name: '链接状态' },
        { name: 'Last_Connect_Time', type: 'string', table_name: '上次链接时间' },
        { name: 'Lease_Mode', type: 'string', table_name: '租赁模式' },
        { name: 'Lock_Machine', type: 'string', table_name: '是否停止服务' },
        { name: 'Provision', type: 'string', table_name: '安全配置' },
        { name: 'Starting_Time', type: 'string', table_name: '租期开始时间' },
        { name: 'Ending_Time', type: 'string', table_name: '租期结束时间' },
        { name: 'Is_Overdue', type: 'string', table_name: '剩余租期' },
        { name: 'Lease_Term_Buff', type: 'string', table_name: '缓冲期' },
        { name: 'Lease_Term_Buff2', type: 'string', table_name: '最终缓冲期' }
      ],

      update_leasingmode_fields: [
        { name: 'Leasing_Mode', type: 'enum', options: [{ label: '开启', value: 1 }, { label: '关闭', value: 0 }], table_name: '租赁模式' }
      ],

      update_leasingbufferperiod_fields: [
        { name: 'Leasing_Buffer', type: 'string', table_name: '缓冲期', unit: "天" },
        { name: 'Final_Leasing_Buffer', type: 'string', table_name: '最终缓冲期', unit: "小时" }
      ],

      send_message_fields: [
        { name: 'Message', type: 'string', table_name: '发送消息' }
      ],

      lockFields: [
        { name: 'Lock', type: 'enum', options: [{ label: '停止服务', value: 1 }, { label: '开启服务', value: 0 }], table_name: '停止服务' }
      ],

      action_fields: [
        { name: 'User', type: 'string', table_name: '操作员' },
        { name: 'Action', type: 'string', table_name: '任务' },
        { name: 'Sending_Time', type: 'string', table_name: '发送时间' },
        { name: 'Update_Time', type: 'string', table_name: '更新时间' },
        { name: 'Machine_SN', type: 'string', table_name: '机器' },
        { name: 'Status', type: 'string', table_name: '处理结果' },
        { name: 'Error_Message', type: 'string', table_name: '备注' }
      ]
    }
  },
  props: ['filter'],
  methods: {
    InitialData() {
      this.componentKey += 1;
      return getAllEndpoints().then(res => {
        if (res.status === "success") {
          let e = []
          if ((res["endpoints"] == null) || (res["endpoints"] == undefined)) return false
          let endpoints = res["endpoints"]
          for (let each in endpoints) {
            let item = endpoints[each]
            let groupName = ""
            let custom_1 = ""
            let custom_2 = ""
            let metadata = {}
            if ((item.metadata != null) && (item.metadata != undefined)) {
              metadata = item.metadata
              if (this.table_fields[5]["table_name"] === "")
                this.table_fields[5]["table_name"] = ( (metadata["custom_1"] == null) || (metadata["custom_1"] == undefined) || (metadata["custom_1"]["name"] == null) || (metadata["custom_1"]["name"] == undefined) || (metadata["custom_1"]["data"] == null) || (metadata["custom_1"]["data"] == undefined) ) ? "" : metadata["custom_1"]["name"]
              if (this.table_fields[6]["table_name"] === "")
                this.table_fields[6]["table_name"] = ( (metadata["custom_2"] == null) || (metadata["custom_2"] == undefined) || (metadata["custom_2"]["name"] == null) || (metadata["custom_2"]["name"] == undefined) || (metadata["custom_2"]["data"] == null) || (metadata["custom_2"]["data"] == undefined) ) ? "" : metadata["custom_2"]["name"]
                groupName = ((metadata["custom_EndpointGroup"] == null) || (metadata["custom_EndpointGroup"] == undefined) || (metadata["custom_EndpointGroup"]["data"] == null) || (metadata["custom_EndpointGroup"]["data"] == undefined)) ? "" : metadata["custom_EndpointGroup"]["data"]
                custom_1 = ((metadata["custom_1"] == null) || (metadata["custom_1"] == undefined) || (metadata["custom_1"]["data"] == null) || (metadata["custom_1"]["data"] == undefined)) ? "" : metadata["custom_1"]["data"]
                custom_2 = ((metadata["custom_2"] == null) || (metadata["custom_2"] == undefined) || (metadata["custom_2"]["data"] == null) || (metadata["custom_2"]["data"] == undefined)) ? "" : metadata["custom_2"]["data"]
            }

            let origin_prop = {}
            let updated_prop = null
            let updated_fileds = null
            if((item.machine_info != null) && (item.machine_info != undefined)){
              if((item.machine_info["origin_prop"] != null) && (item.machine_info["origin_prop"] != undefined))
                origin_prop = item.machine_info["origin_prop"]
              if((item.machine_info["updated_prop"] != null) && (item.machine_info["updated_prop"] != undefined))
                if(Object.keys(item.machine_info["updated_prop"]).length !== 0)
                  updated_prop = item.machine_info["updated_prop"]
              if((item.machine_info["updated_fileds"] != null) && (item.machine_info["updated_fileds"] != undefined))
                if(Object.keys(item.machine_info["updated_fileds"]).length !== 0)
                  updated_fileds = item.machine_info["updated_fileds"]
            }

            let endpoint = {
              id: item.id,
              SN: item.sn,
              Name: item.sn,
              Machine_ID: ((item.machine_id == null) || (item.machine_id == undefined)) ? item.sn : item.machine_id,
              Type: ((item.type == null) || (item.type == undefined)) ? "" : item.type,
              Status: ((item.status == null) || (item.status == undefined)) ? "" : item.status,
              Provision: ((item.provisioned == null) || (item.provisioned == undefined)) ? "" : PROVISIONSTATUS[item.provisioned],
              Lease_Mode: ((item.lease_mode == null) || (item.lease_mode == undefined)) ? "" : (item.lease_mode % 2 === 1) ? "开启" : "关闭",
              Lock_Machine: ((item.lease_mode == null) || (item.lease_mode == undefined)) ? "" : (parseInt(item.lease_mode / 2) === 1) ? "停止服务" : "开启服务",
              EndGroup: groupName,
              LeasingOutOrNot: groupName === "" ? "库存设备":"出租设备",
              custom_1: custom_1,
              custom_2: custom_2,
              Lease_Term_Buff: ((item.lease_term_buff == null) || (item.lease_term_buff == undefined)) ? "" : item.lease_term_buff,
              Lease_Term_Buff2: ((item.lease_term_buff2 == null) || (item.lease_term_buff2 == undefined)) ? "" : item.lease_term_buff2,
              Price: ((item.price == null) || (item.price == undefined)) ? "" : item.price,
              Brand: ((item.brand == null) || (item.brand == undefined)) ? "" : item.brand,
              Model: ((item.model == null) || (item.model == undefined)) ? "" : item.model,
              CPU: ((item.cpu == null) || (item.cpu == undefined)) ? "" : item.cpu,
              GPU: ((item.gpu == null) || (item.gpu == undefined)) ? "" : item.gpu,
              Memory: ((item.memory == null) || (item.memory == undefined)) ? "" : item.memory,
              Drive: ((item.drive == null) || (item.drive == undefined)) ? "" : item.drive,
              Metadata: metadata,
              Origin_Hardware_Data: origin_prop,
              Updated_Hardware_Data: updated_prop,
              Updated_Fileds: updated_fileds,
              Starting_Time: ((item.starting_time == null) || (item.starting_time == undefined) || (item.starting_time === "")) ? "" : new Date(item.starting_time).toLocaleString(),
              Ending_Time: ((item.ending_time == null) || (item.ending_time == undefined) || (item.ending_time === "")) ? "" : new Date(item.ending_time).toLocaleString(),
              Is_Overdue: ((item.remaining_leasing_term == null) || (item.remaining_leasing_term == undefined)) ? "" : LEASINGTERM[(item.remaining_leasing_term || "")],
              Installed_Agent: ((item.agent_installed == null) || (item.agent_installed == undefined)) ? "" : item.agent_installed,
              Machine_Status: ((item.connection_status == null) || (item.connection_status == undefined)) ? "" : CONNECTIONSTATUS[item.connection_status],
              Last_Connect_Time: ((item.updated_at === null) || (item.updated_at === undefined) || (item.updated_at === "")) ? "" : new Date(item.updated_at).toLocaleString(),
              Created_at: ((item.created_at === null) || (item.created_at === undefined) || (item.created_at === "")) ? "" : new Date(item.created_at).toLocaleString(),
              Updated_at: ((item.updated_at === null) || (item.updated_at === undefined) || (item.updated_at === "")) ? "" : new Date(item.updated_at).toLocaleString(),
              Deleted_at: ((item.deleted_at === null) || (item.deleted_at === undefined) || (item.deleted_at === "")) ? "" : new Date(item.deleted_at).toLocaleString()
            };

            if ((endpoint.LeasingOutOrNot) && !this.leasing_out_or_not_list.includes(endpoint.LeasingOutOrNot)) {

              this.leasing_out_or_not_list.push(endpoint.LeasingOutOrNot)

              if (this.filter === 'remaining' && endpoint.LeasingOutOrNot === "库存设备")
                this.leasing_out_or_not_list_checked.push(true);
              else if (this.filter === 'leasingOut' && endpoint.LeasingOutOrNot === "出租设备")
                this.leasing_out_or_not_list_checked.push(true);
              else
                this.leasing_out_or_not_list_checked.push(false);
            }

            if ((endpoint.Provision) && !this.provision_list.includes(endpoint.Provision)) {
              this.provision_list.push(endpoint.Provision)
              this.provision_list_checked.push(false);
            }

            if ((endpoint.Lease_Mode) && !this.leasing_mode_list.includes(endpoint.Lease_Mode)) {

              this.leasing_mode_list.push(endpoint.Lease_Mode)

              if (this.filter === 'leaseMode' && endpoint.Lease_Mode === "开启")
                this.leasing_mode_list_checked.push(true);
              else
                this.leasing_mode_list_checked.push(false);
            }

            if ((endpoint.Lock_Machine) && !this.lock_list.includes(endpoint.Lock_Machine)) {

              this.lock_list.push(endpoint.Lock_Machine)

              if (this.filter === 'lockMachine' && endpoint.Lock_Machine === "停止服务")
                this.lock_list_checked.push(true);
              else
                this.lock_list_checked.push(false);
            }

            if ((endpoint.Machine_Status) && !this.connect_status_list.includes(endpoint.Machine_Status)) {

              this.connect_status_list.push(endpoint.Machine_Status)

              if (this.filter === 'onConnection' && endpoint.Machine_Status === "已联网")
                this.connect_status_list_checked.push(true);
              else if (this.filter === 'notOnConnection' && endpoint.Machine_Status === "未联网")
                this.connect_status_list_checked.push(true);
              else
                this.connect_status_list_checked.push(false);
            }

            if ((endpoint.Is_Overdue) && !this.rent_list.includes(endpoint.Is_Overdue)) {

              this.rent_list.push(endpoint.Is_Overdue)

              if (this.filter === 'overdue' && endpoint.Is_Overdue === '已逾期')
                this.rent_list_checked.push(true);
              else if (this.filter === 'lessThan30d' && this.rent_list[each] === '30天以内')
                this.rent_list_checked.push(true);
              else if (this.filter === 'moreThan30d' && (endpoint.Is_Overdue === '30天至3个月' || endpoint.Is_Overdue === '超过3个月'))
                this.rent_list_checked.push(true);
              else
                  this.rent_list_checked.push(false);
            }

            e.push(endpoint)
          }
          this.EndpointList = e;
          this.total_endpoints_num = Object.keys(this.EndpointList).length
        }

        return true;
      }).catch(error => {
          let responseDialogMessage = "无法获取数据。";
          if (error.response != null && error.response != undefined) {
            if (error.response.status === 500) {
              responseDialogMessage += "\nerror: 服务器错误，请联系管理员。";
            } else if (error.response.data != null && error.response.data != undefined && error.response.data.data != null && error.response.data.data != undefined) {
              responseDialogMessage += "\nerror: " + error.response.data.data;
            } else {
              responseDialogMessage += "\nerror: 网络链接失败。";
            }
          } else {
            responseDialogMessage += "\nerror: 未知错误，请稍后再试。";
          }
          alert(responseDialogMessage);
          return false;
        });
    },
    InitialDetailActionData(sn) {
      return getAction(sn).then(res => {
        if (res.status === "fail")
          alert("对" + sn + '获取操作历史失败。\n' + res.data);
        if (res.status === "success") {
          let actions = res.actions

          if (actions.length > 0) {
            let tmp = actions.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });

            this.actionList = tmp.map(item => {
              let action_name = ""
              if((item.job_id != null) && (item.job_id != undefined)){
                action_name = JOBTYPE[item.job_id.split("*")[1]] ? JOBTYPE[item.job_id.split("*")[1]] : item.job_id.split("*")[1]
              }
              else if((item.command != null) && (item.command != undefined))
                action_name = ACTIONTYPE[item.command] ? ACTIONTYPE[item.command] : item.command

              let action = {
                  User: item.user_id ? item.user_id : (item.job_id ? item.job_id.split("*")[0] : "系统"), // provision, 系统
                  Action: action_name,
                  Sending_Time: ((item.created_at == null) || (item.created_at == undefined) || (item.created_at === "")) ? "" : new Date(item.created_at).toLocaleString(),
                  Update_Time: ((item.updated_at == null) || (item.updated_at == undefined) || (item.updated_at === "")) ? "" : new Date(item.updated_at).toLocaleString(),
                  Machine_SN: item.sn,
                  Machine_ID: ((item.machine_id == null) || (item.machine_id == undefined)) ? "" : item.machine_id,
                  Status: ((item.status == null) || (item.status == undefined)) ? "" : (ACTIONRESULT[item.status] ? ACTIONRESULT[item.status] : item.status),
                  Error_Message: ((item.error_msg == null) || (item.error_msg == undefined)) ? "" : item.error_msg,
              };

              if ((action.Action) && !this.action_list.includes(action.Action)) {
                this.action_list.push(action.Action)
                if (this.filter === 'provision_failed' && action.Action === "安全配置")
                  this.action_list_checked.push(true);
                else
                  this.action_list_checked.push(false);
              }
              if ((action.User) && !this.user_list.includes(action.User)) {
                this.user_list.push(action.User)
                this.user_list_checked.push(false);
              }
              if ((action.Status) && !this.status_list.includes(action.Status)) {
                this.status_list.push(action.Status)
                if (this.filter === 'provision_failed' && action.Status === "失败")
                  this.status_list_checked.push(true);
                else
                  this.status_list_checked.push(false);
              }
              return action
            })

            this.total_actions_num = Object.keys(this.actionList).length
          }
        }
      }).catch(error => {
          if (error.response != null && error.response != undefined){
            let responseDialogMessage = "无法获取endpoint数据。"
            if (error.response.data != null && error.response.data != undefined && error.response.data.data!= null && error.response.data.data != undefined)
              responseDialogMessage += "\nerror: " + error.response.data.data;
            else
              responseDialogMessage += "\nerror: 网络链接失败。";
            alert(responseDialogMessage);
            return false;
          }
        });
    },
    handleFilterByStr(str) {
      this.filterName = String(str);
      this.InitialData().then(() => {
        this.EndpointList = this.EndpointList.filter(item => {
          return searchStr(item, str)
        })
      })
    },
    handleDetailFilterByStr(sn, str) {
      this.InitialDetailActionData(sn).then(() => {
        this.actionList = this.actionList.filter(item => {
          const properties = [
            'User', 'Action', 'Sending_Time', 'Update_Time',
            'Machine_SN', 'Machine_ID', 'Status', 'Error_Message'
          ];

          for (let prop of properties) {
            if (item[prop] !== null && item[prop] !== undefined) {
              if (String(item[prop]).includes(str)) {
                return true;
              }
            }
          }
          return false;
        });
      });
    },
    showDetails(endpoint) {
      this.InitialDetailsData(endpoint)
      setItem("sn", JSON.stringify(endpoint.SN))
      this.$store.commit('setIsOverview', false)
      this.$store.commit('setTabName', '总览');
      this.isOverviewShow = !this.isOverviewShow;
      this.isDetailsShow = !this.isDetailsShow;
    },
    InitialDetailsData(endpoint) {
      this.selectedEndpointData = endpoint;
      this.InitialDetailActionData(endpoint.SN).then(res => {
        return res
      })
    },
    handleUpdateLeasingMode(mode) {
      updateLeasing(mode).then(res => {
        if (res.status === "success"){
        //   if (Object.keys(res.fail_stats).length !== 0) {
            // let tmp = '更改租赁模式失败。具体失败原因：\n'
            // for (let key in res.fail_stats)
            //   tmp = tmp + " " + res.fail_stats[key] + '\n'
            // alert(tmp)
          // }
          // else {
            setItem('activeMenu', '操作历史');
            this.$router.push('/History');
          // }
        }
        if (res.status === "fail")
          alert('更改租赁模式任务发送失败。\n' + res.data)
      }).catch(error => {
      })
    },
    handleUpdateLeasingBuff(buff) {
      updateLeasingBuff(buff).then(res => {
        if (res.status === "success"){
          // if (Object.keys(res.fail_stats).length !== 0) {
            // let tmp = '更改租赁缓冲期失败。具体失败原因：\n'
            // for (let key in res.fail_stats)
            //   tmp = tmp + " " + res.fail_stats[key] + '\n'
            // alert(tmp)
          // }
          // else {
            setItem('activeMenu', '操作历史');
            this.$router.push('/History');
          // }
        }
        if (res.status === "fail")
          alert('更改租赁缓冲期任务发送失败。\n' + res.data)
      }).catch(error => {
      })
    },
    handleSendMessageJob(message_job) {
      sendMessageJob(message_job).then(res => {
        if (res.status === "success"){
          if (Object.keys(res.fail_stats).length !== 0) {
            let tmp = '批量发送消息失败。具体失败原因：\n'
            for (let key in res.fail_stats)
              tmp = tmp + " " + res.fail_stats[key] + '\n'
            alert(tmp)
          }
          else {
            setItem('activeMenu', '操作历史');
            this.$router.push('/History');
          }
        }
        if (res.status === "fail")
          alert('批量发送消息任务发送失败。\n' + res.data)
      }).catch(error => {
      })
    },
    handleLockMachines(job) {
      updateLeasing(job).then(res => {
        if (res.status === "success"){
          // if (Object.keys(res.fail_stats).length !== 0) {
            // let tmp = '停止服务失败。具体失败原因：\n'
            // for (let key in res.fail_stats)
            //   tmp = tmp + " " + res.fail_stats[key] + '\n'
            // alert(tmp)
          // }
          // else {
            setItem('activeMenu', '操作历史');
            this.$router.push('/History');
          // }
        }

        if (res.status === "fail")
          alert('停止服务任务发送失败。\n' + res.data)
      }).catch(error => {
      })
    },
    handleReturn(job) {
      evaluateAssetRecovery(job).then(res => {
        if (res.status === "success"){
          if (Object.keys(res.fail_stats).length !== 0) {
            let tmp = '资产评估失败。具体失败原因：\n'
            for (let key in res.fail_stats)
              tmp = tmp + " " + res.fail_stats[key] + '\n'
            alert(tmp)
          }
          else {
            setItem('activeMenu', '操作历史')
            this.$router.push('/History')
          }
        }

        if (res.status === "fail")
          alert('资产评估任务发送失败。\n' + res.data)
      }).catch(error => {
      })
    },
    handleUpdateMachine(machine_update){
      updateMachine(machine_update).then(res => {
        if (res.status !== "success") {
          let tmp = '更新机器信息发送失败。具体失败原因：\n'
            tmp = tmp + res.data + '\n'
          alert(tmp)
        }
        else {
        }
        location.reload()
      })
    }
  },
  created() {
    this.isOverviewShow = this.$store.state.isOverviewShow;
    this.isDetailsShow = !this.$store.state.isOverviewShow;
    if (this.isOverviewShow) {
      this.$store.commit('setTabName', '总览');
      this.InitialData()
    }
    if (this.isDetailsShow) {
      this.InitialData().then(res => {
        let sn = getItem('sn').replace(/"/g, '')
          let endpoint = this.EndpointList.filter(item => {
                return item.SN === sn
            })[0]
          this.InitialDetailsData(endpoint)
      })
    }
  },

}

function searchStr(ob, str) {
  let tmp = false
  const fieldsToCheck = ['Name', 'EndGroup', 'Machine_Status', 'Last_Connect_Time', 'Is_Overdue'];
  for (let key in ob) {
    if ((ob[key] !== null) && (ob[key] !== undefined)) {
      if (fieldsToCheck.includes(key)) {
        if (typeof (ob[key]) === "string" && ob[key].includes(str)) return true;
        if (typeof (ob[key]) === "number" && ob[key].toString().includes(str)) return true;
      }
      else if (typeof (ob[key]) === 'object') {
        tmp = searchStr(ob[key], str)
        if (tmp) return true
      }
    }
  }
  return tmp
}

</script>