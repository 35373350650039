import admin_api from '../adminRequest'
export const sendMessage = (message) => admin_api.post('/action/add', message)

export const sendMessageJob = (message_job) => admin_api.post('/action/batch_add', message_job)

export const getFailedActions = () => admin_api.get('/action/get_all?status=fail')

export const getIncompleteActions = () => admin_api.get('/action/get_all?status=incomplete')

export const evaluateAssetRecovery = (job) => admin_api.post('/asset/recover', job)

export const cancelIncompleteAction = (machine_action) => admin_api.post('/action/update', machine_action)

export const getAction = (sn) => admin_api.get('action/get?sn=' + sn)

export const getAllJob = () => admin_api.get('job/summary')

export const getOneJob = (job_id) => admin_api.get('job/get?job_id=' + job_id)
