<template>
    <div>
      <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;">
      <div @click="openFileInput" class="btn-content">选择文件</div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      openFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        this.$emit('importExcel', file);
        event.target.value = '';
      }
    }
  }
  </script>
  
  <style>
  .btn-content {
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #c4c6ca;
        background-color: #004a86;
        color: #fff;
        border-radius: 4px;
        font-weight: 500;
        padding: 5px 14px;
        margin: 0 5px;
        vertical-align: middle;
        cursor: pointer;
    }
  </style>