<template>
  <div v-if="this.fields != null && this.fields.includes(this.title)" class="card_update">
    <h3>{{ title }}</h3>
    <div v-if="Array.isArray(info)">
      <div v-for="(item, index) in info" :key="index">
        <div v-for="(value, key) in item" :key="key">
          <strong>{{ key }}:</strong> {{ value }}
        </div>
        <hr v-if="index < info.length - 1" />
      </div>
    </div>
    <div v-else>
      <div v-for="(value, key) in info" :key="key">
        <strong>{{ key }}:</strong> {{ value }}
      </div>
    </div>
  </div>
  <div v-else class="card">
    <h3>{{ title }}</h3>
    <div v-if="Array.isArray(info)">
      <div v-for="(item, index) in info" :key="index">
        <div v-for="(value, key) in item" :key="key">
          <strong>{{ key }}:</strong> {{ value }}
        </div>
        <hr v-if="index < info.length - 1" />
      </div>
    </div>
    <div v-else>
      <div v-for="(value, key) in info" :key="key">
        <strong>{{ key }}:</strong> {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HardwareCard',
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    fields: {
      type: Array
    },
  }
}
</script>

<style scoped>
.card {
  height: 270px;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 350px;
  font-size: 1rem;
  overflow: auto;
}

.card_update{
  height: 270px;
  border: 1px solid #ddd;
  color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  background-color: #0054ae;
  width: 100%;
  max-width: 350px;
  font-size: 1rem;
  overflow: auto;
}

.card h3 {
  margin-top: 0;
  color: #0054ae;
}

.card hr {
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 16px 0;
}
</style>