<template>
    <div>
        <dialog ref="dialog">
            <div class="dialog-header">
                <slot name="header">
                    {{ title }}
                    <i-button label="×" @click="handleCancel"
                        :css-style="{ float: 'right', border: 'none', outline: 'none', margin: '0', padding: '0', fontSize: '1.2rem' }"></i-button>
                </slot>
            </div>
            <div class="dialog-body flex-display">
                <i-form-div :label="'文件上传'" :required="true">
                    <template slot="body">
                        <div class="filenamebox">{{ filename }}</div>
                        <div class="hover-container btn-box" @mouseenter="showImportTooltip = true"
                            @mouseleave="showImportTooltip = false" style="cursor: pointer;">
                            <i-input-file @importExcel="importExcel"></i-input-file>
                            <div class="importTooltip" v-if="showImportTooltip">
                                <p>请注意：租期格式为YYYY-MM-DDTHH:MM:SS</p>
                            </div>
                        </div>
                        <div class="hover-container btn-box" @mouseenter="showExportTooltip = true"
                            @mouseleave="showExportTooltip = false">
                            <div class="btn-content" @click="exportExampleExcel">导出模板Excel</div>
                            <div class="exportTooltip" v-if="showExportTooltip">
                                <p>点击“导出模板Excel”，可下载模板。</p>
                            </div>
                        </div>
                    </template>
                </i-form-div>

                <div v-if="showNote" style="padding-top : 15px; color:red">当前文件为空，请重新选择文件</div>
                <div style="padding-top : 15px">请参考以下格式：</div>
                <img alt="示例图片" src="../assets/img/upload_example.png">
            </div>

            <div class="dialog-footer">
                <slot name="footer">
                    <i-button v-if="showConfirm" label="确认" @click="handleConfirm"></i-button>
                    <i-button label="取消" @click="handleCancel"></i-button>
                </slot>
            </div>
        </dialog>
        <i-wrong-format-data-dialog :show="showWrongFormatDataDialog" @confirm="handleConfirmWrongFormatData" @cancel="handleCancelWrongFormatData"></i-wrong-format-data-dialog>
    </div>
</template>

<script>
const ExcelDataEnum = {
    sn: 'SN',
    租期: 'Ending_Time',
    自定义: 'custom_EndpointGroup'
};

import Button from './Button.vue';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import FormDiv from './FormDiv.vue';
import WrongFormatDataDialog from './WrongFormatDataDialog.vue';
import InputFile from './InputFile.vue';
import { setItem } from "../utils/storage.js";

export default {
    name: 'i-dialog',
    components: {
        "i-button": Button,
        "i-form-div": FormDiv,
        "i-wrong-format-data-dialog": WrongFormatDataDialog,
        "i-input-file": InputFile,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            headers: [],
            showExportTooltip: false,
            showImportTooltip: false,
            filename: '',
            showWrongFormatDataDialog: false,
            dataWithWrongFormat: [],
            showNote: false,
            showConfirm: false
        }
    },
    watch: {
        show: {
            handler: function (newV) {
                if (newV) this.$refs.dialog.showModal()
                else this.$refs.dialog.close()
            }
        }
    },
    mounted() {
        if (this.show) {
            this.$refs.dialog.showModal()
        }
    },
    methods: {
        handleCancel(event) {
            this.$emit('cancel', event)
            this.clearFileInput();
            this.showNote = false
            this.showConfirm = false
            setItem("lastTime", new Date().getTime())
        },
        handleConfirm(event) {
            this.$emit('confirm', event)
            setItem("lastTime", new Date().getTime())
        },
        importExcel(file) {
            this.filename = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                this.headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                let json = XLSX.utils.sheet_to_json(worksheet);
                this.showNote = false
                json = json.filter(row => {
                    return Object.values(row).some(value => value !== null && value !== undefined && value !== '');
                });
                if(Object.keys(json).length === 0){
                    this.showNote = true
                    this.showConfirm = false
                    return
                }
                this.showConfirm = true

                if (this.validExcelData(json)) {
                    this.$emit('loadExcel', json, this.headers);
                } else {
                    this.showWrongFormatDataDialog = true;
                    this.clearFileInput();
                    this.showConfirm = false
                }
            };
            reader.readAsArrayBuffer(file);
            setItem("lastTime", new Date().getTime())
        },
        validExcelData(json) {
            setItem("lastTime", new Date().getTime())
            const snSet = new Set();

            let dataWithWrongFormat = []
            var isWrong = true
            for (let each of json) {
                if (each.sn === "" || !/^[a-zA-Z0-9-_]+$/.test(each.sn)) {
                    each["错误原因"] = (((each["错误原因"] == null)||(each["错误原因"] == undefined)) ? "":each["错误原因"]) + 'SN 字段不能为空且只能包含字母、数字、- 和 _'
                    isWrong = false
                }
                if (snSet.has(each.sn)) {
                    each["错误原因"] = (((each["错误原因"] == null)||(each["错误原因"] == undefined)) ? "":each["错误原因"]) + 'SN 字段有重复，请重新上传'
                    isWrong = false
                }
                snSet.add(each.sn);

                if (!isTimeFormatConsistent(each["租期开始时间"])) {
                    each["错误原因"] = (((each["错误原因"] == null)||(each["错误原因"] == undefined)) ? "":each["错误原因"]) + '租期开始时间格式错误'
                    isWrong = false
                }
                if (!isTimeFormatConsistent(each["租期结束时间"])) {
                    each["错误原因"] = (((each["错误原因"] == null)||(each["错误原因"] == undefined)) ? "":each["错误原因"]) + '租期结束时间格式错误'
                    isWrong = false
                }

                if(!compareDate(each["租期开始时间"], each["租期结束时间"])){
                    each["错误原因"] = (((each["错误原因"] == null)||(each["错误原因"] == undefined)) ? "":each["错误原因"]) + '租期结束时间早于租期开始时间'
                    isWrong = false
                }

                if((each["设备组"] != null)&&(each["设备组"] != undefined))
                    each['设备组'] = removeSpecialCharacters(each['设备组']);
                if((each["自定义1"] != null)&&(each["自定义1"] != undefined))
                    each['自定义1'] = removeSpecialCharacters(each['自定义1']);
                if((each["自定义2"] != null)&&(each["自定义2"] != undefined))
                    each['自定义2'] = removeSpecialCharacters(each['自定义2']);

                dataWithWrongFormat.push(each)
            }

            this.dataWithWrongFormat = dataWithWrongFormat

            if (isWrong === false)
                return false
            else
                return true;
        },
        clearFileInput() {
            const fileInput = this.$refs.fileInput;
            if (fileInput) {
                fileInput.value = '';
                fileInput.files = new DataTransfer().files;
            }
            this.filename = '';
            setItem("lastTime", new Date().getTime())
        },
        exportExampleExcel() {
            setItem("lastTime", new Date().getTime())
            const jsonData = [
                // 需要导出的数据
                {
                    'sn': 'example00001',
                    '租期开始时间': '2024-04-10T09:00:00',
                    '租期结束时间': '2026-04-10T09:00:00',
                    '设备组': 'Group1',
                    '自定义1': "",
                    '自定义2': ""
                },
                {
                    'sn': 'example00002',
                    '租期开始时间': '2024-04-10T09:00:00',
                    '租期结束时间': '2026-04-10T09:00:00',
                    '设备组': 'Group2',
                    '自定义1': "",
                    '自定义2': ""
                }
            ];
            this.exportExcel(jsonData, 'example_data.xlsx')
        },
        exportExcel(jsonData, filename) {
            const ws = XLSX.utils.json_to_sheet(jsonData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
            saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), filename);
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmWrongFormatData(){
            this.exportExcel(this.dataWithWrongFormat, 'data.xlsx')
            this.showWrongFormatDataDialog = false
            setItem("lastTime", new Date().getTime())
        },
        handleCancelWrongFormatData(){
            this.showWrongFormatDataDialog = false
            setItem("lastTime", new Date().getTime())
        }
    }
}

function removeSpecialCharacters(str) {
    return str.toString().replace(/[^\d\w\u4e00-\u9fa5]/g, '');
}

function isTimeFormatConsistent(timeStr) {
    const regex = /^\d{4}-\d{2}-\d{2}T(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/; // 正则表达式匹配YYYY-MM-DDTHH:MM:SS格式
    if(!regex.test(timeStr))
        return false

    const date = new Date(timeStr);
    return !isNaN(date.getTime());
}

function compareDate(str1, str2){

    const date1 = new Date(str1);
    const date2 = new Date(str2);

    if (date1 > date2)
        return false
    else
        return true
}
</script>
<style scoped>
dialog {
    display: flex;
    flex-direction: column;
    width: 700px;
    border-radius: 4px;
    border: none;
}

dialog:not([open]) {
    display: none;
}

.dialog-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.dialog-body {
    min-height: 120px;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}

img {
    height: 185px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.hover-container {
    position: relative;
    /* 确保提示框能够相对于这个容器定位 */
    display: inline-block;
    /* 或者其他适合你的布局的值 */
    /* 其他样式 */
}

.importTooltip {
    position: absolute;
    top: 120%;
    /* 根据需要调整位置 */
    left: 50%;
    /* 居中显示 */
    width: 200px;
    transform: translateX(-18%);
    background-color: #dbdbdb;
    color: #000000;
    padding: 5px 10px;
    border-radius: 5px;
    /* 其他样式，如箭头、阴影等 */
    /* 注意：你可能需要添加z-index以确保提示框在其他内容之上 */
    z-index: 10;
    transition: opacity 0.3s ease;
    /* 添加过渡效果 */
}

.hover-container.show-importTooltip .importTooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 0.1;
}

.exportTooltip {
    position: absolute;
    top: 120%;
    /* 根据需要调整位置 */
    left: 50%;
    /* 居中显示 */
    width: 180px;
    transform: translateX(-30%);
    background-color: #dbdbdb;
    color: #000000;
    padding: 5px 10px;
    border-radius: 5px;
    /* 其他样式，如箭头、阴影等 */
    /* 注意：你可能需要添加z-index以确保提示框在其他内容之上 */
    z-index: 10;
    transition: opacity 0.3s ease;
    /* 添加过渡效果 */
}

.hover-container.show-exportTooltip .exportTooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 0.1;
}

.btn-box {
    display: inline-block;
    vertical-align: bottom;
    position: relative;

    .btn-content {
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #c4c6ca;
        background-color: #004a86;
        color: #fff;
        border-radius: 4px;
        font-weight: 500;
        padding: 5px 14px;
        margin: 0 5px;
        vertical-align: middle;
        cursor: pointer;
    }
}

.filenamebox {
    display: flex;
    border: 1px solid #c5c6c6;
    border-radius: 4px;
    width: 180px;
    margin-right: 10px;
    align-items: center;
    padding: 5px;
}
</style>