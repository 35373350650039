<template>
    <div class="actionHistoryDetails">
        <i-tab>
            <i-tab-item name="操作历史详情" selected="true">
                <div class="create-search">
                    <span>这些端点已安装“英特尔APM代理”，可以由“英特尔APMServer”进行管理。</span>
                    <!-- will provide more style of td -->
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="filterName" placeholder="输入搜索关键字"
                            @handleEnter="handleDetailFilterByStr"></i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size" style="cursor: pointer;"
                            @click="handleDetailFilterByStr">
                    </div>
                </div>
                <div style="display: flex; align-items: flex-start">
                    <div class="filter">
                        <div class="filter-header">
                            <span>过滤器</span>
                            <span @click="handleClearFilters">全选</span>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">联网状态</div>
                            <div v-for="(connect_status, index) in connect_status_list" :key="connect_status.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="connect_status_list_checked[index]" :index="index"
                                        @handleUpdate="handleConnectStatusUpdate"></i-checkbox>
                                    <span>{{ connect_status }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">处理结果</div>
                            <div v-for="(status, index) in status_list" :key="status.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="status_list_checked[index]" :index="index"
                                        @handleUpdate="handleStatusUpdate"></i-checkbox>
                                    <span>{{ status }}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style="flex: 1">
                        <div class="action-search">
                            <div>
                                此操作对{{ totalActionNum }}台不同机器执行相同命令，目前过滤出{{ actionsFilterNum }}台，选择了{{ actionSelectedNum }}条操作。
                                <span v-if="error_message" class="error-message">({{ error_message }})</span>
                            </div>
                            <div>
                                <i-select-option :options="actionOption" :selectedOption="'请选择操作'" @actions="actions" style="width: 150px"></i-select-option>
                            </div>
                        </div>
                        <table cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" id="selectAll" v-model="isAllSelected">
                                        序号
                                    </th>
                                    <th v-for="field in details_fields" :key="field.fid">{{ field.table_name }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in tableData" :key="item.id">
                                    <td>
                                        <input type="checkbox" :value="item" v-model="ActionSelected">
                                        <label :for="item.id">{{ item.num }}</label>
                                    </td>
                                    <td v-for="field in details_fields" :key="item.id + field.name">
                                        <template v-if="field.table_name === '机器'">
                                            <a @click.prevent="clickActionbySN(item.Machine_SN)" class="machine-link">{{ item[field.name] }}</a>
                                        </template>
                                        <template v-else>
                                            <span @click="clickActionDetailbySN(item.Machine_SN, item.Task, item.Task_Result, item.Error_Message)" style="cursor: pointer;">
                                                {{ item[field.name] }}
                                            </span>
                                        </template>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div class="action-search">
                            <div class="pagination">
                                <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                                <i-input v-model="currentPageNum"></i-input>
                                <span style="margin-left: 6px;">{{ ' / ' + totalPageNum }}</span>
                                <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                            </div>
                            <div class="page-size-container">
                                <label for="page-size-input">页面大小:</label>
                                <i-select-option :options="pageSizeOptions" :selectedOption="pageSize"  @actions="changePageSize" style="width: 65px"></i-select-option>
                            </div>
                        </div>
                    </div>
                </div>
            </i-tab-item>
        </i-tab>

        <i-delete-enpoint-dialog :show="showDeleteDialog" :title="'是否中断以下'+ actionSelectedNum + '条未完成任务？'" @cancel="handleCancelDeleteAction"
            @confirm="handleConfirmDeleteAction">
            <template slot="body">
                <i-endpoint-table :headers="selectedActionTableHeaders" :data="sortedActionSelected" />
            </template>
        </i-delete-enpoint-dialog>

    </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import DeleteEndpointDialog from './DeleteEndpointDialog.vue'
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'
import CheckBox from './CheckBox.vue'
import SelectOption from './SelectOption.vue'
import EndpointTable from './EndpointTable.vue'
import { setItem, getItem } from "../utils/storage.js";

export default {
    components: {
        "i-input": Input,
        "i-checkbox": CheckBox,
        'i-button': Button,
        "i-delete-enpoint-dialog": DeleteEndpointDialog,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-select-option": SelectOption,
        "i-endpoint-table": EndpointTable
    },
    props: {
        name: {
            type: String,
            required: true
        },
        job_id: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        details_fields: {
            type: Array,
            required: true
        },
        totalActionNum: {
            type: Number,
            required: true
        },
        connect_status_list: {
            type: Array,
            required: true
        },
        connect_status_list_checked: {
            type: Array,
            required: true
        },
        status_list: {
            type: Array,
            required: true
        },
        status_list_checked: {
            type: Array,
            required: true
        },
        error_message: String
    },
    data() {
        return {
            currentPageNum: 1,
            filterName: '',
            updateIsAllSelectedByButton: true,
            updateActionSelectedByButton: true,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            isAllSelected: false,
            ActionSelected: [],
            pageSize: localStorage.getItem('actionDetailPageSize') || 50,
            pageSizeOptions: [
                    { label: '10', value: 10 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ],
            selectedActionTableHeaders: [
                { key: 'num', name: '序号' },
                { key: 'Machine_SN', name: 'SN' },
                { key: 'Task', name: '任务' }
            ],
            actionOption:
                [
                    { label: '中断未完成操作', value: 'Delete Incompleted Action' }
                ],
            showDeleteDialog: false,
            filterData: []
        }
    },
    computed: {
        tableData: function () {
            var i = 1;
            this.filterData = this.data.filter(item => {
                let connect_status_list_checked_all = false
                for (let each in this.connect_status_list)
                    connect_status_list_checked_all  = connect_status_list_checked_all || this.connect_status_list_checked[each]

                if(connect_status_list_checked_all)
                    for (let each in this.connect_status_list)
                        if (!this.connect_status_list_checked[each] && (item.Machine_Status === this.connect_status_list[each]))
                            return false

                let status_list_checked_all = false
                for (let each in this.status_list)
                    status_list_checked_all  = status_list_checked_all || this.status_list_checked[each]

                if(status_list_checked_all)
                    for (let each in this.status_list)
                        if (!this.status_list_checked[each] && (item.Task_Result === this.status_list[each]))
                            return false

                item.num = i;
                i++;

                return true
            })

            let tmp = this.filterData.slice((this.currentPageNum - 1) * this.pageSize, this.currentPageNum * this.pageSize);
            return tmp;
        },
        totalPageNum: function () {
            let len = Math.ceil(this.filterData.length / this.pageSize)
            if (len === 0) len = 1
            if (len < this.currentPageNum) {
                this.currentPageNum = len
            }
            return len
        },
        actionsFilterNum(){
            return Object.keys(this.filterData).length
        },
        sortedActionSelected() {
            return this.ActionSelected.sort((a, b) => a.num - b.num);
        },
        actionSelectedNum() {
            return Object.keys(this.ActionSelected).length
        }
    },
    watch: {
        isAllSelected(isAllSelected) {
            if (!this.updateIsAllSelectedByButton) {
                this.updateIsAllSelectedByButton = true;
                return;
            }
            if (isAllSelected) {
                this.ActionSelected = this.tableData.slice();
            } else {
                this.ActionSelected = [];
            }
            this.updateActionSelectedByButton = false;
            setItem("lastTime", new Date().getTime())
        },
        ActionSelected() {
            if (!this.updateActionSelectedByButton) {
                this.updateActionSelectedByButton = true;
                return;
            }
            if (this.tableData && this.tableData.length && this.isAllSelected !== this.tableData.every(item => this.ActionSelected.includes(item))) {
                this.isAllSelected = !this.isAllSelected;
                this.updateIsAllSelectedByButton = false;
            }
            setItem("lastTime", new Date().getTime())
        }
    },
    methods: {
        pageDown() {
            if (this.currentPageNum !== 1) this.currentPageNum--;
            this.isAllSelected = false;
            this.ActionSelected = [];
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if (this.currentPageNum !== this.totalPageNum) this.currentPageNum++;
            this.isAllSelected = false;
            this.ActionSelected = [];
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPageNum = event.target.value
            setItem("lastTime", new Date().getTime())
        },
        changePageSize(pageSize) {
            this.pageSize = pageSize
            setItem('actionDetailPageSize', pageSize);
            setItem("lastTime", new Date().getTime())
        },
        actions(action) {
            switch (action) {
                case "Delete Incompleted Action":
                    this.deleteIncompletedAction();
                    break;
            }
            setItem("lastTime", new Date().getTime())
        },
        handleDetailFilterByStr() {
            this.$emit('handleDetailFilterByStr', this.job_id, this.filterName)
            setItem("lastTime", new Date().getTime())
            this.isAllSelected = false;
            this.ActionSelected = [];
        },
        handleClearFilters() {
            for (let each in this.connect_status_list_checked)
                this.$set(this.connect_status_list_checked, each, true);
            for (let each in this.status_list_checked)
                this.$set(this.status_list_checked, each, true);
            this.isAllSelected = false;
            this.ActionSelected = [];
            setItem("lastTime", new Date().getTime());
        },
        handleConnectStatusUpdate(e, index) {
            this.$set(this.connect_status_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.ActionSelected = [];
            this.isAllSelected = false;
        },
        handleStatusUpdate(e, index) {
            this.$set(this.status_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.isAllSelected = false;
            this.ActionSelected = [];
        },
        deleteIncompletedAction() {
            if (this.ActionSelected.length === 0)
                alert("请先选择任务。")
            else {
                for (let index in this.ActionSelected) {
                    if (this.ActionSelected[index]["Task_Result"] !== "未完成") {
                        alert('所选包含非“未完成”状态，请重新进行选择！');
                        return
                    }
                }
                this.showDeleteDialog = true;
            }
            setItem("lastTime", new Date().getTime())
        },
        handleCancelDeleteAction() {
            this.showDeleteDialog = false;
            setItem("lastTime", new Date().getTime())
            location.reload();
        },
        handleConfirmDeleteAction() {
            for (let index in this.ActionSelected) {

                let machine_action_id = this.ActionSelected[index]["Machine_Action_ID"].toString()
                let user_id = getItem("userName")
                var action = {
                    "machine_action_id": machine_action_id,
                    "user_id": user_id,
                    status: "fail",
                    "error_msg": "Cancelled this task by " + user_id + "."
                }
                this.$emit('handleDeleteAction', action);
            }

            this.showDeleteDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        clickActionbySN(sn) {
            this.$store.commit('setTabName', "总览")
            this.$store.commit('setIsOverview', false)

            setItem('sn', sn)
            setItem('activeMenu', '设备')
            setItem('clickActionDetail', true)
            this.$router.push('/Endpoint')
            setItem("lastTime", new Date().getTime())
        },
        clickActionDetailbySN(sn, task, result, message) {
            if((task === "资产评估")&&(message !== ""))
                this.$store.commit('setTabName', "硬件信息")
            else
               this.$store.commit('setTabName', "操作记录")
            this.$store.commit('setIsOverview', false)

            setItem('sn', sn)
            setItem('activeMenu', '设备')
            setItem('clickActionDetail', true)
            this.$router.push('/Endpoint')
            setItem("lastTime", new Date().getTime())
        }
    }
}



</script>

<style scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}

.page-size-container {
    display: flex;
    align-items: center;
}

.page-size-container label {
    margin-right: 10px;
}

.page-size-container select {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.machine-link:hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.search-input {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.filter {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filter-header span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0054ae;
}

.filter-header span:nth-child(2) {
    color: #00285a;
    font-size: 0.8rem;
}

.filter-header span:nth-child(2):hover {
    cursor: pointer;
}

.filter-area {
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 5px;
}

.filter-item span {
    margin-left: 10px;
    font-size: 0.8rem;
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-left: 5px;
}
</style>