<template>
    <div class="details">
        <i-tab>
            <i-tab-item name="总览" selected="">
                <i-text v-for="field in details_fields" :key="field.name" :label="field.table_name"
                    :value="data[field.name]">
                </i-text>
            </i-tab-item>
            <i-tab-item name="硬件信息" selected="">
                <div v-if="data.Updated_Hardware_Data !== null">
                    <div style="display: flex; align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="24" height="24">
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-9h2v6h-2zm0-4h2v2h-2z" />
                        </svg>
                        <span style="padding-left: 5px;">发现当前硬件设备信息与默认硬件信息不一致，是否将当前硬件信息设置为默认硬件信息。</span>
                        <i-button :label="'查看详情'" @click="clickShowUpdateProp"
                            style="background-color:#0054ae; color:white"></i-button>
                    </div>
                    <div class="hardware-grid">
                        <hardware-card v-for="(section, title) in data.Updated_Hardware_Data" :key="title"
                            :title="title" :info="section" :fields="data.Updated_Fileds"></hardware-card>
                    </div>
                </div>
                <div v-else>
                    <div class="hardware-grid">
                        <hardware-card v-for="(section, title) in data.Origin_Hardware_Data" :key="title" :title="title"
                            :info="section"></hardware-card>
                    </div>
                </div>
            </i-tab-item>
            <i-tab-item name="操作记录" selected="">
                <div class="create-search">
                    <span>这些端点已安装“英特尔APM代理”，可以由“英特尔APMServer”进行管理。</span>
                    <!-- will provide more style of td -->
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="filterName" placeholder="输入搜索关键字"
                            @handleEnter="handleDetailFilterByStr"></i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size"
                            style="cursor: pointer;" @click="handleDetailFilterByStr">
                    </div>
                </div>
                <div style="display: flex; align-items: flex-start">
                    <div class="filter">
                        <div class="filter-header">
                            <span>过滤器</span>
                            <span @click="handleClearFilters">全选</span>
                        </div>
                        <div class="filter-area">

                            <div style="margin-bottom: 10px;">操作员</div>
                            <div v-for="(user, index) in user_list" :key="user.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="user_list_checked[index]" :index="index"
                                        @handleUpdate="handleUserUpdate"></i-checkbox>
                                    <span>{{ user }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">任务</div>
                            <div v-for="(task, index) in action_list" :key="task.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="action_list_checked[index]" :index="index"
                                        @handleUpdate="handleActionUpdate"></i-checkbox>
                                    <span>{{ task }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">处理结果</div>
                            <div v-for="(status, index) in status_list" :key="status.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="status_list_checked[index]" :index="index"
                                        @handleUpdate="handleStatusUpdate"></i-checkbox>
                                    <span>{{ status }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="flex: 1">
                        <div>此机器共有{{ total_actions_num }}条操作记录，目前过滤出{{ actionsFilterNum }}条。</div>
                        <table cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th v-for="field in action_fields" :key="field.fid">{{ field.table_name }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in tableData" :key="item.id"
                                    :style="{ backgroundColor: item.highlight ? '#507dae57' : '' }">
                                    <td v-for="fieldName in action_fields.map(field => field.name)"
                                        :key="item.id + fieldName">
                                        <template v-if="fieldName === 'Machine_SN'">
                                            <a @click.prevent="showOverview(item.Machine_SN)" class="machine-link">{{ item[fieldName] }}</a>
                                        </template>
                                        <template v-else>
                                            {{ item[fieldName] }}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="action-search">
                            <div class="pagination">
                                <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                                <i-input v-model="currentPageNum"></i-input>
                                <span style="margin-left: 6px;">{{ ' / ' + totalPageNum }}</span>
                                <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                            </div>
                            <div class="page-size-container">
                                <label for="page-size-input">页面大小:</label>
                                <i-select-option :options="pageSizeOptions" :selectedOption="pageSize"
                                    @actions="changePageSize" style="width: 65px"></i-select-option>
                            </div>
                        </div>
                    </div>
                </div>
            </i-tab-item>
        </i-tab>

        <i-prop-update-dialog :show="isShowPropUpdateDialog" :title="'是否将当前硬件信息设置为默认硬件信息？'"
            @cancel="handleCancelPropUpdate" @confirm="handleConfirmPropUpdate" style="width: 2300px;">
            <template slot="body">
                <div class="origin_box">
                    <span style="padding-left: 20px;">默认硬件信息:</span>
                    <div class="hardware-grid">
                        <hardware-card v-for="(section, title) in data.Origin_Hardware_Data" :key="title" :title="title"
                            :info="section" :fields="data.Updated_Fileds" style="width : 85%"></hardware-card>
                    </div>
                </div>
            </template>
            <template slot="body">
                <div>
                    <span style="padding-left: 20px;">当前硬件信息:</span>
                    <div class="hardware-grid">
                        <hardware-card v-for="(section, title) in data.Updated_Hardware_Data" :key="title"
                            :title="title" :info="section" :fields="data.Updated_Fileds"
                            style="width : 85%"></hardware-card>
                    </div>
                </div>
            </template>
        </i-prop-update-dialog>
    </div>
</template>

<script>
import Text from './Text.vue'
import Input from './Input.vue'
import Button from './Button.vue'
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'
import CheckBox from './CheckBox.vue'
import PropUpdateDialog from './PropUpdateDialog.vue'
import HardwareCard from './HardwareCard.vue'
import { getItem, setItem } from "../utils/storage.js";
import SelectOption from './SelectOption.vue'

export default {
    components: {
        "i-text": Text,
        "i-input": Input,
        "i-prop-update-dialog": PropUpdateDialog,
        'i-button': Button,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        'hardware-card': HardwareCard,
        "i-checkbox": CheckBox,
        "i-select-option": SelectOption,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        actionList: {
            type: Array,
            required: true
        },
        details_fields: {
            type: Array,
            required: true
        },
        action_fields: {
            type: Array,
            required: true
        },
        user_list: {
            type: Array,
            required: true
        },
        user_list_checked: {
            type: Array,
            required: true
        },
        action_list: {
            type: Array,
            required: true
        },
        action_list_checked: {
            type: Array,
            required: true
        },
        status_list: {
            type: Array,
            required: true
        },
        status_list_checked: {
            type: Array,
            required: true
        },
        total_actions_num: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            dataSource: [],
            currentPageNum: 1,
            pageSize: localStorage.getItem('detailPageSize') || 50,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            filterName: '',
            filterData: [],
            pageSizeOptions: [
                    { label: '10', value: 10 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ],
            sn: '',
            isShowPropUpdateDialog: false,
        }
    },
    computed: {
        tableData: function () {
            this.dataSource = this.actionList;
            this.filterData = this.dataSource.filter(item => {
                if (this.sn && item.Machine_SN !== this.sn) return false;

                let user_list_checked_all = false
                for (let each in this.user_list)
                    user_list_checked_all  = user_list_checked_all || this.user_list_checked[each]

                if(user_list_checked_all)
                    for (let each in this.user_list)
                        if (!this.user_list_checked[each] && (item.User === this.user_list[each]))
                            return false

                let action_list_checked_all = false
                for (let each in this.action_list)
                    action_list_checked_all  = action_list_checked_all || this.action_list_checked[each]

                if(action_list_checked_all)
                    for (let each in this.action_list)
                        if (!this.action_list_checked[each] && (item.Action === this.action_list[each]))
                            return false

                let status_list_checked_all = false
                for (let each in this.status_list)
                    status_list_checked_all  = status_list_checked_all || this.status_list_checked[each]

                if(status_list_checked_all)
                    for (let each in this.status_list)
                        if (!this.status_list_checked[each] && (item.Status === this.status_list[each]))
                            return false

                return true
            })

            const highlightCondition = this.$route.query.filter === 'provision_failed';
            const threeDaysAgo = new Date();
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

            this.filterData.forEach(item => {
                const sendingTime = new Date(item.Sending_Time);
                item.highlight = highlightCondition && sendingTime >= threeDaysAgo;
            });

            let tmp = this.filterData.slice((this.currentPageNum - 1) * this.pageSize, this.currentPageNum * this.pageSize);

            return tmp;
        },
        totalPageNum: function () {
            let len = Math.ceil(this.filterData.length / this.pageSize)
            if (len === 0) len = 1
            if (len < this.currentPageNum) {
                this.currentPageNum = len
            }
            return len
        },
        actionsFilterNum(){
            return Object.keys(this.filterData).length
        }
    },
    created() {
        const sn = this.$route.query.sn;
        if (sn) {
            this.sn = sn;
            this.$emit('InitialDetailActionData', this.sn)
        }
    },
    methods: {
        pageDown() {
            if (this.currentPageNum !== 1) this.currentPageNum--
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if (this.currentPageNum !== this.totalPageNum) this.currentPageNum++
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPageNum = event.target.value
            setItem("lastTime", new Date().getTime())
        },
        changePageSize(pageSize) {
            this.pageSize = pageSize
            setItem('detailPageSize', pageSize);
            setItem("lastTime", new Date().getTime())
        },
        handleDetailFilterByStr() {
            this.$emit('handleDetailFilterByStr', this.data.SN, this.filterName)
            setItem("lastTime", new Date().getTime())
        },
        handleClearFilters() {
            for (let each in this.user_list_checked) {
                this.$set(this.user_list_checked, each, true);
            }
            for (let each in this.action_list_checked) {
                this.$set(this.action_list_checked, each, true);
            }
            for (let each in this.status_list_checked) {
                this.$set(this.status_list_checked, each, true);
            }
            setItem("lastTime", new Date().getTime());
        },
        handleUserUpdate(e, index) {
            this.$set(this.user_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
        },
        handleActionUpdate(e, index) {
            this.$set(this.action_list_checked, index, e)
            if (this.$route.query.filter == 'provision_failed')
                this.$router.push({ path: '/Endpoint' }).catch(() => { });
            setItem("lastTime", new Date().getTime())
        },
        handleStatusUpdate(e, index) {
            this.$set(this.status_list_checked, index, e)
            if (this.$route.query.filter == 'provision_failed')
                this.$router.push({ path: '/Endpoint' }).catch(() => { });
            setItem("lastTime", new Date().getTime())
        },
        clickShowUpdateProp(){
            this.isShowPropUpdateDialog = true
            setItem("lastTime", new Date().getTime())
        },
        handleCancelPropUpdate(){
            this.isShowPropUpdateDialog = false
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmPropUpdate() {
            const userConfirmed = confirm("请详细检查高亮部分，一旦确认硬件信息更改则无法回退。");
            if (!userConfirmed) {
                setItem("lastTime", new Date().getTime());
                return;
            }

            this.isShowPropUpdateDialog = false;
            setItem("lastTime", new Date().getTime());

            let machine_update = {
                "sn": this.data.SN,
                "machine_info": {
                    "origin_prop": this.data.Updated_Hardware_Data,
                    "updated_prop": null,
                    "updated_fileds": null
                }
            };

            this.$emit('handleUpdateMachine', JSON.stringify(machine_update));
        },
        showOverview(sn) {
            this.$store.commit('setTabName', "总览")
            this.$store.commit('setIsOverview', false)
            setItem('sn', sn)
            setItem("lastTime", new Date().getTime())
        }
    }
}
</script>

<style scoped>
.hardware-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    justify-items: center;
    padding: 16px;
}

@media (max-width: 1800px) {
    .hardware-grid {
        grid-template-columns: repeat(3, 1fr);
        /* 调整为2列布局 */
    }
}

@media (max-width: 1350px) {
    .hardware-grid {
        grid-template-columns: repeat(2, 1fr);
        /* 调整为2列布局 */
    }
}

@media (max-width: 768px) {
    .hardware-grid {
        grid-template-columns: 1fr;
        /* 在较小的屏幕上调整为1列布局 */
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}

.page-size-container {
    display: flex;
    align-items: center;
}

.page-size-container label {
    margin-right: 10px;
}

.page-size-container select {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.machine-link:hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.search-input {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.filter {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filter-header span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0054ae;
}

.filter-header span:nth-child(2) {
    color: #00285a;
    font-size: 0.8rem;
}

.filter-header span:nth-child(2):hover {
    cursor: pointer;
}

.filter-area {
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 5px;
}

.filter-item span {
    margin-left: 10px;
    font-size: 0.8rem;
}

.origin_box{
    border: none;
    border-right: 2px solid #00285a;
}
</style>