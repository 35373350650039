<template>
    <dialog ref="dialog">
        <div class="dialog-body">
            <slot name="body">
                {{ dialogMessage }}
            </slot>
        </div>
        <div class="dialog-footer">
            <slot name="footer">
                <i-button label="确认" @click="closeDialog()"></i-button>
            </slot>
        </div>
    </dialog>
</template>

<script>
import Button from './Button.vue';
export default {
    components: {
        "i-button": Button
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        dialogMessage: {
            type: String,
            default: ''
        }
    },
    watch: {
        show: {
            handler: function (newV) {
                if (newV) this.$refs.dialog.showModal()
                else this.$refs.dialog.close()
            }
        }
    },
    mounted() {
        if (this.show) {
            this.$refs.dialog.showModal()
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeResponseDialog')
        }
    }
}
</script>

<style scoped>
dialog {
    display: flex;
    flex-direction: column;
    width: 500px;
    border-radius: 4px;
    border: none;
}

dialog:not([open]) {
    display: none;
}

.dialog-body {
    display: flex;
    min-height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    line-height: 35px;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>