<template>
    <div class="form-div">
        <div style="width: 180px;">
            <div class="form-div-label">
                {{ label }}
                <span class="form-div-label-star" v-if="required">*</span>
            </div>
        </div>
        <div class="form-div-body">
            <slot name="body">
                body
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'i-form-div',
    props: {
        label: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
.form-div {
    display: flex;
    align-items: center;
}

.form-div-label {
    float: right;
    position: relative;
    padding: 0 10px;
    margin: 10px 10px;
}

.form-div-label-star {
    position: absolute;
    right: 0;
    top: 0;

}

.form-div-body {
    flex: 1;
    display: flex;
}
</style>