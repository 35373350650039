<template>
    <div class="header">
        <div class="header_logo">
            {{ name }}
        </div>
        <div v-if="currentPage != 'login'">
            <div style=" position: relative">
            <div class="header_user">
                <div v-if="user.name != ''" @click.stop="toggleMenu" style="cursor: pointer;">
                    <i-user-avatar :user="user"></i-user-avatar>
                </div>
                <div style="padding-left: 10px">{{ date }}</div>
            </div>
            <div v-if="menuVisible" class="dropdown-menu">
                <ul class="clickable" @click="onClick">
                    <li @click="clickChangeUserPasswd"><a href="#">更改密码</a></li>
                    <li @click="logOut"><a href="../Login">退出</a></li>
                </ul>
            </div>
            </div>
        </div>
        <i-change-user-password-dialog :show="showChangeUserPasswdDialog" :title="'修改密码'"
            @cancel="handleCancelChangeUserPasswd" @confirm="handleConfirmChangeUserPasswd">
            <template slot="body">
                <i-create-user-form :fields="changeUserPasswdFields" :form="changeUserPasswdForm">
                </i-create-user-form>
            </template>
        </i-change-user-password-dialog>
        <i-operation-response-dialog :show="showResponseDialog" :dialogMessage="responseDialogMessage"
            @closeResponseDialog="closeResponseDialog">
        </i-operation-response-dialog>
    </div>

</template>
<script>
import UserAvatar from './UserAvatar.vue';
import ChangeUserPasswdDialog from './ChangeUserPasswdDialog.vue'
import UserOperationResponseDialog from '@/components/UserOperationResponseDialog.vue'
import CreateUserForm from './CreateUserForm.vue'
import { changeUserPasswd, getAccessTokenbyRefreshToken } from '../apis/User'
import { getItem, setItem } from "../utils/storage.js";
import { LogOut } from "../utils/logout.js";

export default {
    name: 'Header',
    components: {
        "i-user-avatar": UserAvatar,
        "i-change-user-password-dialog": ChangeUserPasswdDialog,
        "i-operation-response-dialog": UserOperationResponseDialog,
        "i-create-user-form": CreateUserForm
    },
    props: {
        name: {
            type: String
        }
    },
    data() {
        return {
            menuVisible: false,
            showChangeUserPasswdDialog: false,
            showResponseDialog: false,
            responseDialogMessage: '',
            currentSelectedUser: {},
            changeUserPasswdForm: {},
            changeUserPasswdFields: prepareFields([
                { name: 'OldPasswd', type: 'password', table_name: '旧密码', isshow: true, required: true },
                { name: 'NewPasswd', type: 'password', table_name: '新密码', isshow: true, required: true },
                { name: 'NewPasswd_verify', type: 'password', table_name: '再次输入', isshow: true, required: true }
            ]),
            user: {
                avatarUrl: "",
                name: (getItem('userName') != null && getItem('userName') != undefined) ? getItem('userName'):""
            },
            currentPage: ""
        }
    },
    computed: {
        date: function () {
            var currentTime = new Date();
            var d = currentTime.toString().slice(0, 15) + currentTime.toString().slice(24, 31);
            return d
        }
    },
    created(){
        this.currentPage = this.$route.path.split("/").slice(-1)[0] ? this.$route.path.split("/").slice(-1)[0].toLowerCase():""
        this.showChangeUserPasswdDialog = getItem('showChangePassword')
    },
    methods: {
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
            document.addEventListener('click', this.onClick);
            setItem("lastTime", new Date().getTime())
        },
        onClick(event) {
            // 检查点击是否发生在div外
            if (!event.target.classList.contains('clickable')) {
                this.menuVisible = false;
                document.removeEventListener('click', this.onClick);
            }
            setItem("lastTime", new Date().getTime())
        },
        // Change user password
        clickChangeUserPasswd(user) {
            this.showChangeUserPasswdDialog = true;
            this.currentSelectedUser = user;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelChangeUserPasswd() {
            this.showChangeUserPasswdDialog = false;
            this.changeUserPasswdForm = {};
            localStorage.removeItem('showChangePassword');
            setItem("lastTime", new Date().getTime())
        },
        checkPasswordComplexity(password) {
            const minLength = 8;
            const maxLength = 128;

            const hasValidLength = password.length >= minLength && password.length <= maxLength;
            const hasUpperCase = /[A-Z]/.test(password);
            const hasLowerCase = /[a-z]/.test(password);
            const hasNumber = /[0-9]/.test(password);
            const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

            return hasValidLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
        },
        handleConfirmChangeUserPasswd() {
            // 1. Check if the fields are empty.
            let data = this.changeUserPasswdForm;
            if (Object.keys(data).length === 0 || !data.hasOwnProperty('OldPasswd') || !data.hasOwnProperty('NewPasswd') || !data.hasOwnProperty('NewPasswd_verify')) {
                this.responseDialogMessage = '请填写所有必填项';
                this.showResponseDialog = true;
                return;
            }
            // 2. Verify if the dual-input password matchs
            if (data.NewPasswd !== data.NewPasswd_verify) {
                this.responseDialogMessage = '两次密码输入不一致，请检查并重新输入';
                this.showResponseDialog = true;
                return;
            }
            // 3. Check password complexity
            if (!this.checkPasswordComplexity(data.NewPasswd)) {
                this.responseDialogMessage = '密码必须包含大写字母、小写字母、数字和特殊字符，并且长度在8到128个字符之间';
                this.showResponseDialog = true;
                return;
            }
            var user = JSON.stringify({
                "old_password": data.OldPasswd,
                "new_password": data.NewPasswd
            });
            changeUserPasswd(user).then(response => {
                this.responseDialogMessage = "用户密码修改成功\n点击 '确定' 重新登录";
                this.showResponseDialog = true;
                localStorage.removeItem('showChangePassword');
            }).catch(error => {
                this.responseDialogMessage = '用户密码修改失败'
                if(error.response != null && error.response != undefined && error.response.data != null && error.response.data != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器问题，无法修改用户密码。请联系管理员';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // changeOneUserPasswd(user)
                        }).catch(error => {
                            this.responseDialogMessage = '用户密码修改失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                        })
                    }
            })
            this.currentSelectedUser = {};
            this.changeUserPasswdForm = {};
            this.showChangeUserPasswdDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        updateToken(){
            let refreshToken = getItem('refreshToken');
            let token = {"token": refreshToken}
            return getAccessTokenbyRefreshToken(token).then(response => {
                setItem('accessToken', response.result.access_token);
                setItem('refreshToken', response.result.refresh_token);
            }).catch(error => {
                if (error.response.data.statusCode === 403){
                    if (confirm('用户已在其他地方登录。请重新登录。')) {
                        LogOut();
                        this.$router.push('/Login');
                    }
                } else if (error.response.data.statusCode === 404) {
                    alert('网络连接问题，无法更新令牌。');
                } else if (error.response.data.statusCode === 400) {
                    if (confirm('Token过期，请重新登录。')) {
                        LogOut();
                        this.$router.push('/Login');
                    }
                } else if (error.response.data.statusCode === 500) {
                    alert('服务器出错，请联系管理员。');
                } else {
                    alert('发生未知错误，请稍后再试。');
                }
            });
        },
        closeResponseDialog() {
            if (this.responseDialogMessage === "用户密码修改成功\n点击 '确定' 重新登录") {
                LogOut();
                this.$router.push('/Login');
            }
            this.showResponseDialog = false;
            this.responseDialogMessage = '';
            setItem("lastTime", new Date().getTime())
        },
        logOut(){
            LogOut();
        }
    },
}
function prepareFields(fields) {
    // Some items aren't allowed displayinlg
    let filteredFields = fields.filter(field => field.isshow)
    // Transfor the item type
    return filteredFields.map(field => {
        let item = { label_name: field.table_name, label: field.name, type: field.type }
        if (field.required) item.required = true;
        switch (field.type) {
            case 'password':
                item.type = 'password';
                break;
            case 'string':
                item.type = 'text';
                break;
            case 'bool':
                item.type = 'checkbox';
                break;
            case 'enum':
                item.type = 'select';
            default:
        }
        return item;
    })
}
</script>
<style scoped>
.header {
    flex: 0 0 auto;
    min-width: max-content;
    width: 100%;
    background-color: #004a86;
    color: white;
    font-size: 1.3rem;
    display: flex;
    justify-content: space-between;
}

.header_logo {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.header_user {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 10px;
}

.dropdown-menu {
    position: absolute;
    /* top: 50px; */
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    z-index: 2000;
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 5px 15px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #eee;
}
</style>