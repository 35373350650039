<template>
    <div>
        <div class="tab-header">
            <div v-for="(tab, index) in tabs" :key="index" @click="selectTab(tab)" :tabindex="index">{{ tab.name }}
            </div>
        </div>
        <div class="tab-divider"></div>
        <div class="tab-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { getItem, setItem } from "../utils/storage.js";

export default {
    name: 'i-tab',
    props: [],
    data() {
        return {
            tabs: []
        }
    },
    created() {
        this.tabs = this.$children
    },
    methods: {
        selectTab(selectedTab) {
            this.$store.commit('setTabName', selectedTab.name)
            this.tabs.forEach(tab => {
                tab.isActive = tab.name === selectedTab.name
                this.$emit('handleClick', selectedTab);
            })
        }
    }
}
</script>

<style scoped>
.tab-header {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-size: 1.5rem;
    color: #0054ae;
    font-weight: 700;
}

.tab-header div {
    padding: 10px;
    position: relative;
}

.tab-header div:hover {
    cursor: pointer;
    color: #00285a;
}

.tab-header div:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 1000;
    background-color: #00285a;
    height: 2px;
    width: calc(100% - 20px);
    margin: 0 10px 0 10px;
}

.tab-header div:focus {
    color: #00285a;
}

.tab-header div:focus::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 1000;
    background-color: #00285a;
    height: 2px;
    width: calc(100% - 20px);
    margin: 0 10px 0 10px;
}

.tab-divider {
    height: 2px;
    margin: 0 10px 0 10px;
    background-color: #e4e7ed;
}

.tab-content {
    padding: 10px;
}
</style>