import { checkLogout } from '../apis/User/index.js'
import { removeItems } from "./storage.js";

export function LogOut() {
    let keys = ['userName', 'accessToken', 'refreshToken', 'activeMenu', 'groupName', "job_id", "isOverviewShow", "selectedTab", 'endpoint', 'sn', 'tabCount']
    removeItems(keys);
    sessionStorage.removeItem('hasIncreasedCount');
    localStorage.setItem('logoutEvent', Date.now().toString());
    // checkLogout().then(res => {
    //     removeItems(keys)
    // }).catch(error => {
    //     alert("网络连接问题。");
    //     return false;
    // });
}