<template>
    <div>
    <div>
        <img alt="示例图片" src="../assets/img/homePage.jpeg" />
    </div>
    <div style="display: flex; ">
        <div class="left-area">
            <div class="top-area">
                <div class="left-top-area">
                    <span>状态</span>
                    <span>(Demos)</span>
                </div>
                <div class="notification-area">
                    <span class="notification-title">重要消息 (3天内)</span>
                    <div class="notification-content">
                        <div class="notification-item">
                            <template v-if="usename === 'admin'">
                                <span class="notification-link failed">{{ failedCount }} 失败,</span>
                                <span class="notification-link incomplete">{{ incompleteCount }} 未完成,</span>
                                <span v-if="provisionFailedSNs.length > 0" class="notification-link provision">
                                    [ <span v-for="(sn, index) in provisionFailedSNs" :key="sn">
                                    <span>{{ sn }}</span>
                                    <span v-if="index < provisionFailedSNs.length - 1">, </span>
                                    </span> ] 安全配置失败
                                </span>
                                <span v-else>无机器安全配置失败</span>
                            </template>
                            <template v-else>
                                <a class="notification-link failed" style="cursor: pointer;" @click="redirectToHistory('failed')">{{ failedCount }} 失败,</a>
                                <a class="notification-link incomplete" style="cursor: pointer;" @click="redirectToHistory('incomplete')">{{ incompleteCount }} 未完成,</a>
                                <span v-if="provisionFailedSNs.length > 0" class="notification-link provision">
                                    [ <span v-for="(sn, index) in provisionFailedSNs" :key="sn">
                                    <a @click="handleMessageClick(sn)" style="cursor: pointer;">{{ sn }}</a>
                                    <span v-if="index < provisionFailedSNs.length - 1">, </span>
                                    </span> ] 安全配置失败
                                </span>
                                <span v-else>无机器安全配置失败</span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="left-bottom-area">
                <div class="stat-area">
                    <div class="stat-data">
                        <span>设备组数:</span>
                        <span style="padding: 10px">{{ endpointGroupNum }}</span>
                    </div>
                    <div class="stat-data">
                        <span>设备数:</span>
                        <span style="padding: 10px">{{ endpointTotalNum }}</span>
                    </div>
                    <div class="stat-data">
                        <span>出租数:</span>
                        <span style="padding: 12px">{{ leaseOutNum }}</span>
                    </div>
                    <div class="stat-data">
                        <span>在线设备数:</span>
                        <span style="padding: 12px">{{ onlineNum }}</span>
                    </div>
                    <div class="stat-data">
                        <span>库存:</span>
                        <span style="padding: 12px">{{ storageNum }}</span>
                    </div>
                    <div class="stat-data">
                        <span>库存占比:</span>
                        <span style="padding: 12px">{{ storagePercentage }}%</span>
                    </div>
                    <div class="stat-data">
                        <a @click="handleLockMachineClick">
                        <span class="highlight" :class="{'not-admin-hover': usename !== 'admin'}">停止服务设备数:</span>
                        </a>
                        <span style="padding: 12px">{{ lockNum }}</span>
                    </div>
                    <div class="stat-data">
                        <a @click="handleLeaseModeClick">
                        <span class="highlight" :class="{'not-admin-hover': usename !== 'admin'}">租赁模式开启数:</span>
                        </a>
                        <span style="padding: 12px">{{ leaseNum }}</span>
                    </div>
                </div>
                <div>
                    <div class="graph-area">
                    <div ref="chart1" class="pie-chart" ></div>
                    <div ref="chart2" class="pie-chart"></div>
                </div>
                    <div ref="chart3" class="pie-chart"></div>
                </div>

                </div>
            </div>
            <div style="flex-grow: 1;" />
            <div class="right-area">
                <span class="right-area-title">快捷链接</span>
                <a v-if="usename != 'admin'" href="#/Endpoint" @click="setActiveMenu('设备')">查看设备</a>
                <a v-if="usename != 'admin'" href="#/EndpointGroup" @click="setActiveMenu('设备组')">管理设备组</a>
                <a href="#/User" @click="setActiveMenu('用户')">用户管理</a>
                <a v-if="usename != 'admin'" href="#/Upload" @click="setActiveMenu('数据同步')">上传租期数据</a>
                <a v-if="usename != 'admin'" href="#/History" @click="setActiveMenu('操作历史')">查看操作历史</a>
                <a href="#/Settings" @click="setActiveMenu('设置')">管理配置文件</a>
                <!-- <router-link v-if="usename != 'admin'" to="/Endpoint" @click.native.prevent="navigate('设备', '/Endpoint')">查看设备</router-link>
                <router-link v-if="usename != 'admin'" to="/EndpointGroup" @click.native.prevent="navigate('设备组', '/EndpointGroup')">管理设备组</router-link>
                <router-link to="/User" @click.native.prevent="navigate('用户', '/User')">用户管理</router-link>
                <router-link v-if="usename != 'admin'" to="/Upload" @click.native.prevent="navigate('数据同步', '/Upload')">上传租期数据</router-link>
                <router-link v-if="usename != 'admin'" to="/History" @click.native.prevent="navigate('操作历史', '/History')">查看操作历史</router-link>
                <router-link to="/Settings" @click.native.prevent="navigate('设置', '/Settings')">管理配置文件</router-link> -->
                <div style="margin-top:200px">
                    <div class="right-area-content">
                        <span class="click_able " @click="openPdf" >操作指南</span>
                    </div>
                    <div class="right-area-content">
                        <span class="click_able " @click="openVideo">入门视频</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { getAllEndpoints } from '../apis/Machine'
import { getFailedActions, getIncompleteActions } from '../apis/Action'
import { getItem, setItem } from "../utils/storage.js";

const createOption = () => {
    return {
        color: ['#00285a', '#4d8fc6', '#0054ae', '#d5f1ff', '#becae0', '#3dc6c8', '#2c7eeb', '#326092'],
        title: {
            text: 'Referer of a Website',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '8.5%',
            left: 'center'
        },
        series: [
            {
                //   name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    }
}
export default {
    name: 'Endpoint',
    data() {
        return {
            endpointGroupNum: 0,
            endpointTotalNum: 0,
            leaseOutNum: 0,
            onlineNum: 0,
            amtNum: 0,
            storageNum: 0,
            lessThan30d: 0,
            overdue: 0,
            leaseOutPercentage: 0,
            onlinePercentage: 0,
            AMTPercentage: 0,
            storagePercentage: 0,
            leaseNum: 0,
            lockNum: 0,
            GroupList: [],
            failedCount: 0,
            incompleteCount: 0,
            provisionFailedSNs: [],
            usename: (getItem('userName') != null && getItem('userName') != undefined) ? getItem('userName'):""
        }
    },
    methods: {
        InitialData() {
            // setItem("lastTime", new Date().getTime())
            var group_num = 0 //endpoint group
            var total_num = 0
            var online_num = 0 //online
            var lease_out_num = 0
            var overdue_num = 0
            var leass_than_30d = 0
            var amt_num = 0
            var lease_num = 0
            var lock_num = 0

            getAllEndpoints().then(res => {
                if (res.status === "success") {
                    let e = []

                    if ((res["endpoints"] != null) && (res["endpoints"] != undefined)) {
                        for (let each in res["endpoints"]) {
                            let item = res["endpoints"][each]

                            total_num++
                            if ((item.metadata != null) && (item.metadata != undefined))
                            if ((item.metadata["custom_EndpointGroup"] != null) && (item.metadata["custom_EndpointGroup"] != undefined))
                            if ((item.metadata["custom_EndpointGroup"]["data"] != null) && (item.metadata["custom_EndpointGroup"]["data"] != undefined))
                                if (item.metadata["custom_EndpointGroup"]["data"]) {
                                    lease_out_num++
                                    if (!this.GroupList.includes(item.metadata["custom_EndpointGroup"]["data"]))
                                        this.GroupList.push(item.metadata["custom_EndpointGroup"]["data"])
                                }

                            if ((item.connection_status != null) && (item.connection_status != undefined))
                                if (item.connection_status === "connected")
                                    online_num++

                            if ((item.remaining_leasing_term != null) && (item.remaining_leasing_term != undefined))
                                if (item.remaining_leasing_term === "less than 30 days")
                                    leass_than_30d++;
                                else if (item.remaining_leasing_term === "overdue")
                                    overdue_num++;
                                    
                            if ((item.lease_mode != null) && (item.lease_mode != undefined))
                            if (item.lease_mode)
                                if(item.lease_mode % 2 === 1) {
                                    lease_num++;
                                }
                                if(parseInt(item.lease_mode / 2) === 1) {
                                    lock_num++;
                            }
                        }
                    }
                    this.endpointTotalNum = total_num
                    this.endpointGroupNum = this.GroupList.length
                    this.leaseOutNum = lease_out_num
                    this.onlineNum = online_num
                    this.lessThan30d = leass_than_30d
                    this.overdue = overdue_num
                    this.amtNum = amt_num
                    this.storageNum = this.endpointTotalNum - this.leaseOutNum
                    this.leaseNum = lease_num
                    this.lockNum = lock_num

                    if (this.endpointTotalNum > 0) {
                        let chart1 = echarts.init(this.$refs.chart1)
                        let chart2 = echarts.init(this.$refs.chart2)
                        let chart3 = echarts.init(this.$refs.chart3)
                        let option1 = createOption()
                        option1.series[0].data = [
                            {
                                value: ((this.leaseOutNum / this.endpointTotalNum) * 100).toFixed(2),
                                name: '出租设备'
                            },
                            {
                                value: (((this.endpointTotalNum - this.leaseOutNum) / this.endpointTotalNum) * 100).toFixed(2),
                                name: '库存设备'
                            }
                        ];
                        option1.title.text = '所有设备管理'
                        option1.tooltip = {
                            formatter: function (params) {
                                return params.name + ': ' + params.value + '%';
                            }
                        }
                        chart1.on('click', function (params) {
                            if(this.usename !='admin'){
                                let url = '/Endpoint';
                                setItem('activeMenu', "设备");
                                if (params.name === '库存设备') {
                                    this.$router.push({ path: url, query: { filter: 'remaining' } }).catch(() => { });
                                } else if (params.name === '出租设备') {
                                    this.$router.push({ path: url, query: { filter: 'leasingOut' } }).catch(() => { });
                                }
                            }
                        }.bind(this));

                    let option2 = createOption()
                    option2.series[0].data = [
                        {
                            value: ((this.onlineNum / this.leaseOutNum) * 100).toFixed(2),
                            name: '在线设备'
                        },
                        {
                            value: (((this.leaseOutNum - this.onlineNum) / this.leaseOutNum) * 100).toFixed(2),
                            name: '离线设备'
                        }
                    ];
                    option2.title.text = '在线设备管理'

                    chart2.on('click', function (params) {
                        if(this.usename !='admin'){
                            let url = '/Endpoint';
                            setItem('activeMenu', "设备");
                            if (params.name === '在线设备') {
                                this.$router.push({ path: url, query: { filter: 'onConnection' } }).catch(() => { });
                            } else if (params.name === '离线设备') {
                                this.$router.push({ path: url, query: { filter: 'notOnConnection' } }).catch(() => { });
                            }
                        }
                    }.bind(this));

                        option2.tooltip = {
                            formatter: function (params) {
                                return params.name + ': ' + params.value + '%';
                            }
                        }

                    let option3 = createOption()
                    option3.series[0].data = [
                        {
                            value: (((this.leaseOutNum - this.lessThan30d - this.overdue) / this.leaseOutNum) * 100).toFixed(2),
                            name: '>30天到期'
                        },
                        {
                            value: ((this.lessThan30d / this.leaseOutNum) * 100).toFixed(2),
                            name: '即将到期'
                        },
                        {
                            value: ((this.overdue / this.leaseOutNum) * 100).toFixed(2),
                            name: '已逾期'
                        }
                    ];

                    option3.title.text = '逾期状态'

                    chart3.on('click', function (params) {
                        if(this.usename !='admin'){
                            let url = '/Endpoint';
                            setItem('activeMenu', "设备");
                            if (params.name === '>30天到期') {
                                this.$router.push({ path: url, query: { filter: 'moreThan30d' } }).catch(() => { });
                            } else if (params.name === '即将到期') {
                                this.$router.push({ path: url, query: { filter: 'lessThan30d' } }).catch(() => { });
                            } else if (params.name === '已逾期') {
                                this.$router.push({ path: url, query: { filter: 'overdue' } }).catch(() => { });
                            }
                        }
                    }.bind(this));

                        option3.tooltip = {
                            formatter: function (params) {
                                return params.name + ': ' + params.value + '%';
                            }
                        }
                        chart1.setOption(option1)
                        chart2.setOption(option2)
                        chart3.setOption(option3)
                    }

                    this.leaseOutPercentage = (this.leaseOutNum / this.endpointTotalNum * 100).toFixed(2);
                    this.onlinePercentage = (this.onlineNum / this.endpointTotalNum * 100).toFixed(2);
                    this.AMTPercentage = (this.amtNum / this.endpointTotalNum * 100).toFixed(2);
                    this.storagePercentage = this.endpointTotalNum === 0 ? 0 : (this.storageNum / this.endpointTotalNum * 100).toFixed(2);
                }
            }).catch(error => {
                let responseDialogMessage = "无法获取数据。";
                if (error.response != null && error.response != undefined) {
                    if (error.response.status === 500) {
                        responseDialogMessage += "\nerror: 服务器错误，请联系管理员。";
                    } else if (error.response.data != null && error.response.data != undefined && error.response.data.data != null && error.response.data.data != undefined) {
                        responseDialogMessage += "\nerror: " + error.response.data.data;
                    } else {
                        responseDialogMessage += "\nerror: 网络链接失败。";
                    }
                } else {
                    responseDialogMessage += "\nerror: 未知错误，请稍后再试。";
                }
                alert(responseDialogMessage);
                return false;
        });
        },
        fetchMessages() {
            // setItem("lastTime", new Date().getTime())
            const threeDaysAgo = new Date();
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

            Promise.all([getFailedActions(), getIncompleteActions()])
                .then(([failedResponse, incompleteResponse]) => {
                    const failedActions = failedResponse.actions || [];
                    const incompleteActions = incompleteResponse.actions || [];

                    this.failedCount = failedActions.filter(action => new Date(action.updated_at) >= threeDaysAgo && action.job_id != null && action.job_id != undefined ).length;
                    this.incompleteCount = incompleteActions.filter(action => new Date(action.updated_at) >= threeDaysAgo && action.job_id != null && action.job_id != undefined ).length;
                    const provisionFailedActions = failedActions.filter(action => action.command === 'provision' && new Date(action.updated_at) >= threeDaysAgo);
                    const latestProvisionFailedActions = provisionFailedActions.reduce((acc, action) => {
                        if (!acc[action.sn] || new Date(acc[action.sn].updated_at) < new Date(action.updated_at)) {
                            acc[action.sn] = action;
                        }
                        return acc;
                    }, {});

                    this.provisionFailedSNs = Object.values(latestProvisionFailedActions).map(action => action.sn);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // navigate(menu, path) {
        //     this.setActiveMenu(menu);
        //     this.$router.push(path);
        //     setItem("lastTime", new Date().getTime())
        // },
        setActiveMenu(menu) {
            localStorage.setItem('activeMenu', menu);
        },
        handleLockMachineClick() {
            if (this.usename !== 'admin') {
                this.setActiveMenu('设备');
                this.$router.push({ path: '/Endpoint', query: { filter: 'lockMachine' } });
                setItem("lastTime", new Date().getTime())
            }
        },
        handleLeaseModeClick() {
            if (this.usename !== 'admin') {
                this.setActiveMenu('设备');
                this.$router.push({ path: '/Endpoint', query: { filter: 'leaseMode' } });
                setItem("lastTime", new Date().getTime())
            }
        },
        handleMessageClick(sn) {
            this.setActiveMenu('设备');
            this.$store.commit('setTabName', "操作记录")
            this.$store.commit('setIsOverview', false)
            setItem('sn', sn)
            this.$router.push({ path: '/Endpoint?sn=' + sn, query: { filter: 'provision_failed' } })
            setItem("lastTime", new Date().getTime())
        },
        openPdf() {
            //const pdfUrl = require('../../../docs/APM Server 操作指南.pdf?');
            //window.open(pdfUrl + '#toolbar=0', '_blank');
            window.open('/UserGuide.txt', '_blank', 'charset=UTF-8');
            setItem("lastTime", new Date().getTime())
        },
        openVideo() {
            window.open('/UserVideo.txt', '_blank', 'charset=UTF-8');
            setItem("lastTime", new Date().getTime())
        },
        redirectToHistory(filter_name) {
            this.setActiveMenu('操作历史')
            this.$router.push({ path: '/History', query: { filter: filter_name } }).catch(() => { });
            setItem("lastTime", new Date().getTime())
        }
    },
    created() {
        this.InitialData();
        this.fetchMessages();
    }
}
</script>

<style scoped>
.left-area {
    width: max-content;
}
.top-area {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}
.left-top-area {
    display: flex;
    flex-direction: column;
    height: 100px;
    margin-top: 30px;
    margin-left: 20px;
}

.left-top-area span:nth-child(1) {
    font-size: 1.5rem;
    color: #0054ae;
    font-weight: 700;
}

.left-top-area span:nth-child(2) {
    margin-left: 15px;
}
.notification-area {
    padding: 0.8em;
    margin: 1rem;
    margin-top: 15px;
    margin-left: 3%;
    width: 80%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.notification-title {
    font-weight: bold;
    margin-bottom: 1em;
    color: #333;
}

.notification-content {
    max-height: 40px;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px;
}

.notification-item {
    margin-bottom: 1em;
    color: #666;
}

.notification-link {
    text-decoration: none;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    margin-right: 10px;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.notification-link.failed {
    color: #e74c3c;
}

.notification-link.failed:hover {
    text-decoration: underline;
}

.notification-link.incomplete {
    color: #f39c12;
}

.notification-link.incomplete:hover {
    text-decoration: underline;
}

.notification-link.provision {
    color: #3498db;
}

.notification-link.provision a {
    color: #3498db;
    text-decoration: none;
}

.notification-link.provision a:hover {
    color: #2980b9;
    text-decoration: underline;
}

.notification-item span {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #149272;
}

.notification-content::-webkit-scrollbar {
    width: 8px;
}

.notification-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.right-area {
    width: 180px;
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 80px;
}

.right-area a {
    color: #0054ae;
    text-decoration: none;
    display: block;
    margin: 20px;
    font-weight: 600;
    font-size: 1.2rem;
}

.right-area-title {
    font-size: 1.5rem;
    color: #0054ae;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    display: block;
}

.right-area-content {
    font-size: 1.2rem;
    margin: 20px;
    color: #0054ae;
    font-weight: 700;
}

.right-area-content span:hover:not(.active){
    font-size: 1.3rem;
    color: #00285a;
}

.right-area a:hover:not(.active) {
    color: #00285a;
    font-size: 1.3rem;
}

.left-bottom-area {
    display: flex;
    margin-left: 20px;
}

.graph-area {
    display: flex;
    justify-content: space-between;
}

.stat-area {
    width: 500px;
}

.stat-data {
    margin: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.stat-data span:nth-child(1) {
    width: 150px;
    margin-right: 50px;
    font-size: 1.3rem;
    color: #252525f9;
}

.stat-data span:nth-child(2) {
    font-size: 2.5rem;
    color: #0054ae;
}

.stat-data .highlight {
    text-decoration: none;
    width: 150px;
    margin-right: 50px;
    font-size: 1.3rem;
    color: #252525f9;
}

.stat-data .highlight.not-admin-hover:hover {
    color: #005dae;
    text-decoration: underline;
    cursor: pointer;
}

.stat-data router-link, .stat-data span {
    width: 150px;
    display: inline-block;
}

.pie-chart {
    width: 350px;
    height: 400px;
    padding-top: 20px;
}

img {
    width: 100%;
    height: 350px;
}

.click_able {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1800px) {
    img {
        width: 100%;
        height: 250px;
    }

    .pie-chart {
        width: 265px;
        height: 300px;
        padding-top: 20px;
    }

    .stat-area {
        width: 360px;
    }

    .stat-data span:nth-child(1) {

        margin-right: 10px;
        font-size: 1rem;
        color: #252525f9;
    }

    .stat-data span:nth-child(2) {
        font-size: 1.8rem;
        color: #0054ae;
    }
}
</style>