export function setItem(key, value) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
}

export function getItem(key, defaultValue) {
    let value = localStorage.getItem(key);
    try {
        value = JSON.parse(value);
    } catch (e) {
    }
    return value || defaultValue;
}

export function removeItem(key) {
    localStorage.removeItem(key);
}

export function clear() {
    localStorage.clear();
}

export function removeItems(keys) {
    keys.length && keys.map((key) => localStorage.removeItem(key));
}
