<template>
    <div class="loginPage">
        <Header name="Intel® Asset Protection Manageability Solution"></Header>
        <div class="loginPage-form">
            <form @submit.prevent="loginPage">
                <h2 class="login">登录</h2>
                <hr class="underline">
                <div class="form-group">
                    <label for="username">用户名:</label>
                    <input type="text" id="username" v-model="username" required>
                </div>
                <div class="form-group">
                    <label for="password">密码:</label>
                    <input type="password" id="password" v-model="password" @keydown.tab="refreshCaptcha" required>
                </div>
                <div class="form-group">
                    <label for="captcha">验证码:</label>
                    <input type="text" id="captcha" v-model="captcha" placeholder="请输入验证码" @click="refreshCaptcha" required>
                </div>
                <div v-html="captchaSrc" @click="refreshCaptcha"></div>
                <button type="submit">登录</button>
            </form>
        </div>
        <i-footer></i-footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '../components/Footer.vue'
import { getCaptcha, checkLogin } from '../apis/User'
import { setItem } from "../utils/storage.js";

export default {
    components: {
        Header,
        "i-footer": Footer,
    },
    data() {
        return {
            username: '',
            password: '',
            captcha: '',
            token: '',
            captchaSrc: ''
        };
    },
    methods: {
        loginPage() {
            var user = JSON.stringify({
                "user_id": this.username,
                "password": this.password,
                "captcha": this.captcha,
                "token": this.token
            })
            checkLogin(user).then(res => {
                if (res.message === "Request successful") {
                    setItem('userName', this.username);
                    setItem('accessToken', res["result"]["accessToken"]);
                    setItem('refreshToken', res["result"]["refreshToken"]);
                    setItem("lastTime", new Date().getTime());
                    this.$store.commit('setIsOverview', true)
                    setItem('activeMenu', '主页');
                    this.$router.push('/Overview');
                    setItem('tabCount', 0);
                    localStorage.removeItem('logoutEvent');

                    if (res["result"]["defaultPasswordUsed"]) {
                        setItem('showChangePassword', 'true');
                    } else {
                        localStorage.removeItem('showChangePassword');
                    }
                }
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 404) {
                        alert('用户未找到。');
                    } else if (error.response.status === 500) {
                        alert('服务器错误，请联系管理员。');
                    } else if (error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined) {
                        alert('用户名、密码或验证码输入错误。\n' + "Error: " + error.response.data.message);
                    } else {
                        alert('网络连接问题，无法登录。');
                    }
            });
        },
        refreshCaptcha() {
            getCaptcha().then(res => {
                this.token = res.token;
                this.captchaSrc = res.captcha.replace('\"', '"');
            }).catch(error => {
                if(error.response.status === 500) {
                    alert('服务器错误，请联系管理员。');
                } else {
                    alert('网络连接问题，无法刷新验证码。');
                }
            });
        }
    }
};
</script>

<style scoped>
.login {
    color: #0c4b8f;
}

.loginPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f1f1f1;
}

.Header {
    flex: 1;
}

.loginPage-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginPage form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}

.form-group {
    margin-bottom: 20px;
}

.loginPage label {
    display: block;
    margin-bottom: 8px;
}

.loginPage input {
    width: calc(100% - 20px);
    padding: 8px;
    margin-bottom: 10px;
}

.loginPage button {
    width: 20%;
    padding: 8px 15px;
    background-color: #0054ae;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    float: right;
}

.loginPage button:hover {
    background-color: #0c4b8f;
}

.underline {
    border: none;
    border-top: 2px solid #ccc;
    width: 100%;
    margin-bottom: 30px;
    margin-top: -10px;
}
</style>