<template>
  <div>
    <UploadExcel name="UploadExcel" @handleUploadEndpoints="handleUploadEndpoints"></UploadExcel>
  </div>
</template>

<script>
import UploadExcel from '../components/UploadExcel.vue'
import { uploadEndpoints } from '../apis/Machine/index.js'
import { getItem, setItem } from "../utils/storage.js";

export default {
  name: 'Upload',
  components: {
    UploadExcel
  },
  methods: {
    handleUploadEndpoints(endpoints) {
      this.$emit('upload-start');
      let tmp = getCurrentDateTime();
      const batchSize = 1000;
      let totalEndpoints = endpoints.length;
      let successCount = 0;
      let failCount = 0;
      let failStats = {};
      let errorMessages = [];
      let hasErrorOccurred = false;

      const uploadBatch = (batch) => {
        return uploadEndpoints(JSON.stringify(batch)).then(res => {
          if (res.status === "success") {
            successCount += Object.keys(res.success_stats).length;
            failCount += Object.keys(res.fail_stats).length;
            Object.assign(failStats, res.fail_stats);
          } else if (res.status === "fail") {
            errorMessages.push(res.data);
            failCount += batch.length;
          }
        }).catch(error => {
          hasErrorOccurred = true;
          failCount += totalEndpoints - successCount;
          if (error.response && error.response.status === 500) {
            errorMessages.push('服务器错误，无法上传数据，请联系管理员。');
            tmp += ' 服务器错误，无法上传数据，请联系管理员。\n';
            alert('服务器错误，请联系管理员。');
          } else {
            errorMessages.push('网络链接超时');
            tmp += ' 网络链接超时\n';
            alert('网络连接问题，无法上传数据。');
          }
        });
      };

      const processBatches = (index = 0) => {
        if (index >= totalEndpoints || hasErrorOccurred) {
          tmp += `   共上传${totalEndpoints}条数据。`;
          tmp += `其中${successCount}条成功，${failCount}条失败。`;
          if (failCount !== 0) {
            tmp += '\n具体失败原因：\n';
            for (let key in failStats) {
              tmp += ` ${key}: ${failStats[key]} \n`;
            }
          }
          if (errorMessages.length > 0) {
            errorMessages.forEach(message => {
              tmp += `${message}\n`;
            });
          }

          this.$emit('upload-end');
          tmp += '\n';
          this.createdLog(tmp);
          location.reload();
          return;
        }

        let batch = endpoints.slice(index, index + batchSize);
        uploadBatch(batch).then(() => {
            if (!hasErrorOccurred) {
              processBatches(index + batchSize);
            }
          }).catch(() => {
            hasErrorOccurred = true;
            processBatches(totalEndpoints);
          });
        };

      processBatches();
    },
    createdLog(message) {
      let tmp = getItem('logs');
      var logs = tmp ? tmp : "";
      logs = logs.length > 8000 ? message + logs.slice(0, 8000) + '\n' : message + logs + '\n';
      setItem('logs', logs);
    }
  }
}

function getCurrentDateTime() {
  var currentTime = new Date();
  var d = currentTime.toString().slice(0, 31);
  return d
}
</script>