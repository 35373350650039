<template>
    <select :value="value" @change="handleSelect">
        <option v-for="option in options" :key="option.id" :value="option.value">{{ option.label }}</option>
    </select>
</template>
<script>
export default {
    name: 'i-select',
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            type: String
        },
    },
    mounted() {
        this.options.forEach((option, index) => {
            option.id = index
            if (this.value && this.value === option.value) option.selected = true
            else option.selected = false
        })
    },
    methods: {
        handleSelect(e) {
            this.$emit("input", e.target.value)
        }
    }
}
</script>
<style scoped>
select {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}
</style>