import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Endpoint from '../views/Endpoint.vue'
import EndpointGroup from '../views/EndpointGroup.vue'
import Overview from '../views/Overview.vue'
import Upload from '../views/Upload.vue'
import Login from '../views/Login.vue'
import History from '../views/History.vue'
import Users from '../views/User.vue'
import Download from '../views/Download.vue'
import Settings from '../views/Settings.vue'
import { getItem, setItem, removeItem } from "../utils/storage.js";

Vue.use(VueRouter)

const NAMEURL = {
  'Overview': '主页',
  'EndpointGroup': '设备组',
  'Endpoint': '设备',
  'User': '用户',
  'Upload': '数据同步',
  'History': '操作历史',
  'Settings': '设置'
};

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      { path: '/Overview', name: 'Overview', component: Overview, meta: { requireAuth: true } },
      { path: '/Upload', name: 'Upload', component: Upload, meta: { requireAuth: true } },
      { path: '/Endpoint', name: 'Endpoint', component: Endpoint, meta: { requireAuth: true }, props: route => ({ filter: route.query.filter }) },
      { path: '/EndpointGroup', name: 'EndpointGroup', component: EndpointGroup, meta: { requireAuth: true } },
      { path: '/History', name: 'History', component: History, meta: { requireAuth: true }, props: route => ({ filter: route.query.filter }) },
      { path: '/User', name: 'User', component: Users },
      { path: '/Settings', name: 'Settings', component: Settings }
    ]
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: { requireAuth: false }
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// let lastHistoryState = window.history.state;

router.beforeEach((to, from, next) => {
  if (to.path.toLocaleLowerCase() === '/download') {
    next();
  } else if (to.path.toLocaleLowerCase() !== '/login' && !isUserLoggedIn()) {
    next('/login');
  } else {
    if (isUserLoggedIn()) {
      // const currentHistoryState = window.history.state;
      // if (currentHistoryState && lastHistoryState && currentHistoryState.key !== lastHistoryState.key) {
      //   const activeMenu = NAMEURL[to.name];
      //   if (activeMenu) {
      //     setItem('activeMenu', activeMenu);
      //   }
      // }
      if (getItem('userName') == 'admin' && (to.path.toLocaleLowerCase() == '/endpoint' || to.path.toLocaleLowerCase() == '/endpointgroup' || to.path.toLocaleLowerCase() == '/history' || to.path.toLocaleLowerCase() == '/upload')) {
        next('/Overview');
      } else {
        const activeMenu = getItem('activeMenu');
        const routeName = Object.keys(NAMEURL).find(key => NAMEURL[key] === activeMenu);
        if (routeName && to.path.toLocaleLowerCase() !== '/' + routeName.toLocaleLowerCase()) {
          next('/' + routeName);
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

// router.afterEach((to, from) => {
  // console.log('Navigation completed from:', from.fullPath, 'to:', to.fullPath);
//   const activeMenu = NAMEURL[to.name];
//   if (activeMenu) {
//     setItem('activeMenu', activeMenu);
//   }
//   lastHistoryState = window.history.state;
// });

function isUserLoggedIn() {
  //This should be a logic to check if the user is logged in, such as checking local storage or cookies
  //Return true or false
  return (getItem('userName') != null);
}

export default router
