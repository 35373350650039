<template>
    <div class="text-div">
        <i-text-div :label="label">{{ label }}</i-text-div>
        <i-text-div :label="value">{{ value }}</i-text-div>
    </div>
</template>
<script>
import TextDiv from './TextDiv.vue';
export default {
    components: {
        "i-text-div": TextDiv,
    },
    name: 'i-text',
    props: {
        label: {
            type: String
        },
        value: {
            type: String | Number | Boolean
        },
    }
}
</script>
<style scoped>
.text-div {
    display: grid;
    grid-template-columns: 15% 30%;
    margin-top: 20px;
}
</style>