<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import { getItem, setItem } from "../utils/storage.js";

export default {
  name: 'i-tab-item',
  props: {
    name: { required: true },
    selected: { default: false }
  },
  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    if (this.name === this.$store.state.tabName)
      this.isActive = 'true';
    else
      this.isActive = this.selected;
  },
  watch: {
    '$store.state.tabName'(newVal) {
      this.isActive = this.name === newVal;
    }
  }
};
</script>
