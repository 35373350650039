<template>
    <div class="userlist">
        <i-tab>
            <i-tab-item name="用户" selected="true">
                <span>管理个人用户</span>
                <td class="cell-align" style="padding-top: 10px;"></td>
                <div class="create-search">
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="filterStr"
                            placeholder="输入搜索关键字" @handleEnter="handleFilterByStr">
                        </i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size" style="cursor: pointer;"
                            @click="handleFilterByStr">
                    </div>
                    <div v-if="login_user_type == 'admin'">
                        <button class="dropbtn" @click="clickCreateUser">创建用户</button>
                    </div>
                </div>
                <div style="flex: 1">
                    <table class="custom-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="background-color : #0c2744">用户名</th>
                                <th style="background-color : #0c2744">描述</th>
                                <th style="background-color : #0c2744">权限</th>
                                <th style="background-color : #0c2744">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in displayedData" :key="index"
                                :class="{ 'row-shadow': index % 2 !== 0 }">
                                <template v-if="login_user_type != 'admin' && user.user_id == 'admin'">

                                </template>
                                <template v-else>
                                    <td>{{ user.user_id }}</td>
                                    <td>{{ user.description }}</td>
                                    <td>{{ user.group }}</td>
                                    <td>
                                        <div class="dropdown">
                                            <div v-if="user.user_id == userName || userName == 'admin'">
                                                <button class="dropbtn">请选择操作</button>
                                                <div class="dropdown-content">
                                                    <div v-if="user.user_id !== 'admin' && userName == 'admin'">
                                                        <a href="#" @click.prevent="clickResetUserPasswd(user)">重置密码</a>
                                                    </div>
                                                    <a href="#" @click.prevent="clickEditUser(user)">编辑信息</a>
                                                    <div v-if="login_user_type == 'admin'">
                                                        <div v-if="user.user_id !== 'admin'">
                                                            <a href="#" @click.prevent="clickDeleteUser(user)">删除用户</a>
                                                        </div>
                                                    </div>
                                                    <div v-if="login_user_type === user.user_id">
                                                        <a href="#"
                                                            @click.prevent="clickChangeUserPasswd(user)">修改密码</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="action-search">
                    <div class="pagination">
                        <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                        <i-input v-model="currentPageNum"></i-input>
                        <span style="margin-left: 6px;">{{ ' / ' + totalPageNum }}</span>
                        <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                    </div>
                    <div class="page-size-container">
                        <label for="page-size-input">页面大小:</label>
                        <select v-model="pageSize">
                            <option v-for="option in pageSizeOptions" :key="option" :value="option">
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>
            </i-tab-item>
        </i-tab>

        <i-create-user-dialog :show="showCreateUserDialog" :title="'创建用户'" @cancel="handleCancelUserCreate"
            @confirm="handleConfirmUserCreate">
            <template slot="body">
                <i-create-user-form :fields="createUserFields" :form="createUserForms" @handleEnter="handleConfirmUserCreate">
                </i-create-user-form>
            </template>
        </i-create-user-dialog>

        <i-edit-user-dialog :show="showEditUserDialog" :title="'编辑信息'" @cancel="handleCancelUserEdit"
            @confirm="handleConfirmUserEdit">
            <template slot="body">
                <i-create-user-form :fields="editUserFields" :form="editUserForm" @handleEnter="handleConfirmUserEdit">
                </i-create-user-form>
            </template>
        </i-edit-user-dialog>

        <i-change-user-password-dialog :show="showChangeUserPasswdDialog" :title="'修改密码'"
            @cancel="handleCancelChangeUserPasswd" @confirm="handleConfirmChangeUserPasswd">
            <template slot="body">
                <i-create-user-form :fields="changeUserPasswdFields" :form="changeUserPasswdForm" @handleEnter="handleConfirmChangeUserPasswd">
                </i-create-user-form>
            </template>
        </i-change-user-password-dialog>

        <i-reset-user-password-dialog :show="showResetUserPasswdDialog" :title="'重置密码'"
            @cancel="handleCancelResetUserPasswd" @confirm="handleConfirmResetUserPasswd">
            <template slot="body">
                <i-create-user-form :fields="resetUserPasswdFields" :form="resetUserPasswdForm" @handleEnter="handleConfirmResetUserPasswd">
                </i-create-user-form>
            </template>
        </i-reset-user-password-dialog>

        <i-delete-user-dialog :show="showDeleteUserDialog" :title="'删除用户'" @cancel="handleCancelDeleteUserDialog"
            @confirm="handleConfirmDeleteUserDialog">
            <template slot="body">
                <div v-if="currentSelectedUser !== {}">
                    <span>是否确定删除用户 {{ currentSelectedUser.user_id }} ?</span>
                </div>
            </template>
        </i-delete-user-dialog>
    </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import CreateUserDialog from './CreateUserDialog.vue'
import DeleteUserDialog from './DeleteUserDialog.vue'
import ChangeUserPasswdDialog from './ChangeUserPasswdDialog.vue'
import ResetUserPasswdDialog from './ResetUserPasswdDialog.vue'
import CreateUserForm from './CreateUserForm.vue'
import EditUserDialog from './EditUserDialog.vue'
import Tab from '@/components/Tab.vue'
import TabItem from '@/components/TabItem.vue'
import { getItem, setItem } from "../utils/storage.js";

export default {
    components: {
        "i-input": Input,
        "i-button": Button,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-edit-user-dialog": EditUserDialog,
        "i-create-user-dialog": CreateUserDialog,
        "i-delete-user-dialog": DeleteUserDialog,
        "i-change-user-password-dialog": ChangeUserPasswdDialog,
        "i-reset-user-password-dialog": ResetUserPasswdDialog,
        "i-create-user-form": CreateUserForm
    },
    props: {
        table_fields: {
            type: Array,
            required: true
        },
        login_user_type: {
            type: String,
            default: "user"
        },
        data: {
            type: Array,
            required: true
        },
        create_user_fields: {
            type: Array,
            required: true
        },
        edit_user_fields: {
            type: Array,
            requried: true
        },
        change_user_password_fields: {
            type: Array,
            required: true
        },
        reset_user_password_fields: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentPageNum: 1,
            pageSize: getItem('userPageSize') || 10,
            pageSizeOptions: [10, 50, 100],
            currentSelectedUser: {},
            filterStr: '',
            showCreateUserDialog: false,
            showEditUserDialog: false,
            showChangeUserPasswdDialog: false,
            showResetUserPasswdDialog: false,
            showDeleteUserDialog: false,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            createUserFields: prepareFields(this.create_user_fields),
            editUserFields: prepareFields(this.edit_user_fields),
            changeUserPasswdFields: prepareFields(this.change_user_password_fields),
            resetUserPasswdFields: prepareFields(this.reset_user_password_fields),
            createUserForms: {},
            editUserForm: {},
            changeUserPasswdForm: {},
            resetUserPasswdForm: {},
            userName: getItem('userName')
        }
    },
    computed: {
        totalPageNum: function () {
            let len = Math.ceil(this.data.length / this.pageSize)
            if (len === 0) len = 1
            if (len < this.currentPageNum) {
                this.currentPageNum = len
            }
            return len
        },
        displayedData() {
            this.data.sort(function(a, b) {
                var user_idA = a.user_id.toUpperCase();
                var user_idB = b.user_id.toUpperCase();
                if (user_idA < user_idB)
                    return -1;
                if (user_idA > user_idB)
                    return 1;
                return 0;
            });
            return this.data.slice((this.currentPageNum - 1) * this.pageSize, this.currentPageNum * this.pageSize);
        }
    },
    watch: {
        pageSize(newPageSize) {
            localStorage.setItem('userPageSize', newPageSize);
            setItem("lastTime", new Date().getTime())
        },
    },
    methods: {
        /* page */
        pageDown() {
            if (this.currentPageNum !== 1) this.currentPageNum--;
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if (this.currentPageNum !== this.totalPageNum) this.currentPageNum++;
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPageNum = event.target.value;
            setItem("lastTime", new Date().getTime())
        },
        // Create user
        clickCreateUser(index) {
            this.showCreateUserDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelUserCreate() {
            this.showCreateUserDialog = false;
            this.createUserForms = {};
            this.currentSelectedUser = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmUserCreate() {
            let data = this.createUserForms;
            // Response dialog
            this.$emit('handleCreateUser', data);
            // Reset the variables
            this.showCreateUserDialog = false;
            this.currentSelectedUser = {};
            this.createUserForms = {};
            setItem("lastTime", new Date().getTime())
        },
        // Edit user
        clickEditUser(user) {
            this.showEditUserDialog = true;
            this.currentSelectedUser = user;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelUserEdit() {
            this.showEditUserDialog = false;
            this.currentSelectedUser = {};
            this.editUserForm = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmUserEdit() {
            let data = this.editUserForm;
            this.$emit('handleEditUser', data, this.currentSelectedUser);
            this.showEditUserDialog = false;
            this.currentSelectedUser = {};
            this.editUserForm = {};
            setItem("lastTime", new Date().getTime())
        },
        // Change user password
        clickChangeUserPasswd(user) {
            this.showChangeUserPasswdDialog = true;
            this.currentSelectedUser = user;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelChangeUserPasswd() {
            this.showChangeUserPasswdDialog = false;
            this.changeUserPasswdForm = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmChangeUserPasswd() {
            // Check if the fields are empty.
            let data = this.changeUserPasswdForm;
            this.$emit('handleChangeUserPasswd', data);
            this.currentSelectedUser = {};
            this.changeUserPasswdForm = {};
            this.showChangeUserPasswdDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        // Reset user password
        clickResetUserPasswd(user) {
            this.showResetUserPasswdDialog = true;
            this.currentSelectedUser = user;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelResetUserPasswd() {
            this.showResetUserPasswdDialog = false;
            this.currentSelectedUser = {};
            this.resetUserPasswdForm = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmResetUserPasswd() {
            let data = this.resetUserPasswdForm;

            this.$emit('handleResetUserPasswd', data, this.currentSelectedUser);
            this.currentSelectedUser = {};
            this.resetUserPasswdForm = {};
            this.showResetUserPasswdDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        // Delete user
        clickDeleteUser(user) {
            this.showDeleteUserDialog = true;
            this.currentSelectedUser = user;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelDeleteUserDialog() {
            this.showDeleteUserDialog = false;
            this.currentSelectedUser = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmDeleteUserDialog() {
            let e = this.currentSelectedUser;
            var user = JSON.stringify({ "user_id": e.user_id });
            this.$emit('handleDeleteUser', user);
            this.currentSelectedUser = {};
            this.showDeleteUserDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        handleFilterByStr() {
            this.$emit('handleFilterByStr', this.filterStr)
            setItem("lastTime", new Date().getTime())
        }
    }
}

function prepareFields(fields) {
    // Some items aren't allowed displayinlg
    let filteredFields = fields.filter(field => field.isshow)
    // Transfor the item type
    return filteredFields.map(field => {
        let item = { label_name: field.table_name, label: field.name, type: field.type }
        if (field.required) item.required = true;
        switch (field.type) {
            case 'password':
                item.type = 'password';
                break;
            case 'string':
                item.type = 'text';
                break;
            case 'bool':
                item.type = 'checkbox';
                break;
            case 'enum':
                item.type = 'select';
            // item.options = field.options;
            default:
        }
        return item;
    })
}
</script>

<style>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}

.page-size-container {
    display: flex;
    align-items: center;
}

.page-size-container label {
    margin-right: 10px;
}

.page-size-container select {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.row-shadow {
    background-color: #f0f0f0;
}

/* Search */
.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.search-input {
    width: 180px !important;
    height: 40px !important;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 0px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

/* Dropdown Button */
.dropbtn {
    background-color: #004a86;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
}

/* needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #004a86;
}
</style>