<template>
    <input type="checkbox" :checked="value" :disabled="disabled" @input="handleInput" />
</template>
<script>

import { setItem } from '../utils/storage.js'

export default {
    name: 'i-checkbox',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: 0,
        }
    },
    mounted() {
    },
    methods: {
        handleInput(event) {
            this.$emit("handleUpdate", event.target.checked, this.index)
            //setItem("lastTime", new Date().getTime())
        }
    }
}
</script>
<style scoped>
input {
    width: 16px;
    height: 16px;
    background-color: #fff;
    line-height: 16px;
    text-align: center;
    /* font-size: 1rem; */
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    box-sizing: content-box;
    appearance: none;
    color: #fff;
    text-align: center;
    margin: 0;
    position: relative;
}

input[type="checkbox"]:checked {
    background-color: #0054ae;
}

input[type="checkbox"]:after {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8Zz4KICA8bGluZSBzdHJva2U9IiNmZmYiIGlkPSJzdmdfNiIgeTI9IjEyLjY4ODYiIHgyPSI3LjQzMzkyIiB5MT0iMTAuMTQwODQiIHgxPSIzLjYxMjI3IiBzdHJva2Utd2lkdGg9IjIiLz4KICA8bGluZSBzdHJva2U9IiNmZmYiIGlkPSJzdmdfOCIgeTI9IjEzLjY0NDAyIiB4Mj0iNy4xMTU0NSIgeTE9IjUuMjA0NTQiIHgxPSIxMi4yMTA5OCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiA8L2c+Cjwvc3ZnPg==');
    position: absolute;
    top: -1px;
    left: 0;
}
</style>