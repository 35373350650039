<template>
    <ul>
        <li v-for="link in linkList" :key="link.uid" :class="{ active: linkname === link.name }">
            <a :href="link.url" style="display: flex; align-items: center" @click="changeActive(link.name)">
                <span class="text">{{ link.name }}</span>
            </a>
            <!-- <router-link :to="link.url" style="display: flex; align-items: center" @click.native="changeActive(link.name)">
                <span class="text">{{ link.name }}</span>
            </router-link> -->
        </li>
    </ul>
</template>

<script>
import { getItem, setItem } from "../utils/storage.js";
const NAMEURL = {
    'Overview': '主页',
    'EndpointGroup': '设备组',
    'Endpoint': '设备',
    'User': '用户',
    'Upload': '数据同步',
    'History': '操作历史',
    'Settings': '设置'
}
export default {
    name: 'nav-menu',
    props: {
        linkList: {
            type: Array,
            required: true,
        }
    },
    methods: {
        changeActive(name) {
            this.$store.commit('setIsOverview', true)
            this.$store.commit('setTabName', "")
            this.$emit('changeActiveMenu', name);
            if (name === getItem("activeMenu"))
                location.reload();
            else {
                setItem('activeMenu', name);
                this.linkname = name;
            }
            // if (name === getItem("activeMenu")) {
            //     this.$router.push(this.linkList.find(link => link.name === name).url);
            //     location.reload();
            // } else {
            //     setItem('activeMenu', name);
            //     this.linkname = name;
            //     this.$router.push(this.linkList.find(link => link.name === name).url);
            // }
        }
    },
    computed:{
        linkname: function () {
            return NAMEURL[this.$route.path.split("/").slice(-1)[0]]
        },
    },
    mounted() {
        this.linkList.forEach((link, index) => {
            link.uid = index
        })
    }
}
</script>

<style scoped>
.active {
    background-color: #00a3f6;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 135px;
    height: calc(100vh - 100px);
    border-right: 1px solid #c5c5c5;
    background-color: #004a86;
}

li {
    text-align: left;
    height: 50px;
    line-height: 1.785714285;
    /*border-bottom: 1px solid #f6f6f6;
    margin: 0.3rem;*/
    font-size: 1.1rem;
    box-sizing: border-box;
}

li a {
    display: block;
    padding: 0.6rem;
    color: #ffffff;
    text-decoration: none;
    border-radius: spx;
}

li:hover:not(.active) {
    background-color: #00a3f6;
    /*border-radius: 5px;*/
    color: white;
}

li a.active {
    background-color: #4CAF50;
    color: white;
}

.arrow {
    margin-left: auto;
    line-height: 0;
    fill: #fff;
    width: .285714286em;
    height: .571428571em;
    min-width: .285714286em;
    min-height: .571428571em;
}
</style>