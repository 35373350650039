<template>
    <div class="page">
        <i-tab>
            <i-tab-item name="同步数据" selected="true">
                <div class="create-search">
                    <span>同步数据前，请确保"自定义"栏没有除了英文和数字之外的特殊字符。</span>
                </div>

            </i-tab-item>

        </i-tab>
        <i-button label="上传Excel表格" @click="clickBatchCreate" class="buttonstyle"></i-button>

        <template>
            <div style="padding-left: 10px; padding-top: 10px;">
                <i-log-box ref="logViewer" :content="logContent"></i-log-box>
            </div>
        </template>
        <i-batch-create-enpoint-dialog :show="showBatchCreateDialog" :title="'批量创建多个机器'"
            @cancel="handleCancelBatchCreate" @confirm="handleConfirmBatchCreate" @loadExcel="loadExcel">
        </i-batch-create-enpoint-dialog>
    </div>
</template>

<script>
import Button from './Button.vue'
import BatchCreateEndpointDialog from './BatchCreateEndpointDialog.vue'
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'
import LogBox from './LogBox.vue';
import { getItem, setItem } from "../utils/storage.js";

export default {
    components: {
        'i-button': Button,
        "i-batch-create-enpoint-dialog": BatchCreateEndpointDialog,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-log-box": LogBox,
    },
    data() {
        return {
            showBatchCreateDialog: false,
            batchCreatEndpointsData: [],
            headers: [],
            logContent: ""
        }
    },
    created(){
        this.InitialData()
    },
    methods: {
        InitialData() {
            this.logContent = ((getItem('logs') == null) || (getItem('logs') == undefined)) ? "":getItem('logs');
            setItem("lastTime", new Date().getTime())
        },
        clickBatchCreate() {
            this.showBatchCreateDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelBatchCreate() {
            this.showBatchCreateDialog = false
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmBatchCreate(){
            let endpoints = []
            this.batchCreatEndpointsData.forEach(each => {
                let metadata =
                {
                    "custom_EndpointGroup":
                    {
                        "name": "设备组",
                        "data": each["设备组"] ? each["设备组"] : ""
                    },
                    'custom_1':
                    {
                        "name": ((this.headers[4] == null) || (this.headers[4] == undefined)) ? "" : this.headers[4],
                        "data": ((each[this.headers[4]] == null) || (each[this.headers[4]] == undefined)) ? "" : each[this.headers[4]]
                    },
                    'custom_2':
                    {
                        "name": ((this.headers[5] == null) || (this.headers[5] == undefined)) ? "" : this.headers[5],
                        "data": ((each[this.headers[5]] == null) || (each[this.headers[5]] == undefined)) ? "" : each[this.headers[5]]
                    }
                };
                let e = {
                    "user_id": getItem("userName"),
                    "sn": each["sn"],
                    "metadata": metadata,
                    "starting_time": each["租期开始时间"],
                    "ending_time": each["租期结束时间"]
                }
                endpoints.push(e)
            })
            this.showBatchCreateDialog = false
            setItem("lastTime", new Date().getTime())
            this.$emit('handleUploadEndpoints', endpoints)
        },
        loadExcel(d, h) {
            this.batchCreatEndpointsData = d;
            this.headers = h;
            setItem("lastTime", new Date().getTime())
        }
    }
}


</script>

<style scoped>
ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.pagination {
    display: flex;
    align-items: center;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.search-input {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.filter {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filter-header span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0054ae;
}

.filter-header span:nth-child(2) {
    color: #00285a;
    font-size: 0.8rem;
}

.filter-header span:nth-child(2):hover {
    cursor: pointer;
}

.filter-area {
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 5px;
}

.filter-item span {
    margin-left: 10px;
    font-size: 0.8rem;
}

.buttonstyle {
    text-align: center;
    border: 1px solid #c4c6ca;
    background-color: #004a86;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 14px;
    margin: 0 10px;
    vertical-align: middle;
}
</style>
