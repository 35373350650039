<template>
    <div class="text-div-label">
        {{ label }}
    </div>
</template>
<script>
export default {
    name: 'i-text-div',
    props: {
        label: {
            type: String | Number | Boolean
        },
    }
}
</script>
<style scoped>
.text-div-label {
    float: right;
    position: relative;
    padding: 0 10px;
    margin: 10px 10px;
}
</style>