<template>
    <div @keydown.enter.prevent="handleEnter">
        <i-form-div v-for="field in fields" :key="field.id" :label="field.label_name" :required="field.required">
            <template slot="body">
                <i-input v-if="field.type === 'text'" v-model="form[field.label]" style="width: 200%;"></i-input>
                <i-checkbox v-if="field.type === 'checkbox'" v-model="form[field.label]"
                    @handleUpdate="handleUpdate"></i-checkbox>
                <i-select v-if="field.type === 'select'" :options="field.options"
                    v-model="form[field.label]"></i-select>
            </template>
        </i-form-div>
    </div>
</template>

<script>
import FormDiv from './FormDiv.vue'
import CheckBox from './CheckBox.vue'
import Input from './Input.vue'
import Select from './Select.vue'

export default {
    name: 'i-create-endpoint-form',
    components: {
        "i-form-div": FormDiv,
        "i-checkbox": CheckBox,
        "i-input": Input,
        "i-select": Select
    },
    props: {
        fields: {
            type: Array,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleUpdate(e) {
            this.$emit("isLeasingModeOn", e)
        },
        handleEnter() {
            this.$emit("handleEnter")
        }
    },
    mounted() {
        this.fields.forEach((field, index) => {
            field.id = index;
            if (!(field.label in this.form)) {
                this.$set(this.form, field.label, field.type === 'checkbox' ? false : '');
            }
        });
    }
}
</script>

<style scoped></style>