<template>
    <div class="custom-select" ref="select" @click="toggleOptions">
      <div class="selected-option">{{ selectedOption }}</div>
      <div v-show="isExpanded" class="options">
        <div v-for="option in options" :key="option" class="option" @click="selectOption(option)">
          {{ option.label }}
        </div>
      </div>
      <div class="arrow"></div>
    </div>
  </template>
  
  <style>
  .custom-select {
    position: relative;
    cursor: pointer;
  }
  
  .selected-option {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
    cursor: pointer;
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    color: #606266;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }

  .option {
    padding: 2px 10px 2px 10px;
    border-radius: 6px;
  }

  .option:hover {
    background-color: #606266;
    color: white
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
  }
  </style>
  
  <script>
  export default {
    data() {
      return {
        isExpanded: false,
        pageSize: 10
      }
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            type: [String, Number]
        },
        selectedOption: {
            type: [String, Number]
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
      toggleOptions() {
        this.isExpanded = !this.isExpanded;
      },
      selectOption(option) {
        this.$emit('actions', option.value)
        
        setTimeout(() => {
            this.isExpanded = false;
            this.selectedOption = option.label;
        }, 100);
      },
      handleClickOutside(event) {
        if (!this.$refs.select.contains(event.target)) {
            this.isExpanded = false;
        }
        }
    }
  }
  </script>
  