<template>
    <div class="page">
        <i-tab>
            <i-tab-item name="机器管理" selected="true">
                <div class="create-search">
                    <span>这些端点已安装“英特尔APM代理”，可以由“英特尔APMServer”进行管理。</span>
                    <!-- will provide more style of td -->
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="localFilterName" placeholder="输入搜索关键字"
                            @handleEnter="handleFilterByStr"></i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size" style="cursor: pointer;"
                            @click="handleFilterByStr">
                    </div>
                </div>
                <div style="display: flex; align-items: flex-start">
                    <div class="filter">
                        <div class="filter-header">
                            <span>过滤器</span>
                            <span @click="handleClearFilters">全选</span>
                        </div>

                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">库存或出租</div>
                            <div v-for="(leasing, index) in leasing_out_or_not_list" :key="leasing.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="leasing_out_or_not_list_checked[index]" :index="index"
                                        @handleUpdate="handleLeasingOutOrNotUpdate"></i-checkbox>
                                    <span>{{ leasing }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">安全配置</div>
                            <div v-for="(provision, index) in provision_list" :key="provision.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="provision_list_checked[index]" :index="index"
                                        @handleUpdate="handleProvisionUpdate"></i-checkbox>
                                    <span>{{ provision }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">租赁模式</div>
                            <div v-for="(leasing_mode, index) in leasing_mode_list" :key="leasing_mode.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="leasing_mode_list_checked[index]" :index="index"
                                        @handleUpdate="handleLeasingModeUpdate"></i-checkbox>
                                    <span>{{ leasing_mode }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">停止服务</div>
                            <div v-for="(lock, index) in lock_list" :key="lock.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="lock_list_checked[index]" :index="index"
                                        @handleUpdate="handleLockMachineUpdate"></i-checkbox>
                                    <span>{{ lock }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">联网状态</div>
                            <div v-for="(connect_status, index) in connect_status_list" :key="connect_status.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="connect_status_list_checked[index]" :index="index"
                                        @handleUpdate="handleConnectStatusUpdate"></i-checkbox>
                                    <span>{{ connect_status }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">剩余租期</div>
                            <div v-for="(rent, index) in rent_list" :key="rent.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="rent_list_checked[index]" :index="index"
                                        @handleUpdate="handleRentUpdate"></i-checkbox>
                                    <span>{{ rent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style="flex: 1">
                        <div class="action-search">
                            <div>APMServer共管理{{ total_endpoints_num }}台机器，目前过滤出{{ endpointsFilterNum }}台，当前选择{{ endpointSelectedNum }}台。</div>
                            <div>
                                <i-select-option :options="actionOption" :selectedOption="'请选择操作'" @actions="actions" style="width: 130px"></i-select-option>
                            </div>

                        </div>
                        <table cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" id="selectAll" v-model="isAllSelected">
                                        序号
                                    </th>
                                    <th v-for="field in tableFields" :key="field.fid">{{ field.table_name }}</th>
                                    <th>
                                        详情
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in tableData" :key="item.id">
                                    <td>
                                        <input type="checkbox" :value="item" v-model="EndpointSelected">
                                        <label :for="item.id">{{ item.num }}</label>
                                    </td>
                                    <td v-for="fieldName in tableFields.map(field => field.name)" :key="item.id + fieldName">
                                        <template v-if="fieldName === 'Name'">
                                            <a @click.prevent="showdetails(item.SN)" class="machine-link">{{ item[fieldName] }}</a>
                                        </template>
                                        <template v-else>
                                            {{ item[fieldName] }}
                                        </template>
                                    </td>
                                    <td>
                                        <i-button label="查看详情" @click="showdetails(item.SN)"
                                            :css-style="{ backgroundColor: '#359eff', height: '28px', padding: '5px', width: '80px' }"></i-button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div class="action-search">
                            <div class="pagination">
                                <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                                <i-input v-model="currentPageNum"></i-input>
                                <span style="margin-left: 6px;">{{ ' / ' + totalPageNum }}</span>
                                <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                            </div>
                            <div class="page-size-container">
                                <label for="page-size-input">页面大小:</label>
                                <i-select-option :options="pageSizeOptions" :selectedOption="pageSize" @actions="changePageSize" style="width: 65px"></i-select-option>
                            </div>
                        </div>
                    </div>

                </div>
            </i-tab-item>

        </i-tab>


        <i-edit-endpoint-dialog :show="showChangeLeasingModeDialog" :title="'是否要更新以下' + endpointSelectedNum +'台机器的租赁模式？'"
            @cancel="handleCancelChangeLeasingMode" @confirm="handleConfirmChangeLeasingMode">
            <template slot="body">
                <i-endpoint-table :headers="selectedEndpointTableHeaders" :data="sortedEndpointSelected" />
            </template>
            <template slot="body">
                <i-create-endpoint-form :fields="updateLeasingmodeFields" :form="editForm"
                    @isLeasingModeOn="isLeasingModeOn">
                </i-create-endpoint-form>
            </template>
        </i-edit-endpoint-dialog>

        <i-edit-endpoint-dialog :show="showChangeLeasingBufferPeriodDialog" :title="'是否要更新以下' + endpointSelectedNum +'台机器的租赁缓冲期？'"
            @cancel="handleCancelChangeLeasingBufferPeriod" @confirm="handleConfirmChangeLeasingBufferPeriod" :isConfirmShow="editForm.Leasing_Buffer != '' && editForm.Final_Leasing_Buffer != ''">
            <template slot="body">
                <div style="color: red;" v-if="editForm.Leasing_Buffer == '' || editForm.Final_Leasing_Buffer == ''">缓冲期为空，请输入缓冲期与最终缓冲期。</div>
            </template>
            <template slot="body">
                <i-endpoint-table :headers="selectedEndpointTableHeaders" :data="sortedEndpointSelected" />
            </template>
            <template slot="body">
                <i-create-endpoint-form :fields="updateLeasingBufferPeriodFields" :form="editForm" @handleEnter="handleConfirmChangeLeasingBufferPeriod">
                </i-create-endpoint-form>
            </template>
        </i-edit-endpoint-dialog>

        <i-delete-enpoint-dialog :show="showReturnDialog" :title="'是否对以下' + endpointSelectedNum +'台机器进行资产评估？'" @cancel="handleCancelReturn"
            @confirm="handleConfirmReturn">
            <template slot="body">
                <i-endpoint-table :headers="selectedEndpointTableHeaders" :data="sortedEndpointSelected" />
            </template>
        </i-delete-enpoint-dialog>

        <i-edit-endpoint-dialog :show="showSendMessageDialog" :title="'是否向以下' + endpointSelectedNum +'台机器发送信息？'" @cancel="handleCancelSendMessage"
            @confirm="handleConfirmSendMessage" style="width: 900px;" :isConfirmShow="editForm.Message != ''">
            <template slot="body">
                <div style="color: red;" v-if="editForm.Message == ''">待发送消息为空，请输入消息内容。</div>
            </template>
            <template slot="body">
                <i-endpoint-table :headers="selectedEndpointTableHeaders" :data="sortedEndpointSelected" />
            </template>
            <template slot="body">
                <i-message-form :fields="addSentMessageFields" :form="editForm" @handleEnter="handleConfirmSendMessage">
                </i-message-form>
            </template>
        </i-edit-endpoint-dialog>

        <i-edit-endpoint-dialog :show="showLockDialog" :title="'是否停止对以下' + endpointSelectedNum +'台机器的服务？'" @cancel="handleCancelLockMachines"
            @confirm="handleConfirmLockMachines" style="width: 900px;">
            <template slot="body">
                <i-endpoint-table :headers="selectedEndpointTableHeaders" :data="sortedEndpointSelected" />
            </template>
            <template slot="body">
                <i-message-form :fields="lockFields" :form="editForm">
                </i-message-form>
            </template>
        </i-edit-endpoint-dialog>

    </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import SelectOption from './SelectOption.vue'
import BatchCreateEndpointDialog from './BatchCreateEndpointDialog.vue'
import DeleteEndpointDialog from './DeleteEndpointDialog.vue'
import EditEndpointDialog from './EditEndpointDialog.vue'
import CreateEndpointForm from './CreateEndpointForm.vue'
import EndpointTable from './EndpointTable.vue'
import MessagForm from './MessageForm.vue'
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'
import CheckBox from './CheckBox.vue'
import { getItem, setItem } from "../utils/storage.js";

export default {
    components: {
        "i-input": Input,
        "i-checkbox": CheckBox,
        'i-button': Button,
        "i-edit-endpoint-dialog": EditEndpointDialog,
        "i-batch-create-enpoint-dialog": BatchCreateEndpointDialog,
        "i-delete-enpoint-dialog": DeleteEndpointDialog,
        "i-create-endpoint-form": CreateEndpointForm,
        "i-message-form": MessagForm,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-select-option": SelectOption,
        "i-endpoint-table": EndpointTable,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        table_fields: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        update_leasingmode_fields: {
            type: Array,
            required: true
        },
        update_leasingbufferperiod_fields: {
            type: Array,
            required: true
        },
        send_message_fields: {
            type: Array,
            required: true
        },
        lock_fields: {
            type: Array,
            required: true
        },
        leasing_out_or_not_list: {
            type: Array,
            required: true
        },
        leasing_out_or_not_list_checked: {
            type: Array,
            required: true
        },
        provision_list: {
            type: Array,
            required: true
        },
        provision_list_checked: {
            type: Array,
            required: true
        },
        leasing_mode_list: {
            type: Array,
            required: true
        },
        leasing_mode_list_checked: {
            type: Array,
            required: true
        },
        lock_list: {
            type: Array,
            required: true
        },
        lock_list_checked: {
            type: Array,
            required: true
        },
        connect_status_list: {
            type: Array,
            required: true
        },
        connect_status_list_checked: {
            type: Array,
            required: true
        },
        rent_list: {
            type: Array,
            required: true
        },
        rent_list_checked: {
            type: Array,
            required: true
        },
        total_endpoints_num: {
            type: Number,
            required: true
        },
        initialFilterName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentPageNum: 1,
            pageSize: getItem('pageSize') || 50,
            showChangeLeasingModeDialog: false,
            showChangeLeasingBufferPeriodDialog: false,
            showReturnDialog: false,
            showSendMessageDialog: false,
            showLockDialog: false,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            filterName: '',
            updateEndpointSelectedByButton: true,
            updateIsAllSelectedByButton: true,
            selectedEndpointTableHeaders: [
                { key: 'num', name: '序号' },
                { key: 'Name', name: 'SN' }
            ],
            pageSizeOptions: [
                    { label: '10', value: 10 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ],
            actionOption:
                [
                    { label: '更改租赁模式', value: 'Change Leasing Mode' },
                    { label: '更改缓冲期', value: 'Change Leasing Buffer Period' },
                    { label: '资产评估', value: 'Return' },
                    { label: '发送消息', value: 'SendMessage' },
                    { label: '停止服务', value: 'Lock' }
                ],
            updateLeasingmodeFields: prepareFields(this.update_leasingmode_fields),
            updateLeasingBufferPeriodFields: prepareFields(this.update_leasingbufferperiod_fields),
            addSentMessageFields: prepareFields(this.send_message_fields),
            lockFields: prepareFields(this.lock_fields),
            editForm: {
                Leasing_Mode: '1',
                Leasing_Buffer: '0',
                Final_Leasing_Buffer: '0',
                Message: 'APM Server正在测试发送消息',
                Lock: '0'
            },
            EndpointSelected: [],
            isAllSelected: false,
            filterData: [],
            localFilterName: this.initialFilterName
        }
    },
    computed: {
        tableData: function () {
            var i = 1;
            this.data.sort(function(a, b) {
                var snA = a.SN.toUpperCase();
                var snB = b.SN.toUpperCase();
                if (snA < snB)
                    return -1;
                if (snA > snB)
                    return 1;
                return 0;
            });
            this.filterData = this.data.filter(item => {
                let leasing_out_or_not_list_checked_all = false
                for (let each in this.leasing_out_or_not_list)
                    leasing_out_or_not_list_checked_all  = leasing_out_or_not_list_checked_all || this.leasing_out_or_not_list_checked[each]

                if(leasing_out_or_not_list_checked_all)
                    for (let each in this.leasing_out_or_not_list)
                        if (!this.leasing_out_or_not_list_checked[each] && (item.LeasingOutOrNot === this.leasing_out_or_not_list[each]))
                            return false

                let provision_list_checked_all = false
                for (let each in this.provision_list)
                    provision_list_checked_all  = provision_list_checked_all || this.provision_list_checked[each]

                if(provision_list_checked_all)
                    for (let each in this.provision_list)
                        if (!this.provision_list_checked[each] && (item.Provision === this.provision_list[each]))
                            return false

                let leasing_mode_list_checked_all = false
                for (let each in this.leasing_mode_list)
                    leasing_mode_list_checked_all  = leasing_mode_list_checked_all || this.leasing_mode_list_checked[each]

                if(leasing_mode_list_checked_all)
                    for (let each in this.leasing_mode_list)
                        if (!this.leasing_mode_list_checked[each] && (item.Lease_Mode === this.leasing_mode_list[each]))
                            return false

                let lock_list_checked_all = false
                for (let each in this.lock_list)
                    lock_list_checked_all  = lock_list_checked_all || this.lock_list_checked[each]

                if(lock_list_checked_all)
                    for (let each in this.lock_list)
                        if (!this.lock_list_checked[each] && (item.Lock_Machine === this.lock_list[each]))
                            return false

                let rent_list_checked_all = false
                for (let each in this.rent_list)
                    rent_list_checked_all  = rent_list_checked_all || this.rent_list_checked[each]

                if(rent_list_checked_all)
                    for (let each in this.rent_list)
                        if (!this.rent_list_checked[each] && (item.Is_Overdue === this.rent_list[each]))
                            return false

                let connect_status_list_checked_all = false
                for (let each in this.connect_status_list)
                    connect_status_list_checked_all  = connect_status_list_checked_all || this.connect_status_list_checked[each]

                if(connect_status_list_checked_all)
                    for (let each in this.connect_status_list)
                        if (!this.connect_status_list_checked[each] && (item.Machine_Status === this.connect_status_list[each]))
                            return false
                item.num = i;
                i++;

                return true
            })

            let tmp = this.filterData.slice((this.currentPageNum - 1) * this.pageSize, this.currentPageNum * this.pageSize);
            return tmp;
        },
        tableFields: function () {
            return this.table_fields.filter(item => {
                if (item.table_name === "")
                    return false
                return true
            })
        },
        endpointsFilterNum(){
            return Object.keys(this.filterData).length
        },
        // TODO： 增加页码设置
        totalPageNum: function () {
            let len = Math.ceil(this.filterData.length / this.pageSize)
            if (len === 0) len = 1
            if (len < this.currentPageNum) {
                this.currentPageNum = len
            }
            return len
        },
        sortedEndpointSelected() {
            return this.EndpointSelected.sort((a, b) => a.num - b.num);
        },
        endpointSelectedNum(){
            return Object.keys(this.EndpointSelected).length
        }
    },
    mount() {
        this.provisionedChecked == false;
    },
    watch: {
        // TODO： 全选
        isAllSelected(isAllSelected) {
            if (!this.updateIsAllSelectedByButton) {
                this.updateIsAllSelectedByButton = true;
                return;
            }
            if (isAllSelected) {
                this.EndpointSelected = this.tableData.slice();
            } else {
                this.EndpointSelected = [];
            }
            this.updateEndpointSelectedByButton = false;
            setItem("lastTime", new Date().getTime())
        },
        EndpointSelected() {
            if (!this.updateEndpointSelectedByButton) {
                this.updateEndpointSelectedByButton = true;
                return;
            }
            if (this.tableData && this.tableData.length && this.isAllSelected !== this.tableData.every(item => this.EndpointSelected.includes(item))) {
                this.isAllSelected = !this.isAllSelected;
                this.updateIsAllSelectedByButton = false;
            }
            setItem("lastTime", new Date().getTime())
        },
        initialFilterName(newVal) {
            this.localFilterName = newVal;
        }
    },
    methods: {
        pageDown() {
            if (this.currentPageNum !== 1) this.currentPageNum--;
            this.EndpointSelected = [];
            this.isAllSelected = false;
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if (this.currentPageNum !== this.totalPageNum) this.currentPageNum++;
            this.EndpointSelected = [];
            this.isAllSelected = false;
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPageNum = event.target.value
            setItem("lastTime", new Date().getTime())
        },
        changePageSize(pageSize) {
            this.pageSize = pageSize
            setItem('pageSize', pageSize);
            setItem("lastTime", new Date().getTime())
        },
        actions(action) {
            setItem("lastTime", new Date().getTime())
            switch (action) {
                case "Change Leasing Mode":
                    this.changeLeasingMode();
                    break;
                case "Change Leasing Buffer Period":
                    this.changeLeasingBufferPeriod();
                    break;
                case "Return":
                    this.returnMachines();
                    break;
                case "SendMessage":
                    this.sendMessage();
                    break;
                case "Reset":
                    this.resetMachines();
                    break;
                case "Lock":
                    this.lockMachines();
                    break;
            }
        },
        isLeasingModeOn(e) {
            this.showcreateFields2 = e
            setItem("lastTime", new Date().getTime())
        },
        returnMachines() {
            if (this.EndpointSelected.length === 0)
                alert("请先选择机器。")
            else
                this.showReturnDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelReturn() {
            this.showReturnDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmReturn() {
            this.showReturnDialog = false;
            let machines = []
            for (let index in this.EndpointSelected)
                machines.push({ "sn": this.EndpointSelected[index].SN })
            var user = getItem('userName')
            var job = {
                "job_id": user + "*eAR*" + getTimeHex(),//evaluate Asset Recovery
                "machines": machines
            }

            setItem("lastTime", new Date().getTime())
            this.$emit('handleReturn', job);
        },
        showdetails(sn) {
            let tmp = this.tableData.filter(item => {
                return item.SN === sn
            })[0]
            let data = JSON.parse(JSON.stringify(tmp))
            this.$emit('showDetails', data);
            this.$router.push({
                path: this.$route.path
            });
            location.reload();
            setItem("lastTime", new Date().getTime())
        },
        changeLeasingMode() {
            if (this.EndpointSelected.length === 0)
                alert("请先选择机器。")
            else
                this.showChangeLeasingModeDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelChangeLeasingMode() {
            this.showChangeLeasingModeDialog = false
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmChangeLeasingMode() {
            this.showChangeLeasingModeDialog = false

            var machines = []
            for (let index in this.EndpointSelected) {
                var machine = {
                    "sn": this.EndpointSelected[index].SN,
                    "lease_mode": parseInt(this.editForm.Leasing_Mode) + 2 * ( (this.EndpointSelected[index].Lock_Machine === "是") ? 1 : 0 ),
                }
                machines.push(machine)
            }
            var user = getItem('userName')
            var mode = JSON.stringify({
                "job_id": user + "*uLM*" + getTimeHex(),
                "machines": machines
            })
            this.$emit('handleUpdateLeasingMode', mode);
            setItem("lastTime", new Date().getTime())
        },
        changeLeasingBufferPeriod() {
            if (this.EndpointSelected.length === 0)
                alert("请先选择机器。")
            else
                this.showChangeLeasingBufferPeriodDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelChangeLeasingBufferPeriod() {
            this.showChangeLeasingBufferPeriodDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmChangeLeasingBufferPeriod() {
            const leasingBuffer = parseInt(this.editForm.Leasing_Buffer);
            const finalLeasingBuffer = parseInt(this.editForm.Final_Leasing_Buffer);

            if (isNaN(leasingBuffer) || leasingBuffer < 0 || leasingBuffer > 255) {
                alert('缓冲期必须是0到255之间的数字');
                return;
            }

            if (isNaN(finalLeasingBuffer) || finalLeasingBuffer < 0 || finalLeasingBuffer > 255) {
                alert('最终缓冲期必须是0到255之间的数字');
                return;
            }

            this.showChangeLeasingBufferPeriodDialog = false
            var machines = []
            for (let index in this.EndpointSelected) {
                var machine = {
                    "sn": this.EndpointSelected[index].SN,
                    "lease_term_buff": parseInt(this.editForm.Leasing_Buffer),
                    "lease_term_buff2": parseInt(this.editForm.Final_Leasing_Buffer),
                }
                machines.push(machine)
            }
            var user = getItem('userName')
            var buff = JSON.stringify({
                "job_id": user + "*uLB*" + getTimeHex(),
                "machines": machines
            })
            setItem("lastTime", new Date().getTime())
            this.$emit('handleUpdateLeasingBuff', buff);
        },
        sendMessage() {
            if (this.EndpointSelected.length === 0)
                alert("请先选择机器。")
            else
                this.showSendMessageDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelSendMessage() {
            this.showSendMessageDialog = false;
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmSendMessage() {
            this.showSendMessageDialog = false;

            let messages = []
            for (let index in this.EndpointSelected) {
                var endpoint = this.EndpointSelected[index]
                let message = {
                    "sn": endpoint.SN,
                    "argument": this.editForm.Message
                }
                messages.push(message)
            }
            let message_job = {
                "job_id": getItem('userName') + "*sAM*" + getTimeHex(),
                "user_id": getItem('userName'),
                "command": "message",
                "machines": messages
            }
            setItem("lastTime", new Date().getTime())
            this.$emit('handleSendMessageJob', message_job)
        },
        lockMachines() {
            if (this.EndpointSelected.length === 0)
                alert("请先选择机器。")
            else {
                let leasingModeOffEndpoints = []
                let provisionedEndpoints = []
                for (let index in this.EndpointSelected) {
                    if (this.EndpointSelected[index].Lease_Mode !== "开启")
                        leasingModeOffEndpoints.push(this.EndpointSelected[index].SN)
                    if(this.EndpointSelected[index].Provision !== "已完成安全配置")
                        provisionedEndpoints.push(this.EndpointSelected[index].SN)
                }
                if (leasingModeOffEndpoints.length !== 0){
                    let alertLogs = "以下机器未开启租赁模式，无法停止其服务，请重新选择。\n"
                    for (let i in leasingModeOffEndpoints) {
                        alertLogs += leasingModeOffEndpoints[i] + "\n"
                    }
                    alert(alertLogs)
                }
                else if(provisionedEndpoints.length !== 0){
                   let alertLogs = "以下机器未完成安全配置，无法停止其服务，请重新选择。\n"
                   for (let i in provisionedEndpoints) {
                       alertLogs += provisionedEndpoints[i] + "\n"
                   }
                   alert(alertLogs)
                }
                else 
                    this.showLockDialog = true
            }
            setItem("lastTime", new Date().getTime())
        },
        handleCancelLockMachines() {
            this.showLockDialog = false
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmLockMachines() {
            this.showLockDialog = false

            var machines = []
            for (let index in this.EndpointSelected) {
                var machine = {
                    "sn": this.EndpointSelected[index].SN,
                    "lease_mode": 1 + 2 * parseInt(this.editForm.Lock),
                }
                machines.push(machine)
            }
            var user = getItem('userName')
            var lockMachines = JSON.stringify({
                "job_id": user + "*uSS*" + getTimeHex(),
                "machines": machines
            })
            setItem("lastTime", new Date().getTime())
            this.$emit('handleLockMachines', lockMachines);
        },
        handleFilterByStr() {
            this.$emit('handleFilterByStr', this.localFilterName)
            setItem("lastTime", new Date().getTime())
            this.isAllSelected = false;
            this.ActionSelected = [];
        },
        handleClearFilters() {
            for (let each in this.leasing_out_or_not_list_checked)
                this.$set(this.leasing_out_or_not_list_checked, each, true);
            for (let each in this.provision_list_checked)
                this.$set(this.provision_list_checked, each, true);
            for (let each in this.leasing_mode_list_checked)
                this.$set(this.leasing_mode_list_checked, each, true);
            for (let each in this.lock_list_checked)
                this.$set(this.lock_list_checked, each, true);
            for (let each in this.connect_status_list_checked)
                this.$set(this.connect_status_list_checked, each, true);
            for (let each in this.rent_list_checked)
                this.$set(this.rent_list_checked, each, true);
            this.EndpointSelected = [];
            this.isAllSelected = false;
            setItem("lastTime", new Date().getTime());
        },
        handleLeasingOutOrNotUpdate(e, index) {
            this.$set(this.leasing_out_or_not_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
            if(this.$route.query.filter == 'remaining' || this.$route.query.filter == 'leasingOut'){
                this.$router.push({ path: '/Endpoint'}).catch(() => { });
                this.$emit('InitialData');
            }
        },
        handleLockMachineUpdate(e, index) {
            this.$set(this.lock_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
            if(this.$route.query.filter == 'lockMachine'){
                this.$router.push({ path: '/Endpoint'}).catch(() => { });
                this.$emit('InitialData');
            }
        },
        handleProvisionUpdate(e, index) {
            this.$set(this.provision_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
        },
        handleLeasingModeUpdate(e, index) {
            this.$set(this.leasing_mode_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
            if(this.$route.query.filter == 'leaseMode'){
                this.$router.push({ path: '/Endpoint'}).catch(() => { });
                this.$emit('InitialData');
            }
        },
        handleConnectStatusUpdate(e, index) {
            this.$set(this.connect_status_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
            if(this.$route.query.filter == 'onConnection' || this.$route.query.filter == 'notOnConnection'){
                this.$router.push({ path: '/Endpoint'}).catch(() => { });
                this.$emit('InitialData');
            }
        },
        handleRentUpdate(e, index) {
            this.$set(this.rent_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
            this.EndpointSelected = [];
            this.isAllSelected = false;
            if(this.$route.query.filter == 'overdue' || this.$route.query.filter == 'lessThan30d' || this.$route.query.filter == 'moreThan30d') {
                this.$router.push({ path: '/Endpoint'}).catch(() => { });
                this.$emit('InitialData');
            }
        }
    }
}

function prepareFields(fields) {
    return fields.map(field => {
        let tmp = { label_name: field.table_name, label: field.name, unit: field.unit }
        if (field.required) tmp.required = true
        if (field.type === 'string') tmp.type = 'text'
        else if (field.type === 'bool') tmp.type = 'checkbox'
        else if (field.type === 'enum') {
            tmp.type = 'select'
            tmp.options = field.options
        }
        return tmp
    })
}

function getTimeHex() {
    const now = new Date();
    const year = now.getFullYear().toString(16);
    const month = (now.getMonth() + 1).toString(16).padStart(2, '0'); // 月份是从0开始的
    const day = now.getDate().toString(16).padStart(2, '0');
    const hours = now.getHours().toString(16).padStart(2, '0');
    const minutes = now.getMinutes().toString(16).padStart(2, '0');
    const seconds = now.getSeconds().toString(16).padStart(2, '0');
    var time = `${year}${month}${day}${hours}${minutes}${seconds}`;
    var timeHex = time.toString(16)
    return timeHex;
}

</script>

<style scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}

.page-size-container {
    display: flex;
    align-items: center;
}

.page-size-container label {
    margin-right: 10px;
}

.page-size-container select {
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.machine-link:hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.search-input {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.filter {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filter-header span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0054ae;
}

.filter-header span:nth-child(2) {
    color: #00285a;
    font-size: 0.8rem;
}

.filter-header span:nth-child(2):hover {
    cursor: pointer;
}

.filter-area {
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 5px;
}

.filter-item span {
    margin-left: 10px;
    font-size: 0.8rem;
}
</style>
