<template>
    <dialog ref="dialog">
        <div class="dialog-header">
            <slot name="header">
                {{ title }}
                <i-button label="×" @click="handleCancel"
                    :css-style="{ float: 'right', border: 'none', outline: 'none', margin: '0', padding: '0', fontSize: '1.2rem' }"></i-button>
            </slot>
        </div>
        <div class="dialog-body">
            <slot name="body">
                default body
            </slot>
        </div>
        <div class="dialog-footer">
            <slot name="footer">
                <i-button label="确认" @click="handleConfirm"></i-button>
                <i-button label="取消" @click="handleCancel"></i-button>
            </slot>
        </div>
    </dialog>
</template>
<script>
import Button from './Button.vue';
export default {
    name: 'i-dialog',
    components: {
        "i-button": Button
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    },
    watch: {
        show: {
            handler: function (newV) {
                if (newV) this.$refs.dialog.showModal()
                else this.$refs.dialog.close()
            }
        }
    },
    mounted() {
        if (this.show) {
            this.$refs.dialog.showModal()
        }
    },
    methods: {
        handleCancel(event) {
            this.$emit('cancel', event)
        },
        handleConfirm(event) {
            this.$emit('confirm', event)
        }
    }
}
</script>
<style scoped>
dialog {
    display: flex;
    flex-direction: column;
    width: 700px;
    border-radius: 4px;
    border: none;
}

dialog:not([open]) {
    display: none;
}

.dialog-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.dialog-body {
    min-height: 200px;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>