<template>
    <div class="user">
        <UserList v-show="isUserListShow" :data="userList" :login_user_type="login_user_type"
            @handleCreateUser="handleCreateUser" @handleEditUser="handleEditUser" @handleDeleteUser="handleDeleteUser"
            @handleChangeUserPasswd="handleChangeUserPasswd" @handleResetUserPasswd="handleResetUserPasswd"
            @handleFilterByStr="handleFilterByStr" :table_fields="table_fields" :create_user_fields="create_user_fields"
            :edit_user_fields="edit_user_fields" :change_user_password_fields="change_user_password_fields"
            :reset_user_password_fields="reset_user_password_fields">
        </UserList>
        <i-operation-response-dialog :show="showResponseDialog" :dialogMessage="responseDialogMessage"
            @closeResponseDialog="closeResponseDialog">
        </i-operation-response-dialog>
    </div>
</template>

<script>
import UserList from '@/components/UserList.vue'
import UserOperationResponseDialog from '@/components/UserOperationResponseDialog.vue'
import { getAllUsers, createUser, deleteUser, updateUser, changeUserPasswd, resetUserPasswd, getAccessTokenbyRefreshToken } from '../apis/User'
import { getItem, setItem } from "../utils/storage.js";
import { LogOut } from "../utils/logout.js";

export default {
    name: 'User',
    components: {
        UserList,
        "i-operation-response-dialog": UserOperationResponseDialog
    },
    data() {
        return {
            isUserListShow: true,
            login_user_type: getItem('userName'),
            userList: [],
            showResponseDialog: false,
            responseDialogMessage: '',
            table_fields: [
                { name: 'Name', type: 'string', table_name: '用户名', isshow: true },
                { name: 'Description', type: 'string', table_name: '描述', isshow: true },
                { name: 'Group', type: 'string', table_name: '权限', isshow: true },
                { name: 'Action', type: 'string', table_name: '操作', isshow: true }
            ],
            create_user_fields: [
                { name: 'UserID', type: 'string', table_name: '用户名', isshow: true, required: true },
                { name: 'Description', type: 'string', table_name: '描述', isshow: true, required: false },
                { name: 'Email', type: 'string', table_name: '邮箱', isshow: false, required: false },
                { name: 'Passwd', type: 'password', table_name: '密码', isshow: true, required: true },
                { name: 'Passwd_verify', type: 'password', table_name: '确认密码', isshow: true, required: true }
            ],
            edit_user_fields: [
                { name: 'Description', type: 'string', table_name: '描述', isshow: true, required: true }
            ],
            change_user_password_fields: [
                { name: 'OldPasswd', type: 'password', table_name: '旧密码', isshow: true, required: true },
                { name: 'NewPasswd', type: 'password', table_name: '新密码', isshow: true, required: true },
                { name: 'NewPasswd_verify', type: 'password', table_name: '再次输入', isshow: true, required: true }
            ],
            reset_user_password_fields: [
                { name: 'Name', type: 'string', table_name: '用户名', isshow: false, required: true },
                { name: 'Passwd', type: 'password', table_name: '新密码', isshow: true, required: true },
                { name: 'Passwd_verify', type: 'password', table_name: '再次输入', isshow: true, required: true }
            ]
        }
    },
    methods: {
        InitialData() {
            return (getAllUsers().then(response => {
                let u = []
                let users = response.result
                for (let each in users){
                    let user = {
                        "user_id" : users[each]["user_id"],
                        "description" : users[each]["description"] ? users[each]["description"] : "",
                        "group" : (users[each]["user_id"] === "admin") ? "超级管理员" : "普通用户",
                    }
                    u.push(user)
                }
                this.userList = u;
                return true
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器出错，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.InitialData();
                        }).catch(error => {
                            this.responseDialogMessage = '获取全部用户失败';
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                            return false;
                        })
                    }
            }))
        },
        checkPasswordComplexity(password) {
            const minLength = 8;
            const maxLength = 128;

            const hasValidLength = password.length >= minLength && password.length <= maxLength;
            const hasUpperCase = /[A-Z]/.test(password);
            const hasLowerCase = /[a-z]/.test(password);
            const hasNumber = /[0-9]/.test(password);
            const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

            return hasValidLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
        },
        handleCreateUser(data) {
            const usernameRegex = /^[\u4e00-\u9fa5a-zA-Z0-9-_]+$/;
            if (!usernameRegex.test(data.UserID)) {
                this.responseDialogMessage = '用户名只能包含中文, 字母, 数字, - 和 _';
                this.showResponseDialog = true;
                return false;
            }
            // 1. Check if the fields are empty
            if (Object.keys(data).length === 0 || !data.hasOwnProperty('UserID') || !data.hasOwnProperty('Passwd') || !data.hasOwnProperty('Passwd_verify')) {
                this.responseDialogMessage = '请填写所有必填项';
                this.showResponseDialog = true;
                return false;
            }
            // 2. Check the description length
            if (data.Description && data.Description.length > 20) {
                this.responseDialogMessage = '描述不能超过20个字符';
                this.showResponseDialog = true;
                return;
            }
            // 3. Check password complexity
            if (!this.checkPasswordComplexity(data.Passwd)) {
                this.responseDialogMessage = '密码必须包含大写字母、小写字母、数字和特殊字符，并且长度在8到128个字符之间';
                this.showResponseDialog = true;
                return false;
            }
            // 4. Verify if the dual-input password matchs
            if (data.Passwd !== data.Passwd_verify) {
                this.responseDialogMessage = '两次密码输入不一致，请检查并重新输入';
                this.showResponseDialog = true;
                return false;
            }
            // 5. Process the data
            var user = JSON.stringify({
                "email": data.Email,
                "description": data.Description || "",
                "user_id": data.UserID,
                "password": data.Passwd
            });
            this.createOneUser(user)
        },
        createOneUser(user){
            createUser(user).then(response => {
                this.responseDialogMessage = '用户创建成功';
                this.showResponseDialog = true;
                this.InitialData();
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器出错，无法创建用户，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else if (error.response.status === 400) {
                        this.responseDialogMessage = '用户创建失败'
                        if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                            this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.createOneUser(user);
                        }).catch(error => {
                            this.responseDialogMessage = '用户创建失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                            return false;
                        })
                }
            });
        },
        handleEditUser(data, e) {
            if (Object.keys(data).length === 0 || !data.hasOwnProperty('Description')) {
                this.responseDialogMessage = '请填写所有必填项';
                this.showResponseDialog = true;
                return;
            }
            if (data.Description.length > 20) {
                this.responseDialogMessage = '描述不能超过20个字符';
                this.showResponseDialog = true;
                return;
            }
            var user = JSON.stringify({
                "description": data.Description,
                "user_id": e.user_id,
            });

            this.updateOneUser(user)
        },
        updateOneUser(user){
            updateUser(user).then(response => {
                this.responseDialogMessage = '用户信息更新成功';
                this.showResponseDialog = true;
                this.InitialData();
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器问题，无法更新用户数据，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.updateOneUser(user);
                        }).catch(error => {
                            this.responseDialogMessage = '用户信息更新失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                        })
                    }
            })
        },
        handleDeleteUser(user) {
            deleteUser(user).then(response => {
                this.responseDialogMessage = '用户删除成功';
                this.showResponseDialog = true;
                this.InitialData();
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器问题，无法删除用户，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.handleDeleteUser(user);
                        }).catch(error => {
                            this.responseDialogMessage = '用户删除失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                        })
                    }
            })
        },
        handleChangeUserPasswd(data) {
            if (Object.keys(data).length === 0 || !data.hasOwnProperty('OldPasswd') || !data.hasOwnProperty('NewPasswd') || !data.hasOwnProperty('NewPasswd_verify')) {
                this.responseDialogMessage = '请填写所有必填项';
                this.showResponseDialog = true;
                return;
            }
            if (!this.checkPasswordComplexity(data.NewPasswd)) {
                this.responseDialogMessage = '密码必须包含大写字母、小写字母、数字和特殊字符，并且长度在8到128个字符之间';
                this.showResponseDialog = true;
                return false;
            }
            // Verify if the dual-input password matchs
            if (data.NewPasswd !== data.NewPasswd_verify) {
                this.responseDialogMessage = '两次密码输入不一致，请检查并重新输入';
                this.showResponseDialog = true;
                return;
            }
            var user = JSON.stringify({
                "old_password": data.OldPasswd,
                "new_password": data.NewPasswd
            });

            this.changeOneUserPasswd(user)
        },
        changeOneUserPasswd(user){
            changeUserPasswd(user).then(response => {
                this.responseDialogMessage = "用户密码修改成功\n点击 '确定' 重新登录";
                this.showResponseDialog = true;
                this.InitialData();
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器问题，无法修改用户密码，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.changeOneUserPasswd(user);
                        }).catch(error => {
                            this.responseDialogMessage = '用户密码修改失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                        })
                    }
            })
        },
        handleResetUserPasswd(data, e) {
            if (Object.keys(data).length === 0 || !data.hasOwnProperty('Passwd') || !data.hasOwnProperty('Passwd_verify')) {
                this.responseDialogMessage = '请填写所有必填项';
                this.showResponseDialog = true;
                return;
            }
            if (!this.checkPasswordComplexity(data.Passwd)) {
                this.responseDialogMessage = '密码必须包含大写字母、小写字母、数字和特殊字符，并且长度在8到128个字符之间';
                this.showResponseDialog = true;
                return false;
            }
            if (data.Passwd !== data.Passwd_verify) {
                this.responseDialogMessage = '两次密码输入不一致，请检查并重新输入';
                this.showResponseDialog = true;
                return;
            }
            var user = JSON.stringify({
                "user_id": e.user_id,
                "new_password": data.Passwd
            });

            this.resetOneUserPasswd(user)
        },
        resetOneUserPasswd(user){
            resetUserPasswd(user).then(response => {
                this.responseDialogMessage = '用户密码重置成功';
                this.showResponseDialog = true;
                this.InitialData();
            }).catch(error => {
                if (error.response != null && error.response != undefined && error.response.status != null && error.response.status != undefined)
                    if (error.response.status === 500) {
                        this.responseDialogMessage = '服务器出错，请联系管理员。';
                        this.showResponseDialog = true;
                        return false;
                    } else {
                        this.updateToken().then(res =>{
                            // this.resetOneUserPasswd(user);
                        }).catch(error => {
                            this.responseDialogMessage = '用户密码重置失败'
                            if(error.response != null && error.response != undefined)
                                if(error.response.data != null && error.response.data != undefined && error.response.data.message != null && error.response.data.message != undefined)
                                    this.responseDialogMessage += '\nerror: ' + error.response.data.message;
                            this.showResponseDialog = true;
                        })
                    }
            })
        },
        handleFilterByStr(str) {
            this.InitialData().then(() => {
                this.userList = this.userList.filter(item => {
                    for (let key in item)
                        if ((item[key] !== null) && (item[key] !== undefined))
                            if (item[key].includes(str)) return true
                })
            })
        },
        closeResponseDialog() {
            if (this.responseDialogMessage === "用户密码修改成功\n点击 '确定' 重新登录" || this.responseDialogMessage === "用户已在其他地方登录。请重新登录" || this.responseDialogMessage === "Token过期，请重新登录。") {
                LogOut()
                this.$router.push('/Login');
            }
            this.showResponseDialog = false;
            this.responseDialogMessage = '';
        },
        updateToken(){
            let refreshToken = getItem('refreshToken');
            let token = {"token": refreshToken}
            return getAccessTokenbyRefreshToken(token).then(response => {
                if (response.message === "Request successful") {
                    setItem('accessToken', response.result.access_token);
                    setItem('refreshToken', response.result.refresh_token);
                }
            }).catch(error => {
                if (error.response.data.statusCode === 403){
                    this.responseDialogMessage = "用户已在其他地方登录。请重新登录";
                    this.showResponseDialog = true;
                } else if (error.response.data.statusCode === 404) {
                    this.responseDialogMessage = "网络连接问题，请重试。";
                    this.showResponseDialog = true;
                } else if (error.response.data.statusCode === 400) {
                    this.responseDialogMessage = "Token过期，请重新登录。";
                    this.showResponseDialog = true;
                } else if (error.response.data.statusCode === 500) {
                    this.responseDialogMessage = "服务器出错，请联系管理员。";
                    this.showResponseDialog = true;
                } else {
                    this.responseDialogMessage = "发生未知错误，请稍后再试。";
                    this.showResponseDialog = true;
                }
            });
        }
    },
    mounted() {
        this.InitialData();
    }
};
</script>

<style scoped>
ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.pagination {
    display: flex;
    align-items: center;
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}
</style>