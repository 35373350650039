<template>
  <div class="log-container">
    <div v-html="formattedLogContent"></div>
  </div>
</template>

<script>
import { getItem } from "../utils/storage.js";
export default {
  props: {
    content: {
        type: String,
        required: true
    },
  },
  computed: {
    formattedLogContent() {
        return this.content.replace(/([^0]条失败|具体失败原因)/g, '<span style="color: red;">$&</span>').replace(/\n/g, '<br>');
    },
  }
}
</script>

<style>
.log-container {
  border: 1px solid #ccc; /* 设置浅灰色外边框 */
  padding: 10px; /* 设置内边距 */
  height: 800px; /* 设置固定高度 */
  width: 1800px;
  overflow-y: scroll; /* 显示垂直滚动条 */
}
</style>