<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'App',
}
</script>
<style>
body,
html {
    margin: 0;
    height: 100%;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

#app {
    height: 100%;
}
</style>