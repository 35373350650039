"use strict";
import { getItem } from "./storage.js";

let timeOut = 1000 * 60 * 30; //设置超时时间: 30分钟

export function checkTimeout() {
    let currentTime = new Date().getTime(); //get current time
    let lastTime = getItem("lastTime");
    if (currentTime - lastTime > timeOut)
        return true
    else return false   
}