export default {
  setTabName(state, newName) {
    state.tabName = newName;
  },
  setIsOverview(state, newStatus) {
    state.isOverviewShow = newStatus;
  },
  setEndpoint(state, endpoint) {
    state.endpoint = endpoint;
  },
  removeField(state, fieldName) {
    delete state[fieldName];
  }
};