<template>
    <div class="grouplist">
        <i-tab>
            <i-tab-item name="设备组" selected="true">
                <span>管理设备组</span>
                <td class="cell-align" style="padding-top: 10px;"></td>
                <div class="create-search">
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="filterStr"
                            placeholder="Enter Name to search" @handleEnter="handleFilterByStr">
                        </i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size" style="cursor: pointer;"
                            @click="handleFilterByStr">
                    </div>
                    <div v-if="login_group_type == 'admin'">
                        <button class="button" @click="clickCreateGroup" style="cursor: pointer;">创建设备组</button>
                    </div>
                </div>
                <div style="flex: 1">
                    <table class="custom-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="background-color : #0c2744" v-for="field in table_fields" :key="field.fid">{{
                                    field.table_name }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(group, index) in displayedData" :key="index"
                                :class="{ 'row-shadow': index % 2 !== 0 }">
                                <template v-if="login_group_type != 'admin' && group.group_id == 'admin'">

                                </template>
                                <template v-else>
                                    <td>{{ group }}</td>
                                    <td>{{ group.description }}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div class="dropdown">
                                            <button class="dropbtn"> 请选择操作</button>
                                            <div class="dropdown-content">
                                                <div v-if="group.group_id !== 'admin'">
                                                    <a href="#" @click.prevent="clickDeleteGroup(group)">删除设备组</a>
                                                </div>
                                                <a href="#" @click.prevent="clickEditGroup(group)">编辑信息</a>
                                            </div>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination">
                    <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                    <i-input v-model="currentPage"></i-input>
                    <span style="margin-left: 6px;">{{ ' / ' + totalPages }}</span>
                    <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                </div>
            </i-tab-item>
        </i-tab>

        <i-create-group-dialog :show="showCreateGroupDialog" :title="'创建设备组'" @cancel="handleCancelGroupCreate"
            @confirm="handleConfirmGroupCreate">
            <template slot="body">
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    待开发
                </div>
            </template>
        </i-create-group-dialog>

        <i-edit-group-dialog :show="showEditGroupDialog" :title="'编辑信息'" @cancel="handleCancelGroupEdit"
            @confirm="handleConfirmGroupEdit">
            <template slot="body">
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    待开发
                </div>
            </template>
        </i-edit-group-dialog>

        <i-delete-group-dialog :show="showDeleteGroupDialog" :title="'删除设备组'" @cancel="handleCancelDeleteGroupDialog"
            @confirm="handleConfirmDeleteGroupDialog">
            <template slot="body">
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    待开发
                </div>
            </template>
        </i-delete-group-dialog>

        <i-operation-response-dialog :show="showResponseDialog" :dialogMessage="responseDialogMessage"
            @closeResponseDialog="closeResponseDialog">
        </i-operation-response-dialog>
    </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import CreateGroupDialog from './Dialog.vue'
import DeleteGroupDialog from './Dialog.vue'
import EditGroupDialog from './Dialog.vue'
import GroupOperationResponseDialog from './Dialog.vue'
import Tab from '@/components/Tab.vue'
import TabItem from '@/components/TabItem.vue'
import { setItem } from '../utils/storage.js'
export default {
    components: {
        "i-input": Input,
        "i-button": Button,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-edit-group-dialog": EditGroupDialog,
        "i-create-group-dialog": CreateGroupDialog,
        "i-delete-group-dialog": DeleteGroupDialog,
        "i-operation-response-dialog": GroupOperationResponseDialog
    },
    props: {
        table_fields: {
            type: Array,
            required: true
        },
        login_group_type: {
            type: String,
            default: "group"
        },
        responseStatusList: {
            type: Object,
            requried: true
        },
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentSelectedGroup: {},
            currentPage: 0,
            pageSize: 10,
            filterStr: '',
            responseDialogMessage: '',
            showCreateGroupDialog: false,
            showEditGroupDialog: false,
            showDeleteGroupDialog: false,
            showResponseDialog: false,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            createGroupForms: {},
            editGroupForm: {},
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.data.length / this.pageSize);
        },
        displayedData() {
            const start = this.currentPage * this.pageSize;
            const end = start + this.pageSize;
            return this.data.slice(start, end);
        }
    },
    methods: {
        /* page */
        pageDown() {
            if (this.currentPage > 0) this.currentPage--;
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if (this.currentPage < this.totalPages - 1) this.currentPage++;
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPage = event.target.value;
            setItem("lastTime", new Date().getTime())
        },
        // Create group
        clickCreateGroup(index) {
            this.showCreateGroupDialog = true;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelGroupCreate() {
            this.showCreateGroupDialog = false;
            this.currentSelectedGroup = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmGroupCreate() {
            var e = prepareGroupJson(this.createGroupForms)
            this.$emit('handleCreateGroup', e)
            this.showCreateGroupDialog = false
            // response dialog
            if (this.responseStatusList.createGroup) {
                this.responseDialogMessage = '设备组创建成功';
                this.showResponseDialog = true;
                this.currentSelectedGroup = {};
                this.createGroupForms = {};
            }
            setItem("lastTime", new Date().getTime())
        },
        // Edit group
        clickEditGroup(group) {
            this.showEditGroupDialog = true;
            this.currentSelectedGroup = group;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelGroupEdit() {
            this.showEditGroupDialog = false;
            this.currentSelectedGroup = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmGroupEdit() {
            this.showEditGroupDialog = false;
            let form = this.editGroupForm;
            var group = JSON.stringify({
                "description": form.Description
            });
            this.$emit('handleEditGroup', group);
            if (this.responseStatusList.editGroup) {
                this.responseDialogMessage = '设备组信息编辑成功'
                this.showResponseDialog = true
                this.currentSelectedGroup = {};
                this.editGroupForm = {};
            }
            setItem("lastTime", new Date().getTime())
        },
        // Delete group
        clickDeleteGroup(group) {
            this.showDeleteGroupDialog = true;
            this.currentSelectedGroup = group;
            setItem("lastTime", new Date().getTime())
        },
        handleCancelDeleteGroupDialog() {
            this.showDeleteGroupDialog = false;
            this.currentSelectedGroup = {};
            setItem("lastTime", new Date().getTime())
        },
        handleConfirmDeleteGroupDialog() {
            this.showDeleteGroupDialog = false
            let e = this.currentSelectedGroup;
            var group = JSON.stringify({ "group_id": e.group_id });
            this.$emit('handleDeleteGroup', group);
            if (this.responseStatusList.deleteGroup) {
                this.responseDialogMessage = '设备组删除成功';
                this.showResponseDialog = true;
                this.currentSelectedGroup = {};
            }
            setItem("lastTime", new Date().getTime())
        },
        handleFilterByStr() {
            this.$emit('handleFilterByStr', this.filterStr)
            setItem("lastTime", new Date().getTime())
        },
        closeResponseDialog() {
            this.showResponseDialog = false;
            this.responseDialogMessage = '';
            setItem("lastTime", new Date().getTime())
        }
    }
}

function prepareGroupJson(e) {
    var group = JSON.stringify({
        "email": e.Email ? e.Email : "test2@intel.com",
        "description": e.Description ? e.Description : "test",
        "group_id": e.GroupID ? e.GroupID : "test"
    });
    return group;
}

</script>

<style>
.pagination {
    display: flex;
    align-items: center;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.row-shadow {
    background-color: #f0f0f0;
}

/* Search */
.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.search-input {
    width: 180px !important;
    height: 40px !important;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 0px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

/* Dropdown Button */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

/* needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
</style>