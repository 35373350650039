<template>
  <div class="user-avatar">
    {{ userInitials }}
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    userInitials() {
      return this.user.avatarUrl ? '' : this.user.name.charAt(0) + (this.user.name.length > 1 ? this.user.name.charAt(1) : '');
    }
  }
}
</script>

<style scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #00a3f6;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  font-size: 1.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
}
</style>