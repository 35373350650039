<template>
    <div class="group">
        <EndpointGroupList v-show="isEndpointGroupListShow" :data="groupList" :login_group_type="login_group_type"
            :responseStatusList="responseStatusList" @handleFilterByStr="handleFilterByStr" :table_fields="table_fields">
        </EndpointGroupList>
    </div>
</template>

<script>
import EndpointGroupList from '@/components/EndpointGroupList.vue'
import { getAllEndpoints } from '../apis/Machine'
export default {
    name: 'EndpointGroup',
    components: {
        EndpointGroupList
    },
    data() {
        return {
            isEndpointGroupListShow: true,
            login_group_type: 'admin',
            groupList: [],
            responseStatusList: {
                createEndpointGroup: true,
                deleteEndpointGroup: true,
                editEndpointGroup: true
            },
            table_fields: [
                { name: 'Name', type: 'string', table_name: '设备组', isshow: true },
                { name: 'Description', type: 'string', table_name: '描述', isshow: true },
                { name: 'Group', type: 'string', table_name: '缓冲期', isshow: true },
                { name: 'Group', type: 'string', table_name: '最终缓冲期', isshow: true },
                { name: 'Action', type: 'string', table_name: '操作', isshow: true }
            ]
        }
    },
    methods: {
        InitialData() {
            return new Promise((resolve, reject) => {
                getAllEndpoints().then(res => {
                    if (res.status === "success") {
                        if ((res["endpoints"] == null) || (res["endpoints"] == undefined)) {
                            resolve(false);
                            return;
                        }

                        this.groupList = [];

                        for (let each in res["endpoints"]) {
                            let item = res["endpoints"][each];

                            let groupName = "";
                            if ((item.metadata != null) && (item.metadata != undefined)) {
                                if ((item.metadata["custom_EndpointGroup"] != null) && (item.metadata["custom_EndpointGroup"] != undefined)) {
                                    if ((item.metadata["custom_EndpointGroup"]["data"] != null) && (item.metadata["custom_EndpointGroup"]["data"] != undefined)) {
                                        groupName = item.metadata["custom_EndpointGroup"]["data"];
                                    }
                                }
                            }

                            if (groupName && !this.groupList.includes(groupName)) {
                                this.groupList.push(groupName);
                            }
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch(error => {
                    let responseDialogMessage = "无法获取数据。";
                    if (error.response != null && error.response != undefined) {
                        if (error.response.status === 500) {
                            responseDialogMessage += "\nerror: 服务器错误，请联系管理员。";
                        } else if (error.response.data != null && error.response.data != undefined && error.response.data.data != null && error.response.data.data != undefined) {
                            responseDialogMessage += "\nerror: " + error.response.data.data;
                        } else {
                            responseDialogMessage += "\nerror: 网络链接失败。";
                        }
                    } else {
                        responseDialogMessage += "\nerror: 未知错误，请稍后再试。";
                    }
                    alert(responseDialogMessage);
                    return false;
                    });
                });
        },

        handleFilterByStr(str) {
            this.InitialData().then(() => {
                if (str !== '') {
                    this.groupList = this.groupList.filter(item => {
                        if (typeof item === 'string' || typeof item === 'number') {
                            return item.toString().includes(str);
                        } else if (typeof item === 'object' && item !== null) {
                            for (let key in item) {
                                if ((item[key] !== null) && (item[key] !== undefined)) {
                                    if (item[key].toString().includes(str)) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    this.InitialData();
                }
            });
        }
    },
    mounted() {
        this.InitialData();
    },
    create() {
        this.InitialData();
    }
};
</script>

<style scoped>
ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.pagination {
    display: flex;
    align-items: center;
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}
</style>