<template>
    <input :value="value" :disabled="disabled" :placeholder="placeholder" @input="handleInput" :type="type"
        @keydown.enter="handleEnter" />
</template>

<script>
export default {
    name: 'i-input',
    inheritAttrs: false,
    props: {
        value: {
            type: String | Number,
            default: ''
        },
        placeholder: {
            type: String | Number
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String
        }
    },
    methods: {
        handleInput(event) {
            this.$emit("input", event.target.value)
        },
        handleEnter() {
            this.$emit("handleEnter")
        }
    }
}
</script>

<style scoped>
input {
    width: 30px;
    height: 28px;
    line-height: 28px;
    /* text-align: center; */
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}
</style>