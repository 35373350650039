<template>
    <button @click="handleClick" :style="cssStyle">{{ label }}</button>
</template>

<script>
export default {
    name: 'i-button',
    props: {
        label: {
            type: String,
            required: true
        },
        cssStyle: {
            type: Object
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event)
        }
    }
}
</script>
<style scoped>
button {
    padding: 5px 14px;
    margin: 0 5px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    background-color: white;
    height: 36px;
    vertical-align: middle;
    text-align: center;
}

button:hover {
    /* box-shadow: 0 0 8px 0 rgba(232,237,250,.6), 0 2px 4px 0 rgba(232,237,250,.5); */
    cursor: pointer;
}
</style>