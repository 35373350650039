<template>
    <div class="footer">
      <span class="non-clickable">Intel® APM V0.0.0</span>
      <span class="separator"></span>
      <span class="non-clickable">apm</span>
      <span class="separator"></span>
      <span class="clickable" @click="openPdf">英特尔许可协议</span>
      <span class="separator"></span>
      <a href="#" class="clickable" @click="openTxt">Intel® APM 第三方程序文件</a>
    </div>
  </template>
  
  <script>
export default {
    name: 'i-footer',
    methods: {
    openPdf() {
      window.open('/Intel OBL Distribution (Commercial Use) License Agreement [v2022.12.20].pdf', '_blank');
    },
    openTxt() {
      window.open('/tpp 3.txt', '_blank');
    }
  }
}
  </script>
  <style>
  .footer {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    /*background-color: #f2f2f2;*/
    padding-bottom: 10px;
  }
  .footer .separator {
  border-left: 1px solid black;
  margin: 0 10px;
  text-align: center;
}
  
  .circle {
    display: inline-block;
    border-radius: 50%;
    background-color: #f2f2f2;
    padding: 2px 6px;
    font-weight: bold;
  }
  
  .non-clickable {
    margin: 0 10px;
  }
  
  .clickable {
    margin: 0 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  </style>
  