<template>
    <div class="setting-tab">
        <i-tab>
            <i-tab-item name="设置" selected="true">
                <div>待开发</div>
            </i-tab-item>
        </i-tab>
    </div>
</template>
<script>
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'

export default {
    components: {
        "i-tab": Tab,
        "i-tab-item": TabItem,
    }
}
</script>