<template>
    <div class="home">
        <LoadingOverlay :visible="isUploading"></LoadingOverlay>
        <Header name="Intel® Asset Protection Manageability Solution"></Header>
        <div style="flex: 1;overflow-x: auto">
            <div class="menu-content-wrapper">
                <div class="menutitle">
                    <div class="menutitlelink" @click="clickLink">{{ menuName }}</div>
                    <div v-if="isShow" style="display: inline-block; padding-left: 5px;">
                        > {{ tabName }}
                    </div>
                </div>
                <div style="height: 40px;"></div>
                <div class="main">
                    <div class="nav-menu">
                        <nav-menu v-if="login_user != 'admin'" :linkList="links"
                            @changeActiveMenu="changeActiveMenu"></nav-menu>
                        <nav-menu v-else :linkList="adminlinks" @changeActiveMenu="changeActiveMenu"></nav-menu>
                    </div>
                    <div class="content">
                        <router-view @upload-start="showLoading" @upload-end="hideLoading"></router-view>
                        <i-footer></i-footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

const NAMEURL = {
    'Overview': '主页',
    'EndpointGroup': '设备组',
    'Endpoint': '设备',
    'User': '用户',
    'Upload': '数据同步',
    'History': '操作历史',
    'Settings': '设置'
}
import Header from '@/components/Header.vue'
import NavMenu from '../components/NavMenu.vue';
import Footer from '../components/Footer.vue'
import LoadingOverlay from '../components/LoadingOverlay.vue';
import { getItem, setItem } from "../utils/storage.js";
import { LogOut } from "../utils/logout.js";

export default {
    name: 'Home',
    components: {
        Header,
        NavMenu,
        "i-footer": Footer,
        LoadingOverlay
    },
    data() {
        return {
            links: [
                { name: '主页', url: '/#/Overview' },
                { name: '设备组', url: '/#/EndpointGroup' },
                { name: '设备', url: '/#/Endpoint' },
                { name: '用户', url: '/#/User' },
                { name: '数据同步', url: '/#/Upload' },
                { name: '操作历史', url: '/#/History' },
                { name: '设置', url: '/#/Settings' }
            ],
            adminlinks: [
                { name: '主页', url: '/#/Overview' },
                { name: '用户', url: '/#/User' },
                { name: '设置', url: '/#/Settings' }
            ],
            login_user: getItem('userName'),
            isUploading: false
        }
    },
    computed: {
        menuName: function () {
            return NAMEURL[this.$route.path.split("/").slice(-1)[0]]
        },
        tabName: function () {
            return this.$store.state.tabName;
        },
        isShow: function (){
            return !this.$store.state.isOverviewShow;
        }
    },
    mounted() {
        this.listenToStorageChanges();
        window.addEventListener("beforeunload", () => this.beforeunloadHandler());
        window.addEventListener("unload", () => this.unloadHandler());
    },
    beforeDestroy() {
        window.removeEventListener("storage", this.handleStorageChange);
        window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
        window.removeEventListener("unload", () => this.unloadHandler());
    },
    methods: {
        InitialData() {
            this.menuName = getItem("activeMenu") ? getItem("activeMenu") : "主页"
        },
        showLoading() {
            this.isUploading = true;
            setItem('isUploading', true);
        },
        hideLoading() {
            this.isUploading = false;
            setItem('isUploading', false);
        },
        changeActiveMenu(name) {
            this.menuName = name
            setItem('activeMenu', name);
        },
        clickLink(){
            this.$store.commit('setIsOverview', true)
            this.$store.commit('setTabName', "")
            location.reload();
        },
        beforeunloadHandler() {
            this.beforeUnload_time = new Date().getTime();
        },
        unloadHandler() {
            this.gap_time = new Date().getTime() - this.beforeUnload_time;
            if (this.gap_time <= 10) {
                let count = parseInt(localStorage.getItem('tabCount') || '0', 10);
                if (isNaN(count)) {
                    count = 0;
                }
                count--;
                setItem('tabCount', count);
                if (count <= 0) {
                    LogOut();
                }
            } else {
            }
        },
        listenToStorageChanges() {
            this.handleStorageChange = (event) => {
                if (event.key === 'logoutEvent') {
                    sessionStorage.clear();
                }
            };
            window.addEventListener("storage", this.handleStorageChange);
        }
    },
    created() {
        this.InitialData();
        this.isUploading = localStorage.getItem('isUploading') === 'true';     
        if (!sessionStorage.getItem('hasIncreasedCount')) {
            let count = parseInt(localStorage.getItem('tabCount') || '0', 10);
            if (isNaN(count)) {
                count = 0;
            }
            count++;
            setItem('tabCount', count);
            sessionStorage.setItem('hasIncreasedCount', 'true');
        }
    },
    // watch: {
    //     '$route'(to, from) {
    //         const clickActionDetail = getItem('clickActionDetail');
    //         if (!clickActionDetail) {
    //             this.$store.commit('setTabName', '');
    //             this.$store.commit('setIsOverview', true);
    //         } else {
    //             setItem('clickActionDetail', false)
    //         }
    //     }
    // },
}
</script>
<style scoped>
.home {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu-content-wrapper {
    min-width: fit-content;
}

.menutitle {
    width: 100%;
    height: 40px;
    background-color: #00a3f6;
    color: white;
    font-size: 1.1rem;
    position: fixed;
    z-index: 1000;
    /*align-items: center;*/
    align-content: center;
    box-sizing: border-box;
    padding-left: 10px;
}
.menutitlelink{
    color: white;
    display: inline-block;
    cursor: pointer;
}

.main {
    flex: 1;
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin-left: 135px;
}

.router-view {
    flex: 1;
}

.nav-menu {
    position: fixed;
    min-height: 100vh;
    /* 最小高度设置为视口高度 */
    width: auto;
    z-index: 1000;
}
</style>