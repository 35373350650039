<template>
  <div>
    <ActionHistory v-show="isOverviewShow" name="ActionHistory" :data="ActionList" :table_fields="table_fields"
      :user_list="UserList" :user_list_checked="UserListChecked" :job_list="JobList" :job_list_checked="JobListChecked" :job_status_list="job_status_list" :job_status_list_checked="job_status_list_checked"
      :error_message="errorMessage" :totalJobNum="totalJobNum" @showDetails="showDetails" @handleFilterByStr="handleFilterByStr"></ActionHistory>
    <ActionHistoryDetails v-show="isDetailsShow" name="ActionHistoryDetails" :job_id="Job_id" :data="JobDetail" 
      :error_message="errorMessage" :details_fields="details_fields" :totalActionNum="totalActionNum" :connect_status_list="connect_status_list" :connect_status_list_checked="connect_status_list_checked" :status_list="status_list" :status_list_checked="status_list_checked"
      @handleDetailFilterByStr="handleDetailFilterByStr" @handleDeleteAction="handleDeleteAction">
    </ActionHistoryDetails>
  </div>
</template>

<script>
import ActionHistory from '../components/ActionHistory.vue'
import ActionHistoryDetails from '../components/ActionHistoryDetails.vue'
import { getItem, setItem } from '../utils/storage.js'

import { getAllJob, getOneJob, cancelIncompleteAction } from '../apis/Action'

const JOBTYPE = {
  "uLM": "更改租赁模式",
  "uLB": "更改缓冲期或最终缓冲期",
  "uSS": "停止服务",
  "sAM": "发送消息",
  "eAR": "资产评估"
}

const CONNECTIONSTATUS = {
  "connected": "已联网",
  "disconnected": "未联网"
}

const ACTIONRESULT = {
  "success": "成功",
  "fail": "失败",
  "incomplete": "未完成"
}

export default {
  name: 'History',
  components: {
    ActionHistory,
    ActionHistoryDetails
  },
  data() {
    return {
      isOverviewShow: this.$store.state.isOverviewShow,
      isDetailsShow: !this.$store.state.isOverviewShow,
      ActionList: [],
      UserList: [],
      UserListChecked: [],
      JobList: [],
      JobListChecked: [],
      job_status_list: [],
      job_status_list_checked: [],
      JobDetail: [],
      filterStr: '',
      detailFilterStr: '',
      table_fields: [
        { name: 'User', type: 'string', table_name: '操作员' },
        { name: 'Job', type: 'string', table_name: '任务' },
        { name: 'Sending_Time', type: 'string', table_name: '发送时间' },
        { name: 'Update_Time', type: 'string', table_name: '更新时间' },
        { name: 'Action_Total', type: 'string', table_name: '操作总数' },
        { name: 'Success_Amount', type: 'string', table_name: '成功执行数量' },
        { name: 'Fail_Amount', type: 'string', table_name: '失败执行数量' },
        { name: 'Imcomplete_Amount', type: 'string', table_name: '未完成数量' }
      ],
      Job_id: "",
      details_fields: [
        { name: 'User', type: 'string', table_name: '操作员' },
        { name: 'Task', type: 'string', table_name: '任务' },
        { name: 'Sending_Time', type: 'string', table_name: '发送时间' },
        { name: 'Machine_SN', type: 'string', table_name: '机器' },
        { name: 'Machine_Status', type: 'string', table_name: '机器状态' },
        { name: 'Task_Result', type: 'string', table_name: '处理结果' },
        { name: 'Error_Message', type: 'string', table_name: '备注' }
      ],
      pollingInterval: null,
      errorMessage: '',
      totalJobNum: 0,
      totalActionNum: 0,
      connect_status_list: [],
      connect_status_list_checked: [],
      status_list: [],
      status_list_checked: []
    }
  },
  props: ['filter'],
  methods: {
    InitialData(filterStr = '') {
      return getAllJob().then(res => {
        if ((res["jobs"] == null) || (res["jobs"] == undefined)) return false
        let j = []
        for (let each in res["jobs"]) {
          let item = res["jobs"][each]

          var job_id = ((item.job_id == null) || (item.job_id == undefined)) ? "" : item.job_id;
          var user_name = (job_id === "") ? "" : (job_id.split("*")[0] ? job_id.split("*")[0] : "");
          var job_type = (job_id === "") ? "" : (job_id.split("*")[1] ? JOBTYPE[job_id.split("*")[1]] : "");

          let job_status = []

          if((item.action_fail_counter != null) && (item.action_fail_counter != undefined))
            if ( item.action_fail_counter != 0 )
              job_status.push("存在失败任务")

          if((item.action_incomplete_counter != null) && (item.action_incomplete_counter != undefined))
            if ( item.action_incomplete_counter != 0 )
              job_status.push("存在未完成任务")

          if((item.action_counter != null) && (item.action_counter != undefined) && (item.action_success_counter != null) && (item.action_success_counter != undefined) && item.action_counter === item.action_success_counter)
              job_status.push("所有均成功")

          let job = {
            'job_id': job_id,
            'User': user_name,
            'Job': job_type,
            'Sending_Time': ((item.created_at == null) || (item.created_at == undefined) || (item.created_at === "")) ? "" : new Date(item.created_at).toLocaleString(),
            'Update_Time': ((item.updated_at == null) || (item.updated_at == undefined) || (item.updated_at === "")) ? "" : new Date(item.updated_at).toLocaleString(),
            'Action_Total': (((item.action_counter == null) || (item.action_counter == undefined)) ? "" : item.action_counter) + "条",
            'Success_Amount': (((item.action_success_counter == null) || (item.action_success_counter == undefined)) ? "" : item.action_success_counter) + "条",
            'Fail_Amount': (((item.action_fail_counter == null) || (item.action_fail_counter == undefined)) ? "" : item.action_fail_counter) + "条",
            'Imcomplete_Amount': (((item.action_incomplete_counter == null) || (item.action_incomplete_counter == undefined)) ? "" : item.action_incomplete_counter) + "条",
            'Job_Status': job_status
          }
          if ((job_type) && !this.JobList.includes(job_type)) {
            this.JobList.push(job_type)
            this.JobListChecked.push(false);
          }
          if ((user_name) && !this.UserList.includes(user_name)) {
            this.UserList.push(user_name)
            this.UserListChecked.push(false);
          }

          if(job_status)
            for(let each in job_status)
              if ((job_status[each]) && !this.job_status_list.includes(job_status[each])) {
                this.job_status_list.push(job_status[each])
                if (this.filter === 'failed' && job_status[each] === "存在失败任务")
                  this.job_status_list_checked.push(true);
                else if(this.filter === 'incomplete' && job_status[each] === "存在未完成任务")
                  this.job_status_list_checked.push(true);
                else
                  this.job_status_list_checked.push(false);
              }
          j.push(job)
        }
        if (filterStr) {
          j = j.filter(item => {
            for (let key in item) {
              if ((item[key] !== null) && (item[key] !== undefined)) {
                if (item[key].toString().includes(filterStr)) return true;
              }
            }
            return false;
          });
        }
        this.errorMessage = '';
        this.ActionList = j;

        this.totalJobNum  = Object.keys(this.ActionList).length

        return true;
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this.handleError("服务器错误，请联系管理员");
        } else {
          this.handleError("网络链接错误，数据更新可能延迟");
        }
        return false;
      });
    },
    InitialDetailsData(job_id, str) {
      this.Job_id = job_id;
      var user = job_id.split("*")[0];
      var task = JOBTYPE[job_id.split("*")[1]];
      return getOneJob(job_id).then(res => {
        let actions = res["actions"]
        let tmp = actions.sort((a, b) => a.sn - b.sn);

        let a = []
        for (let each in tmp) {
          let item = tmp[each]

          let action = {
            'User': user,
            'Task': task,
            'Sending_Time': ((item.created_at == null) || (item.created_at == undefined) || (item.created_at === "")) ? "" : new Date(item.created_at).toLocaleString(),
            'Machine_SN': item.sn,
            'Machine_Status': ((item.connection_status == null) || (item.connection_status == undefined)) ? "" : CONNECTIONSTATUS[item.connection_status],
            'Task_Result': ((item.status == null) || (item.status == undefined)) ? "" : ACTIONRESULT[item.status],
            'Error_Message': ((item.error_msg == null) || (item.error_msg == undefined)) ? "" : item.error_msg,
            "Machine_Action_ID": ((item.machine_action_id == null) || (item.machine_action_id == undefined)) ? "" : item.machine_action_id
          }

          if ((action.Machine_Status) && !this.connect_status_list.includes(action.Machine_Status)) {
            this.connect_status_list.push(action.Machine_Status)
            this.connect_status_list_checked.push(false);
          }
          if ((action.Task_Result) && !this.status_list.includes(action.Task_Result)) {
            this.status_list.push(action.Task_Result)
            this.status_list_checked.push(false);
          }
          a.push(action)
        }

        if (str) {
          a = a.filter(item => {
            let field = ['User', 'Task', 'Sending_Time', 'Machine_SN', 'Machine_Status', 'Task_Result', 'Error_Message']
            return field.some(key => item[key] && item[key].includes(str));
          });
        }
        this.JobDetail = a;
        this.errorMessage = '';
        this.totalActionNum = Object.keys(this.JobDetail).length
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this.handleError("服务器错误，请联系管理员");
        } else {
          this.handleError("网络链接错误，数据更新可能延迟");
        }
      });
    },
    showDetails(job_id) {
      this.Job_id = job_id;
      this.isOverviewShow = !this.isOverviewShow;
      this.isDetailsShow = !this.isDetailsShow;
      setItem("job_id", job_id)
      this.$store.commit('setIsOverview', false)
      this.$store.commit('setTabName', '操作历史详情');
      this.InitialDetailsData(job_id);
    },
    handleFilterByStr(str) {
      this.filterStr = str;
      this.InitialData().then(() => {
        this.ActionList = this.ActionList.filter(item => {
          for (let key in item)
            if ((item[key] !== null) && (item[key] !== undefined))
              if (String(item[key]).includes(str)) return true
        })
      })
    },
    handleDetailFilterByStr(job_id, str) {
      var user = job_id.split("*")[0];
      var task = JOBTYPE[job_id.split("*")[1]];
      this.detailFilterStr = str;
      this.InitialDetailsData(job_id, user, task).then(() => {
        this.JobDetail = this.JobDetail.filter(item => {
          let field = ['User','Task','Sending_Time','Machine_SN','Machine_Status','Task_Result','Error_Message']
          for (let key in item)
            if(field.includes(key))
              if ((item[key] !== null) && (item[key] !== undefined))
                if (item[key].includes(str)) return true
        })
      })
    },
    handleDeleteAction(action) {
      cancelIncompleteAction(action).then(res => {
        if (res.status === "fail")
          alert('中断未完成任务失败。\n' + res.data);
        if (res.status === "success")
          {
            location.reload();
          }
      })
    },
    startPolling() {
      this.pollingInterval = setInterval(() => {
        if (this.isOverviewShow) {
          this.InitialData(this.filterStr);
        } else if (this.isDetailsShow) {
          this.InitialDetailsData(this.Job_id, this.detailFilterStr);
        }
      }, 5000);
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    handleError(message) {
      this.errorMessage = message;
    }
  },
  created() {
    this.isOverviewShow = this.$store.state.isOverviewShow;
    this.isDetailsShow = !this.$store.state.isOverviewShow;
    if (this.isOverviewShow)
      this.InitialData()
    if (this.isDetailsShow) {
      let job_id = getItem("job_id")
      this.InitialDetailsData(job_id)
    }
  },
  mounted() {
    this.startPolling();
  },
  beforeDestroy() {
    this.stopPolling();
  }
}
</script>