<template>
  <div>
    <i-form-div v-for="item in fields" :key="item.id" :label="item.label_name" :required="item.required">
      <form slot="body" @keydown.enter.prevent="handleEnter">
        <input :type="item.type" :id="item.label" v-model="form[item.label]" autocomplete="on">
      </form>
    </i-form-div>
  </div>
</template>

<script>
import FormDiv from './FormDiv.vue'

export default {
  name: 'i-create-user-form',
  components: {
    "i-form-div": FormDiv
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    form: {
      type: Object
    }
  },
  methods: {
    handleEnter() {
      this.$emit("handleEnter")
    }
  },
  mounted() {
    this.fields.forEach((field, index) => {
      this.$set(field, 'id', index);
    });
  }
}
</script>

<style scoped></style>