import admin_api from '../adminRequest'

export const getAllEndpoints = () => {
  return admin_api.get('/endpoint/get_all')
}

export const deleteEndpoint = (sn) => admin_api.delete('/machine/delete?sn=' + sn)

export const createEndpoint = (endpoint) => admin_api.post('/machine/register', endpoint)

export const updateMachine = (machine) => admin_api.post('/machine/update', machine)

export const addProvision = (action) => admin_api.post('/action/add', action)

import public_api from '../publicRequest'

export const getEndpoints = (sn) => {
  return public_api.get('/machine/get?sn=' + sn)
}
export const uploadEndpoints = (endpoints) => admin_api.post('/endpoint/import', endpoints)