import axios from 'axios';
const instance = axios.create({
	baseURL: '/api/public/download',
	timeout: 3000,
	headers: {
		'Content-Type': 'application/json'
	}
});
import { getItem } from "../utils/storage.js";

instance.interceptors.request.use(function (config) {
	const token = getItem('accessToken')
	if (token) {
		config.headers.Authorization = `Bearer ${token}`
	}
	return config
}, function (error) {
	return Promise.reject(error)
});

instance.interceptors.request.use(function (config) {
	return config
}, function (error) {
	return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
	return response.data
}, function (error) {
	return Promise.reject(error);
})

export default instance