import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router'
import { checkTimeout } from "./utils/astrict.js";
import { getItem, setItem, removeItem } from "./utils/storage.js";
import { LogOut } from "./utils/logout.js";

// 在页面卸载之前将store中的数据保存到localStorage中
window.addEventListener('beforeunload', () => {
  localStorage.setItem('store', JSON.stringify(store.state));
});

// 在页面加载时从localStorage中恢复数据到store中
const savedState = JSON.parse(localStorage.getItem('store'));
if (savedState) {
  store.replaceState(savedState);
}

Vue.config.productionTip = false
new Vue({
  //el: '#app',
  store,
  router,
  render: h => h(App),

  data() {
    return {
      requireAuthNum: 1
    }
  },
  created() {
    router.beforeEach((to, from, next) => {
      var _this = this;
      if (to.path.toLocaleLowerCase() === '/download') {
        next();
      } else if (to.meta.requireAuth && _this.requireAuthNum == 1) {
        if (getItem("userName") == null) {
          _this.$router.push({ path: '/', query: { redirect: to.fullPath } })
          if (to.path !== '/login') {
            next('/login');
          } else {
            next();
          }
        } else {
          _this.requireAuthNum++;
          _this.$router.push({ path: to.fullPath })

          var isTimeout = checkTimeout()
          if (isTimeout) {
            LogOut();
            next('/login');
          } else {
            setItem("lastTime", new Date().getTime())
            next()
          }
        }
      }
      else {
        _this.requireAuthNum = 1;
        next();
      }
    });
  }
}).$mount('#app')