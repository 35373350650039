<template>
    <dialog ref="dialog">
        <div class="dialog-header">
            <slot name="header">
                <div>上传的数据中存在<span style="color:red">错误格式</span>，请<span style="color:red">下载Excel</span>后更正错误格式后重新上传。</div>
                <i-button label="×" @click="handleCancel"
                    :css-style="{ float: 'right', border: 'none', outline: 'none', margin: '0', padding: '0', fontSize: '1.2rem' }"></i-button>
            </slot>
        </div>
        <div class="dialog-footer">
            <slot name="footer">
                <i-button style="background-color: #004a86; color:white" label="下载Excel" @click="handleConfirm"></i-button>
            </slot>
        </div>
    </dialog>
</template>
<script>
import Button from './Button.vue';
export default {
    name: 'i-dialog',
    components: {
        "i-button": Button
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show: {
            handler: function (newV) {
                if (newV) this.$refs.dialog.showModal()
                else this.$refs.dialog.close()
            }
        }
    },
    mounted() {
        if (this.show) {
            this.$refs.dialog.showModal()
        }
    },
    methods: {
        handleCancel(event) {
            this.$emit('cancel', event)
        },
        handleConfirm(event) {
            this.$emit('confirm', event)
        }
    }
}
</script>
<style scoped>
dialog {
    display: flex;
    flex-direction: column;
    width: 500px;
    border-radius: 4px;
    border: none;
}

dialog:not([open]) {
    display: none;
}

.dialog-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>