import public_api from '../publicRequest'

export const getCaptcha = () => {
  return public_api.get('/user/captcha')
}

export const checkLogin = (user) => {
  return public_api.post('/user/login', user)
}

export const checkLogout = () => {
  return public_api.post('/user/logout')
}

export const getAllUsers = () => {
  return public_api.get('/user/get_all')
}

export const createUser = (user) => {
  return public_api.post('/user/create', user)
}

export const updateUser = (user) => {
  return public_api.post('/user/update', user)
}

export const deleteUser = (user) => {
  return public_api.post('/user/delete_by_admin', user)
}

export const changeUserPasswd = (user) => {
  return public_api.post('/user/change_password', user)
}

export const resetUserPasswd = (user) => {
  return public_api.post('/user/reset_password', user)
}

export const getAccessTokenbyRefreshToken = (token) => {
  return public_api.post('/user/token', token)
}