<template>
    <div class="actionHistory">
        <i-tab>
            <i-tab-item name="操作历史" selected="true">
                <div class="create-search">
                    <span>这些端点已安装“英特尔APM代理”，可以由“英特尔APMServer”进行管理。</span>
                    <!-- will provide more style of td -->
                    <div class="search">
                        <i-input class="search-input" type="text" v-model="filterName" placeholder="输入搜索关键字"
                            @handleEnter="handleFilterByStr"></i-input>
                        <img alt="icon" src="../assets/icon/search.svg" class="search-icon-size"
                            style="cursor: pointer;" @click="handleFilterByStr">
                    </div>
                </div>
                <div style="display: flex; align-items: flex-start">
                    <div class="filter">
                        <div class="filter-header">
                            <span>过滤器</span>
                            <span @click="handleClearFilters">全选</span>
                        </div>
                        <div class="filter-area">

                            <div style="margin-bottom: 10px;">操作员</div>
                            <div v-for="(user, index) in user_list" :key="user.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="user_list_checked[index]" :index="index"
                                        @handleUpdate="handleUserUpdate"></i-checkbox>
                                    <span>{{ user }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">任务</div>
                            <div v-for="(job, index) in job_list" :key="job.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="job_list_checked[index]" :index="index"
                                        @handleUpdate="handleJobUpdate"></i-checkbox>
                                    <span>{{ job }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="filter-area">
                            <div style="margin-bottom: 10px;">批量操作结果</div>
                            <div v-for="(job_status, index) in job_status_list" :key="job_status.id">
                                <div class="filter-item">
                                    <i-checkbox v-model="job_status_list_checked[index]" :index="index"
                                        @handleUpdate="handleJobStatusUpdate"></i-checkbox>
                                    <span>{{ job_status }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="flex: 1">
                        <div>
                            共有{{ totalJobNum }}条操作历史记录，目前过滤出{{ actionsFilterNum }}条。
                            <span v-if="error_message" class="error-message">({{ error_message }})</span>
                        </div>
                        <table cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th v-for="field in table_fields" :key="field.fid">{{ field.table_name }}</th>
                                    <th>
                                        详情
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in tableData" :key="item.id"
                                    :style="{ backgroundColor: item.highlight ? '#507dae57' : '' }">
                                    <td v-for="fieldName in table_fields.map(field => field.name)"
                                        :key="item.id + fieldName">
                                        <div v-if="fieldName =='Fail_Amount' && item[fieldName] !== '0条'"
                                            style="color: #e74c3c;">{{ item[fieldName] }}</div>
                                        <div v-else-if="fieldName =='Imcomplete_Amount' && item[fieldName] !== '0条'"
                                            style="color: #f39c12;">{{ item[fieldName] }}</div>
                                        <div v-else>{{ item[fieldName] }}</div>
                                    </td>
                                    <td>
                                        <i-button label="查看详情" @click="showdetails(item.job_id)"
                                            :css-style="{ backgroundColor: '#359eff', height: '28px', padding: '5px', width: '80px' }"></i-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="action-search">
                            <div class="pagination">
                                <i-button label="<" @click="pageDown" :css-style="paginationStyle"></i-button>
                                <i-input v-model="currentPageNum"></i-input>
                                <span style="margin-left: 6px;">{{ ' / ' + totalPageNum }}</span>
                                <i-button label=">" @click="pageUp" :css-style="paginationStyle"></i-button>
                            </div>
                            <div class="page-size-container">
                                <label for="page-size-input">页面大小:</label>
                                <i-select-option :options="pageSizeOptions" :selectedOption="pageSize"
                                    @actions="changePageSize" style="width: 65px"></i-select-option>
                            </div>
                        </div>
                    </div>
                </div>
            </i-tab-item>
        </i-tab>
    </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import SelectOption from './SelectOption.vue'
import BatchCreateEndpointDialog from './BatchCreateEndpointDialog.vue'
import DeleteEndpointDialog from './DeleteEndpointDialog.vue'
import Tab from './Tab.vue'
import TabItem from './TabItem.vue'
import CheckBox from './CheckBox.vue'
import { getItem, setItem } from "../utils/storage.js";

export default {
    components: {
        "i-input": Input,
        "i-checkbox": CheckBox,
        'i-button': Button,
        "i-batch-create-enpoint-dialog": BatchCreateEndpointDialog,
        "i-delete-enpoint-dialog": DeleteEndpointDialog,
        "i-tab": Tab,
        "i-tab-item": TabItem,
        "i-select-option": SelectOption,
    },
    props: {
        data: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        table_fields: {
            type: Array,
            required: true
        },
        user_list: {
            type: Array,
            required: true
        },
        user_list_checked: {
            type: Array,
            required: true
        },
        job_list: {
            type: Array,
            required: true
        },
        job_list_checked: {
            type: Array,
            required: true
        },
        job_status_list: {
            type: Array,
            required: true
        },
        job_status_list_checked: {
            type: Array,
            required: true
        },
        totalJobNum: {
            type: Number,
            required: true
        },
        error_message: String
    },
    data() {
        return {
            currentPageNum: 1,
            paginationStyle: { backgroundColor: '#f4f4f5', height: '28px', padding: '5px', width: '28px', margin: '5px' },
            filterName: '',
            pageSize: getItem('actionPageSize') || 50,
            pageSizeOptions: [
                    { label: '10', value: 10 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ],
            filterData: []
        }
    },
    computed: {
        tableData: function () {
            this.filterData = this.data.filter(item => {
                let user_list_checked_all = false
                for (let each in this.user_list)
                    user_list_checked_all  = user_list_checked_all || this.user_list_checked[each]

                if(user_list_checked_all)
                    for (let each in this.user_list)
                        if (!this.user_list_checked[each] && (item.User === this.user_list[each]))
                            return false

                let job_list_checked_all = false
                for (let each in this.job_list)
                    job_list_checked_all  = job_list_checked_all || this.job_list_checked[each]

                if(job_list_checked_all)
                    for (let each in this.job_list)
                        if (!this.job_list_checked[each] && (item.Job === this.job_list[each]))
                            return false

                let job_status_list_checked_all = false
                for (let each in this.job_status_list)
                    job_status_list_checked_all  = job_status_list_checked_all || this.job_status_list_checked[each]

                if(job_status_list_checked_all){
                    for (let each in this.job_status_list)
                        if (this.job_status_list_checked[each] && (item.Job_Status.includes(this.job_status_list[each])))
                            return true
                    return false
                }

                return true
            })

            this.filterData.sort((a, b) => {
                return new Date(b.Sending_Time) - new Date(a.Sending_Time);
            });

            const highlightCondition = this.$route.query.filter === 'failed' || this.$route.query.filter === 'incomplete';
            const threeDaysAgo = new Date();
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

            this.filterData.forEach(item => {
                const sendingTime = new Date(item.Update_Time);
                item.highlight = highlightCondition && sendingTime >= threeDaysAgo;
            });

            let tmp = this.filterData.slice((this.currentPageNum - 1) * this.pageSize, this.currentPageNum * this.pageSize);
            return tmp;
        },
        totalPageNum: function () {
            let len = Math.ceil(this.filterData.length / this.pageSize)
            if(len === 0) len = 1
            if(len < this.currentPageNum) {
                this.currentPageNum = len
            }
            return len
        },
        actionsFilterNum(){
            return Object.keys(this.filterData).length
        }
    },
    methods: {
        pageDown() {
            if(this.currentPageNum !== 1) this.currentPageNum--
            this.EndpointSelected=[];
            setItem("lastTime", new Date().getTime())
        },
        pageUp() {
            if(this.currentPageNum !== this.totalPageNum) this.currentPageNum++
            this.EndpointSelected=[];
            setItem("lastTime", new Date().getTime())
        },
        pageInput(event) {
            this.currentPageNum = event.target.value
            setItem("lastTime", new Date().getTime())
        },
        changePageSize(pageSize) {
            this.pageSize = pageSize
            setItem('actionPageSize', pageSize);
            setItem("lastTime", new Date().getTime())
        },
        showdetails(job_id) {
            this.$emit('showDetails', job_id);
            setItem("lastTime", new Date().getTime())
        },
        handleFilterByStr() {
            this.$emit('handleFilterByStr', this.filterName)
            setItem("lastTime", new Date().getTime())
        },
        handleClearFilters() {
            for (let i = 0; i < this.user_list_checked.length; i++) {
                this.$set(this.user_list_checked, i, true);
            }
            for (let i = 0; i < this.job_list_checked.length; i++) {
                this.$set(this.job_list_checked, i, true);
            }
            for (let i = 0; i < this.job_status_list_checked.length; i++) {
                this.$set(this.job_status_list_checked, i, true);
            }
            setItem("lastTime", new Date().getTime())
        },
        handleUserUpdate(e, index) {
            this.$set(this.user_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
        },
        handleJobUpdate(e, index) {
            this.$set(this.job_list_checked, index, e)
            setItem("lastTime", new Date().getTime())
        },
        handleJobStatusUpdate(e, index) {
            this.$set(this.job_status_list_checked, index, e)
            if(this.$route.query.filter == 'failed' || this.$route.query.filter == 'incomplete')
                this.$router.push({ path: '/History'}).catch(() => { });
            setItem("lastTime", new Date().getTime())
        },
    }
}
</script>

<style scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}
.page-size-container{
    display: flex;
    align-items: center;
}
.page-size-container label{
    margin-right: 10px;
}

.page-size-container select{
    height: 28px;
    line-height: 28px;
    font-size: 1rem;
    outline: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    color: #606266;
    padding: 0 10px;
}

table {
    margin: 20px 0;
    width: 100%
}

th {
    background-color: #0c4b8f;
    color: #c0c1c1
}

th,
td {
    border-bottom: 1px solid #ececec;
    text-align: center;
    width: 120px;
    padding: 10px;
}

tbody tr:hover {
    background-color: #507dae57;
}

.search-input {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #c5c6c6;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.search-input:focus,
.search-input:hover {
    border-color: #4c7daf;
    outline: none;
}

.search {
    display: flex;
}

.create-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.search-icon-size {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.filter {
    width: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    margin-top: 20px;
    padding: 10px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filter-header span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0054ae;
}

.filter-header span:nth-child(2) {
    color: #00285a;
    font-size: 0.8rem;
}

.filter-header span:nth-child(2):hover {
    cursor: pointer;
}

.filter-area {
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: 5px;
}

.filter-item span {
    margin-left: 10px;
    font-size: 0.8rem;
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-left: 5px;
}
</style>
