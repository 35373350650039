<template>
    <div>
      <SettingsTab name="Setting"></SettingsTab>
    </div>
  </template>
  
  <script>
  import SettingsTab from '../components/SettingsTab.vue'
  
  export default {
    name: 'Settings',
    components: {
        SettingsTab
    }
  }
  </script>