<template>
  <div id="download-app">
    <header>
      <div style="margin-left: 15px;">
        Intel® Asset Protection Manageability Solution
      </div>
    </header>
    <main>
      <div class="text-right">
        <h1>请点击下方按钮下载相关的安装包</h1>
        <div class="download-info">下载时间将根据您的连接速度而有所不同...</div>
      </div>
      <div class="button-container">
        <button @click="downloadAllFiles">
          下载
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import { getFiles, downloadFile } from '../apis/Download';

export default {
  data() {
    return {
      files: []
    };
  },
  methods: {
    downloadFile(fileName) {
      if (!fileName) {
        console.error('File name is null or undefined');
        return;
      }
      downloadFile(fileName)
        .then(response => {
          const blob = response;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      })
        .catch(error => {
          console.error('Error downloading file:', error);
      });
    },
    downloadAllFiles() {
      getFiles()
        .then(data => {
          if (Array.isArray(data)) {
            this.files = data;
            if (!Array.isArray(this.files) || this.files.length === 0) {
              console.error('No files to download');
              return;
            }
            let promise = Promise.resolve();
            this.files.forEach(file => {
              promise = promise.then(() => {
                return new Promise(resolve => {
                  this.downloadFile(file);
                  setTimeout(resolve, 500);
                });
              });
            });
          } else {
            console.error('Expected an array but got:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching files:', error);
        });
    }
  }
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  width: 100%;
  background-color: #004a86;
  color: white;
  font-size: 1.3rem;
  height: 60px;
  display: flex;
  align-items: center;
}

main {
  text-align: center;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#download-app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.text-right {
  text-align: right;
}

h1 {
  font-family: "IntelOne Display", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
  color: #333333;
}

.download-info {
  font-size: 16px;
  color: #666;
  margin-bottom: 40px;
  margin-right: 5px;
}

button {
  background-color: rgba(0, 84, 174, 1);
  color: white;
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 460px;
}

button:hover {
  background-color: #155ab6;
}
</style>
